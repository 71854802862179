
import { defineComponent } from 'vue';
import * as CryptoJS from 'crypto-js';
import { mapActions } from 'vuex';
import { RolesEnum } from '@/core/packages/shared-library';
import EmailValidator from '@/core/utils/email-validation';
import { AUTH_USER, CRYPTO_SECRET_KEY } from '@/core/constants';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  name: 'login',

  data() {
    return {
      loginForm: {
        username: null,
        password: null,
      },

      rules: {
        username: [
          {
            required: true,
            validator: (rule, value, cb) => {
              if (value) {
                const isValidEmail = EmailValidator.validate(value);

                if (isValidEmail) {
                  cb();
                } else {
                  cb(new Error('Invalid email address'));
                }
              }
            },
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: 'Enter your password.',
            trigger: 'blur',
          },
        ],
      },

      rememberUser: false,

      submitting: false,
    };
  },

  methods: {
    ...mapActions(AUTHENTICATION_STORE, ['login']),

    ...mapActions(USERS_STORE, ['getUser', 'saveUserState']),

    doLogin(e) {
      e.preventDefault();

      this.submitting = true;

      (this.$refs as any).loginForm.validate(async (valid) => {
        if (valid) {
          const { username, password } = this.loginForm;
          const passwordEncrypt = CryptoJS.AES.encrypt(password ?? '', CRYPTO_SECRET_KEY ?? '').toString();

          await this.login({
            username,
            password: passwordEncrypt
          }).then(async (loginResponse) => {
            if (loginResponse) {
              const user: any = await this.getUser(loginResponse.user.id);

              if (user && user.role.id === RolesEnum.ADMIN) {
                this.saveUserState(user);

                window.localStorage.setItem(AUTH_USER, JSON.stringify({
                  roleId: user.role.id,
                  userId: user.id,
                }));

                window.location.reload();
              }
            }

            this.submitting = false;
          }, (error) => {
            if (error.statusCode !== 401) {
              (this as any).$notify.error({
                title: 'Error',
                message: error.data.message
              });
            }
            this.submitting = false;
          });
        }
      });
    },

    navigateToRegistrationPage() {
      this.$router.push('/registration');
    },

    navigateToChangePasswordPage() {
      this.$router.push('/forgot-password');
    },
  },
});
