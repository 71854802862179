
import { defineComponent, PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import CardWrapper from '@/core/components/ui/CardWrapper.vue';
import SkillsFormComponent from '@/modules/account-details/components/SkillsFormComponent.vue';
import UserVerificationStatus from '@/core/components/account/UserVerificationStatus.vue';
import { User } from '@/core/models/user';
import UserVerifyActions from '@/core/components/account/UserVerifyActions.vue';
import { USERS_STORE } from '@/store/modules/users';
import { PROJECTS_STORE } from '../../../store/modules/projects';

export default defineComponent({
  name: 'skills-page',

  components: {
    CardWrapper,
    UserVerificationStatus,
    SkillsFormComponent,
    UserVerifyActions
  },

  props: {
    user: Object as PropType<User>
  },

  data() {
    return {
      moveToNextSection: false,

      UserVerificationStatusEnum,

      userSkills: {
        categories: [] as [number, number][]
      },
      userSkillState: {} as any,
    };
  },

  computed: {
    ...mapGetters(PROJECTS_STORE, ['projectCategories']),

    rawCategories(): unknown {
      return this.projectCategories;
    },

    availableCategories() {
      if (this.projectCategories && this.projectCategories.length) {
        return (this as any).projectCategories.map((category) => {
          return {
            value: category.id,
            label: category.tpLabel,
            children: category.subCategories.map((sub) => {
              return { value: sub.id, label: sub.label };
            }),
          };
        });
      }

      return [];
    },

    hasSubmitted() {
      return parseInt(this.userSkillState.userVerificationStatus, 10) > 0;
    }
  },

  async created() {
    await this.getProjectCategories();

    const user = this.user as any;
    const userSkills = await this.getUserSkills(user.id);

    this.setUserSkillsRecord(userSkills);

    const skillStatus = await this.getUserSkillVerificationStatus(user.id);
    this.userSkillState = skillStatus;
  },

  methods: {
    ...mapActions(PROJECTS_STORE, ['getProjectCategories']),

    ...mapActions(USERS_STORE, [
      'getUserSkillVerificationStatus',
      'saveUserSkills',
      'getUserSkills',
      'getAccountStatusesByUserId'
    ]),

    removeSkill(skill: never) {
      const { categories } = this.userSkills;

      categories.splice(categories.indexOf(skill), 1);
    },

    setUserSkillsRecord(payload) {
      const userSkillsRecord = {
        categories: [] as [number, number][],
      };

      if (payload.length) {
        payload.forEach((record) => {
          userSkillsRecord.categories.push([
            record.subCategory.category.id as never,
            record.subCategory.id
          ]);
        });

        this.userSkills = userSkillsRecord;
      }
    },

    backSection() {
      (this.$refs as any).skillsForm.submitForm();
      this.moveToNextSection = true;
    },

    nextSection() {
      this.$router.push({ name: 'account/status' });
    },

    approve() {
      const info = this.userSkillState as any;
      info.userVerificationStatus = this.UserVerificationStatusEnum.VERIFIED;
      this.submit(info, 'approved');
    },
    reject() {
      const info = this.userSkillState as any;
      info.userVerificationStatus = this.UserVerificationStatusEnum.REJECTED;
      this.submit(info, 'rejected');
    },
    submit(formData, action) {
      const { id } = this.$route.params;
      const payload = {
        userId: id,
        userSkillsVerificationId: formData.userVerificationStatus
      };

      this.saveUserSkills({ userId: id, userSkill: payload })
        .then(() => {
          (this as any).$notify.success({
            message: `User skills successfully ${action}.`
          });
        })
        .catch((e) => {
          (this as any).$notify.error({
            message: e || 'Error submitting user skills details.'
          });
        })
        .finally(() => {
          this.getAccountStatusesByUserId(id);
        });
    }
  },
});
