import { User } from '@/core/models/user';

export default {
  /**
   * Set the user state
   * @param state
   * @param payload {User} entity
   */
  setUser(state, payload: User) {
    state.user = payload;
  },

  setAccountStatuses(state, payload) {
    state.accountStatuses = payload;
  },

  setCertificates: (state, certificates) => {
    state.userCertificates = certificates;
  },

  setNewUserCertificate: (state, certificate) => {
    state.userCertificates = [...state.userCertificates, certificate];
  },

  removeUserCertificates: (state, certificateId: number) => {
    if (certificateId) {
      state.userCertificates = state.userCertificates.filter((certificate) => certificate.id !== certificateId);
    }
  },

  setInsurances: (state, insurances) => {
    state.userInsurances = insurances;
  },

  setNewUserInsurance: (state, insurance) => {
    state.userInsurances = [...state.userInsurances, insurance];
  },

  removeUserInsurance: (state, insuranceId) => {
    if (insuranceId) {
      state.userInsurances = state.userInsurances.filter((insurance) => insurance.id !== insuranceId);
    }
  },

  setUsers(state, payload) {
    state.users = payload;
  },

  setSelectedUser(state, payload) {
    state.selectedUser = payload;
  },

  setUserWorkstations(state, payload) {
    state.userWorkstations = payload;
  },

  setSelectedUserWorkstation(state, value) {
    state.selectedUserWorkstation = value;
  },
};
