import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import state from './state';
import submodules from './submodules';

export const PROJECTS_STORE = 'projects';

export default {
  namespaced: true,

  state,

  actions,

  getters,

  mutations,

  modules: {
    ...submodules
  }
};
