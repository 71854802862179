
import { defineComponent, PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import CardWrapper from '@/core/components/ui/CardWrapper.vue';
import { VerificationStepsEnum } from '@/core/enums/verification-steps.enum';
import { User } from '@/core/models/user';
import { USER_CUSTOMERS_STORE } from '@/store/modules/user-customers';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  name: 'account-detail-page',

  components: {
    CardWrapper
  },

  props: {
    user: Object as PropType<User>,
    status: Object
  },

  data() {
    return {
      VerificationStepsEnum,
      UserVerificationStatusEnum,
      verificationConfirmDialog: false,
      submitting: false,
      userCustomer: null,
      userCustomerAccount: null,
      currentVerificationStatus: '',
      disableActionButton: false,

      currentUser: Object as PropType<User>,
      currentStatus: Object,

      isDataLoaded: false
    };
  },

  computed: {
    ...mapGetters(USERS_STORE, [
      'isUserVerificationActive',
      'selectedUserWorkstation'
    ]),

    currentStep() {
      let step = 0;
      // const tpProfile = this.currentUser as any;
      const { selectedUserWorkstation } = this as any;

      if (this.isUserVerificationActive) {
        step = 1;
      }

      if (selectedUserWorkstation.verificationStatus === '1') {
        step = 2;
      }

      if (selectedUserWorkstation.verificationStatus === '2') {
        step = 4;
      }

      return step;
    }
  },

  methods: {
    ...mapActions(USERS_STORE, [
      'getUserById',
      'rejectUserAccount',
      'verifyUserAccount',
      'getAccountStatusesByUserId',
      'updateUserWorkstationStatus'
    ]),
    ...mapActions(USER_CUSTOMERS_STORE, [
      'getCustomerByUserId',
      'getCustomerAccountByUserId',
      'createCustomer',
      'createCustomerAccount',
      'checkUserCustomerStatus'
    ]),

    hasAccount() {
      return this.userCustomerAccount;
    },

    isCustomerVerified() {
      let status = '';

      if (this.userCustomer) {
        status = (this.userCustomer as any).status;
      }
      return (status === 'EXVERIFIED' || status === 'VERIFIED');
    },

    hasCustomer() {
      return this.userCustomer;
    },

    checkCustomerStatus() {
      const payload = {
        userId: (this.currentUser as any).id,
        userCustomerId: (this.userCustomer as any).id
      };
      const notify = (this as any).$notify;

      this.disableActionButton = true;

      this.checkUserCustomerStatus(payload).then(() => {
        notify.success({
          message: 'Customer status checked and updated.'
        });
        window.location.reload();
      })
        .catch((e) => {
          notify.error({
            message: this.transformResponseMessage(e)
          });
        }).finally(() => {
          this.disableActionButton = false;
        });
    },

    createUserCustomer() {
      const payload = this.getPayload();
      const notify = (this as any).$notify;

      this.disableActionButton = true;

      this.createCustomer(payload).then(() => {
        notify.success({
          message: 'You successfully created the customer.'
        });
        window.location.reload();
      })
        .catch((e) => {
          notify.error({
            message: this.transformResponseMessage(e)
          });
        }).finally(() => {
          this.disableActionButton = false;
        });
    },

    createUserCustomerAccount() {
      const payload = {
        userId: (this.currentUser as any).id,
        userCustomerId: (this.userCustomer as any).id
      };
      const notify = (this as any).$notify;

      this.disableActionButton = true;

      this.createCustomerAccount(payload).then(() => {
        notify.success({
          message: 'You successfully created the account.'
        });
        this.verifyAccount();
      })
        .catch((e) => {
          notify.error({
            message: this.transformResponseMessage(e)
          });
        })
        .finally(() => {
          this.disableActionButton = false;
        });
    },

    createUserCustomerAccountUnderMain() {
      const { userCustomer, currentUser } = this as any;
      const payload = {
        userId: currentUser.id,
        userCustomerId: userCustomer?.id || 999999, // we need to fake the id for backend validation
        createFromMainCustomer: 1
      } as any;
      const notify = (this as any).$notify;

      this.disableActionButton = true;

      this.createCustomerAccount(payload).then(() => {
        notify.success({
          message: 'You successfully created the account.'
        });

        this.verifyAccount();
      })
        .catch((e) => {
          notify.error({
            message: this.transformResponseMessage(e)
          });
        })
        .finally(() => {
          this.disableActionButton = false;
        });
    },

    rejectAccount() {
      const { id } = this.$route.params;
      const notify = (this as any).$notify;

      this.disableActionButton = true;

      this.rejectUserAccount(id).then(() => {
        notify.success({
          message: 'You successfully reject the account.'
        });
        this.currentVerificationStatus = UserVerificationStatusEnum.REJECTED;
        window.location.reload();
      })
        .catch((e) => {
          notify.error({
            message: e || 'Error rejecting your account.'
          });
        })
        .finally(() => {
          this.getAccountStatusesByUserId(id);
          this.disableActionButton = false;
        });
    },

    verifyAccount() {
      const { id } = this.$route.params;
      const notify = (this as any).$notify;

      this.disableActionButton = true;

      this.updateUserWorkstationStatus({
        userId: id,
        userWorkstationId: this.selectedUserWorkstation.id,
        formData: {
          verificationStatus: UserVerificationStatusEnum.VERIFIED
        }
      }).then(() => {
        notify.success({
          message: 'You successfully verified the account.'
        });
        this.currentVerificationStatus = UserVerificationStatusEnum.VERIFIED;
        window.location.reload();
      })
        .catch((e) => {
          notify.error({
            message: e || 'Error verifying your account.'
          });
        })
        .finally(() => {
          this.disableActionButton = false;
        });

      // this.verifyUserAccount(id).then(() => {
      //   notify.success({
      //     message: 'You successfully verified the account.'
      //   });
      //   this.currentVerificationStatus = UserVerificationStatusEnum.VERIFIED;
      //   window.location.reload();
      // })
      //   .catch((e) => {
      //     notify.error({
      //       message: e || 'Error verifying your account.'
      //     });
      //   })
      //   .finally(() => {
      //     this.disableActionButton = false;
      //   });
    },
    getPayload() {
      const { id } = this.$route.params;
      const user = this.currentUser as any;
      const {
        firstName,
        lastName,
        birthDate,
        email,
        contactNo,
        address,
        postcode
      } = user;
      const businessDetail = user.userBusinessDetails;
      const customerTypes = this.customerTypes();

      const businessDetailType = customerTypes[businessDetail.type]
        ? customerTypes[businessDetail.type] : customerTypes.limited_company;
      const customer = {
        tcsVersion: 1,
        userId: id,
        name: `${firstName} ${lastName}`,
        legalEntity: 'GB',
        type: businessDetailType,

        tradingAddress: {
          addressLine1: address,
          postCode: postcode,
          postTown: address
        },

        // static for now
        expectedMonthlySpend: 5000,
        industryCode: '41100',
      } as any;

      if (customer.type === customerTypes.soletrader) {
        customer.associates = [
          {
            applicant: true,
            type: customer.type,
            firstName,
            lastName,
            dateOfBirth: birthDate, // must be 18
            email,
            phone: contactNo,
            homeAddress: {
              addressLine1: address,
              country: customer.legalEntity,
              postCode: postcode,
              postTown: address
            }
          },
        ];
      }

      if (customer.type === customerTypes.limited_company) {
        customer.name = businessDetail.companyName;
        customer.companyRegNumber = businessDetail.companyNumber;
        customer.registeredAddress = {
          addressLine1: address,
          postCode: postcode,
          postTown: address
        };

        // need for llc
        customer.associates = [
          {
            applicant: true,
            type: 'DIRECTOR',
            firstName,
            lastName,
            dateOfBirth: birthDate,
            email,
            phone: contactNo,
            ownership: 1,
            homeAddress: {
              addressLine1: address,
              country: customer.legalEntity,
              postCode: postcode,
              postTown: address
            }
          },
          {
            applicant: false,
            type: 'BENE_OWNER',
            firstName,
            lastName,
            dateOfBirth: birthDate,
            email,
            phone: contactNo,
            ownership: 1,
            homeAddress: {
              addressLine1: address,
              country: customer.legalEntity,
              postCode: postcode,
              postTown: address
            }
          }
        ];
      }

      return customer;
    },

    customerTypes() {
      return {
        limited_company: 'LLC',
        soletrader: 'SOLETRADER'
      };
    },

    nextSection() {
      this.$router.push({ name: 'account/public-profile' });
    },

    transformResponseMessage(response) {
      let responseMessage = '' as any;
      const { data } = response;
      const { message: messageResponse } = data;

      if (messageResponse) {
        if (Array.isArray(messageResponse) && messageResponse.length) {
          const [actualMessage] = messageResponse;
          responseMessage = actualMessage;
        } else {
          responseMessage = messageResponse;
        }
      }

      if (responseMessage.message) {
        responseMessage = responseMessage.message;
      }

      return responseMessage;
    }
  },

  async created() {
    const { id, userWorkstationId } = this.$route.params;

    this.currentUser = await this.getUserById(id);
    // const { userVerificationStatus } = this.currentUser as any;
    const { selectedUserWorkstation } = this as any;

    this.currentVerificationStatus = selectedUserWorkstation.verificationStatus;

    this.currentStatus = await this.getAccountStatusesByUserId(id);

    await this.getCustomerByUserId({
      userId: id,
      userWorkstationId
    }).then((response) => {
      this.userCustomer = response;
    }).catch(() => {});

    await this.getCustomerAccountByUserId({
      userId: id,
      userWorkstationId
    }).then((response) => {
      this.userCustomerAccount = response;
    }).catch(() => {});

    this.isDataLoaded = true;
  },

});
