<template>
  <div class="image-dynamic-layout">
    <template v-if="isImageLoading">
      <ImageLoading />
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </div>
</template>
<script>
import ImageLoading from '@/core/components/ui/ImageLoading.vue';

export default {
  name: 'image-dynamic-layout',

  props: {
    isImageLoading: Boolean,

    source: String
  },

  components: {
    ImageLoading
  }
};
</script>
<style lang="scss" scoped>

</style>
