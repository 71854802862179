
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { USER_CERTIFICATES_CACHE } from '@/store/modules/attachment-cache/constants';
import { ATTACHMENT_CACHE_STORE } from '@/store/modules/attachment-cache';

export default defineComponent({
  name: 'certificate-form-item-component',

  props: [
    'propName',
    'metadata',
    'certificate'
  ],

  data() {
    return {
      file: null
    };
  },

  created() {
    const { attachment } = this.certificate;

    if (attachment.length) {
      this.loadAttachment(attachment[0]);
    }
  },

  methods: {
    ...mapActions(ATTACHMENT_CACHE_STORE, ['getAttachments']),

    async loadAttachment(value) {
      this.getAttachments({
        name: USER_CERTIFICATES_CACHE,
        attachments: [value],
        returnFirstOnly: true
      })
        .then(({ src }) => {
          this.file = src;
        }).catch(() => {});
    }
  }
});
