import { UserVerificationStatusEnum, UserVerificationStepsEnum } from '@/core/packages/shared-library';

export const toStepStatus = (verificationStatus) => {
  const status = UserVerificationStatusEnum;
  const stepStatus = UserVerificationStepsEnum;
  let currentStatus: any = '';
  let newValue: any = null;

  for (let i = 0; i < Object.keys(stepStatus).length; i++) {
    currentStatus = stepStatus[i];

    if (verificationStatus === status.VERIFIED) {
      newValue = stepStatus.VERIFIED;
    }

    if (verificationStatus === status.PENDING_VERIFICATION) {
      newValue = stepStatus.VERIFYING;
    }

    if (verificationStatus === status.REJECTED) {
      newValue = stepStatus.ADD_DETAILS;
    }

    if (newValue) {
      // eslint-disable-next-line no-loop-func
      currentStatus = Object.keys(stepStatus).find((key) => stepStatus[key] === newValue);
    }

    break;
  }

  return currentStatus;
};

export const toVerificationStatus = (stepStatus) => {
  const statuses = UserVerificationStatusEnum;
  const stepStatuses = UserVerificationStepsEnum;
  let currentStatus: any = '';
  let newValue: any = null;

  for (let i = 0; i < Object.keys(statuses).length; i++) {
    currentStatus = statuses[i];

    if (stepStatus === stepStatuses.ADD_DETAILS) {
      newValue = statuses.NOT_ADDED;
    }

    if (stepStatus === stepStatuses.VERIFYING
        || stepStatus === stepStatuses.SUBMIT_DETAILS) {
      newValue = statuses.PENDING_VERIFICATION;
    }

    if (stepStatus === stepStatuses.VERIFIED) {
      newValue = statuses.VERIFIED;
    }

    if (newValue) {
      // eslint-disable-next-line no-loop-func
      currentStatus = Object.keys(statuses).find((key) => statuses[key] === newValue);
    }

    break;
  }

  return currentStatus;
};
