
import { defineComponent } from 'vue';

import UserThumbnail from '@/core/components/ui/UserThumbnail.vue';

import { PROJECT_QUOTE_OVERVIEW_ROUTE } from '../../../../modules/project-quote-overview/routes';

export default defineComponent({
  name: 'po-recent-project-quotes-card',

  props: {
    projectQuotes: Array,
  },

  components: {
    UserThumbnail,
  },

  methods: {
    viewQuote() {
      const { projectId } = (this as any).$route.params;
      (this as any).$router.push({ name: PROJECT_QUOTE_OVERVIEW_ROUTE, params: { projectId } });
    },
  },
});
