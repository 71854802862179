import authentication from './authentication';
import users from './users';
import userCustomers from './user-customers';
import projects from './projects';
import projectQuotes from './project-quotes';
import certificates from './certificates';
import insurances from './insurances';
import attachmentCache from './attachment-cache';
import messages from './messages';
import course from './course';

export default {
  authentication,
  users,
  userCustomers,
  course,
  projects,
  projectQuotes,
  certificates,
  insurances,
  attachmentCache,
  messages
};
