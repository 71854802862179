

import { defineComponent } from 'vue';

import { mapActions } from 'vuex';
import { COURSE_STORE } from '@/store/modules/course';
import NumberHelper from '@/core/helpers/number.helper';

export default defineComponent({
  name: 'update-course-page',

  data() {
    return {
      selectedCourseScheduleId: null as any,
      createBtnDisable: true,
      isSubmitting: false,

      form: {
        name: '',
        address: '',
        about: '',
        price: '',
        availabilityLimit: 0,
        startDate: '',
        duration: 0,
        size: 0
      },

      flexibleSchedule: false,

      coursesOptions: [],

      rules: {
        courseId: [
          {
            required: true,
            message: 'Select a course',
            trigger: 'change'
          },
        ],
        duration: [
          {
            required: true,
            message: 'Provide a duration',
            trigger: 'change'
          },
        ],
        spotLimit: [
          {
            required: true,
            message: 'Provide a spot limit',
            trigger: 'change'
          },
        ],
      },

      cohorts: [] as any
    };
  },

  watch: {
    form: {
      immediate: true,
      deep: true,
      handler(value) {
        this.validateForm(value);
      }
    },
  },

  async created() {
    const { params } = this.$route;

    if (params.courseScheduleId) {
      this.selectedCourseScheduleId = params.courseScheduleId;

      await this.getCourses().then((response) => {
        const { data } = response;

        if (data) {
          const options = data.map((item) => {
            return {
              value: item.id,
              label: item.name
            };
          });

          this.coursesOptions = options;
        }
      });

      await this.getCourseSchedule(this.selectedCourseScheduleId).then((response) => {
        const {
          course, startDate, spotLimit, duration
        } = response;
        const { userCourses } = course;

        if (userCourses.length) {
          userCourses.forEach((userCourse) => {
            const { userCourseSchedules, user } = userCourse;

            if (userCourseSchedules.length) {
              const item = userCourseSchedules.find((userCourseSchedule) => {
                return userCourseSchedule.courseSchedule.id === parseInt(this.selectedCourseScheduleId, 10);
              });
              if (item) {
                this.cohorts.push(user);
              }
            }
          });
        }

        this.form = {
          courseId: course.id,
          startDate,
          spotLimit,
          duration
        } as any;
      });
    }
  },

  methods: {
    ...mapActions(COURSE_STORE, ['getCourses', 'getCourseSchedule', 'updateCourseSchedule']),

    isNumber(event, value) {
      return NumberHelper.isNumber(event, value);
    },

    convertToDecimal(value) {
      return NumberHelper.convertToDecimal(value);
    },

    validateForm(data) {
      let isValid = true;
      const dataCopy = JSON.parse(JSON.stringify(data));
      const fields = Object.keys(dataCopy);

      if (fields.length) {
        if (this.flexibleSchedule) {
          // eslint-disable-next-line no-param-reassign
          dataCopy.startDate = '--';
        }

        fields.forEach((field) => {
          if (!dataCopy[field]) {
            isValid = false;
          }
        });
      }

      if (!isValid) {
        this.createBtnDisable = true;
      } else {
        this.createBtnDisable = false;
      }
    },

    async onSubmit() {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { courseId: remove, ...rest } = this.form as any;

      this.isSubmitting = true;

      await this.updateCourseSchedule({
        id: this.selectedCourseScheduleId,
        formData: rest
      })
        .then(async () => {
          this.goToCoursesPage();
        })
        .catch((e) => {
          let formError = 'Error creating course. Please try again.';
          // console.log('e', e);

          if (e?.data?.message) {
            formError = (e?.data?.message).join('\n');
          }

          (this as any).$notify.error({
            message: formError
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },

    goToCoursesPage() {
      this.$router.push('/course-schedules');
    }
  }
});
