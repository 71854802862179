
import { defineComponent } from 'vue';
import BusinessTypeFormComponent from '@/modules/account-details/components/business-detail/BusinessTypeFormComponent.vue';

export default defineComponent({
  name: 'sole-trader-component',

  components: {
    BusinessTypeFormComponent,
  },

  props: {
    businessDetails: Object
  },

  computed: {
    businessDetailEntity(): unknown {
      return this.businessDetails;
    }
  }
});
