
import * as mob from 'is-mobile';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import ProjectEmptySkeleton from '@/core/components/project/placeholder/ProjectEmptySkeleton.vue';
import ProjectCardLayoutB from '@/core/components/project/project-card-layouts/ProjectCardLayoutB.vue';
import ProjectContainerSlot from '@/core/components/project/ProjectContainerSlot.vue';
import Sort from '@/core/components/ui/Sort.vue';
import projectPageListMixin from '@/mixins/projects/project-page-list.mixin';
import ProjectEmptyComponent from '@/modules/projects/components/ProjectEmptyComponent.vue';
import ProjectFilterComponent from '@/modules/projects/components/ProjectFilterComponent.vue';

import { PAGE_LIMIT } from '@/core/constants';
// eslint-disable-next-line import/no-cycle
import { PROJECTS_STORE } from '@/store/modules/projects';

export default defineComponent({
  name: 'project-page',

  mixins: [projectPageListMixin],

  components: {
    ProjectFilterComponent,
    ProjectEmptySkeleton,
    ProjectContainerSlot,
    ProjectEmptyComponent,
    ProjectCardLayoutB,
    Sort,
  },

  data() {
    interface ProjectListMetaData {
      limit: number,
      page: number,
      totalCount: number,
      totalPages: number,
    }

    return {
      drawer: false,
      isAppendToBody: false,
      isLoadingProjects: null as boolean | null,
      projects: [] as any,
      pageFilters: {
        page: 1,
        limit: PAGE_LIMIT,
      } as any,
      projectListMetaData: {
        limit: PAGE_LIMIT,
        page: 1,
        totalCount: 0,
        totalPages: 0,
      } as ProjectListMetaData,
    };
  },

  created() {
    this.initialize();
  },

  computed: {
    ...mapGetters(['generalSortFields']),

    isMapView(): boolean {
      return false;
    },
  },

  methods: {
    ...mapActions(PROJECTS_STORE, ['getProjectPageList']),

    initialize() {
      this.setProjectDrawerAppendToBody();

      this.loadProjects();
    },

    onPaginateList(currentPage: number) {
      this.pageFilters.page = currentPage;

      this.loadProjects();
    },

    loadProjects() {
      this.isLoadingProjects = true;

      const currentFilters = this.pageFilters as any ?? {};
      const { params } = this.$route;
      const { id: userId } = params;

      if (userId) {
        currentFilters.projectCreatorIds = [userId];
      }

      this.getProjectPageList(currentFilters)
        .then((response) => {
          this.projects = response.data;
          const {
            limit, page, totalCount, totalPages
          } = response;
          this.projectListMetaData = {
            limit,
            page,
            totalCount,
            totalPages,
          };
        })
        .catch((error) => {
          (this as any).$notify.error({
            title: 'Project List Error',
            message: error.data.message ?? 'Error occurred when fetching projects in the system. Please try again'
          });
        })
        .finally(() => {
          this.isLoadingProjects = false;
        });
    },

    setProjectDrawerAppendToBody() {
      if (mob.isMobile()) {
        this.isAppendToBody = true;
      } else {
        this.isAppendToBody = false;
      }
    },

    onFilterChange(filters) {
      this.pageFilters = Object.assign(this.pageFilters, { ...filters });

      // TODO: investigate why there's unnecessary field. Seems isTrusted is added directly in the object by axios or vue
      if ('isTrusted' in this.pageFilters) {
        delete (this.pageFilters as any).isTrusted;
      }

      this.loadProjects();
    },

    navigateToProjectDetail(projectId: number, userId: number) {
      this.$router.push({ path: `/projects/${projectId}/${userId}/overview` });
    },

    navigateTo(pageName: string) {
      this.$router.push({ name: pageName });
    }
  }
});
