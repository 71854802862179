import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03d03a1e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card padding-horizontal-12 padding-vertical-32 padding-vertical-xs-12" }
const _hoisted_2 = { class: "inner-container padding-16 padding-horizontal-xs-28 padding-vertical-xs-32" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}