import $axios from '../utils/axios-api-config';

export default {
  getCustomer(userId: number, userWorkstationId: number) {
    return $axios.get(`/users/${userId}/customers?wsid=${userWorkstationId}`);
  },

  getUserCustomers() {
    return $axios.get('customers');
  },

  getCustomerAccount(userId, userWorkstationId) {
    return $axios.get(`users/${userId}/accounts?wsid=${userWorkstationId}`);
  },

  createCustomer(userId, customer) {
    return $axios.post(`/users/${userId}/customers`, customer);
  },

  updateCustomerStatus(payload) {
    const { userId, userCustomerId } = payload;

    return $axios.put(`users/${userId}/customers/${userCustomerId}/status`);
  },

  createCustomerAccount(payload) {
    const { userId, userCustomerId } = payload;

    // eslint-disable-next-line no-param-reassign
    delete payload.userId;

    return $axios.post(`users/${userId}/accounts/${userCustomerId}/accounts`, payload);
  }
};
