<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style lang="scss">
  @import '@/assets/scss/app.scss';

  #app {
    height: 100%;
  }
</style>
