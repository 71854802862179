export default {
  setEntity(state, payload) {
    const { name, entity } = payload;

    state[name] = entity;
  },

  resetEntityState(state, name) {
    if (state[name]) {
      state[name] = [];
    }
  },

  appendEntity(state, payload) {
    const { name, entity } = payload;
    let current = state[name];

    if (!Array.isArray(current)) {
      current = [];
    }
    current.push(entity);

    state[name] = current;
  },

  mergeEntity(state, payload) {
    const { name, entity } = payload;
    let current = state[name];

    if (!Array.isArray(current)) {
      current = [];
    }

    current.push(...entity);

    const newList = [...new Map(
      current.filter(Boolean).map((item) => {
        return [item.attachment, item];
      })
    ).values()];

    state[name] = newList;
  }
};
