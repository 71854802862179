import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b4d6644"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "project-container page-viewport" }
const _hoisted_2 = { class: "project-filter page-viewport" }
const _hoisted_3 = { class: "project-list page-viewport" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_scrollbar, null, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "project-filter", {}, undefined, true)
        ]),
        _: 3
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}