
import { User } from '@/core/packages/shared-library';
import { defineComponent } from 'vue';

import { mapActions } from 'vuex';
import MessageListDetailBoxComponent from '@/core/components/messages/MessageListDetailBoxComponent.vue';
import CardWrapper from '@/core/components/ui/CardWrapper.vue';
import Loader from '@/core/components/ui/Loader.vue';
import { ImageFileTypes } from '../../helpers/file.helper';
import { ATTACHMENT_CACHE_STORE } from '../../../store/modules/attachment-cache';
import { USER_MESSAGES_CACHE } from '../../../store/modules/attachment-cache/constants';

export default defineComponent({
  name: 'messages-list',
  emits: ['load-unread-messages'],
  components: {
    CardWrapper,
    Loader,
    MessageListDetailBoxComponent,
  },
  props: {
    showViewProjectButton: {
      default: false,
    },
    selectedUserMessageRoom: {
      type: Object,
      required: true,
    },
    isSearchTriggered: {
      type: Object,
      required: true
    }
  },
  data: () => {
    return {
      message: '',
      inputDisable: false,
      attachmentUrls: [] as any,
      isMessageAttachmentLoaded: true
    };
  },

  computed: {
    getSelectedUserId() {
      return this.$route.params.userId;
    },

    member(): null | User {
      let member = (this as any).selectedUserMessageRoom.fromUser && (this as any).selectedUserMessageRoom.fromUser.id !== this.getSelectedUserId
        ? (this as any).selectedUserMessageRoom.fromUser : (this as any).selectedUserMessageRoom.toUser;

      if (!member) {
        member = (this as any).selectedUserMessageRoom?.project?.projectCreator;
      }

      return member;
    },

    getUserMessageList() {
      let userMessageList = (this as any).selectedUserMessageRoom.userMessages;

      if ((this as any).selectedUserMessageRoom.userMessageAttachments?.length) {
        userMessageList = userMessageList.concat((this as any).selectedUserMessageRoom.userMessageAttachments);
      }
      // eslint-disable-next-line no-nested-ternary
      userMessageList.sort((a, b) => ((a.dateCreated > b.dateCreated) ? 1 : ((b.dateCreated > a.dateCreated) ? -1 : 0)));
      return userMessageList;
    }
  },

  watch: {
    'selectedUserMessageRoom.userMessages': {
      immediate: true,
      handler(newval) {
        if (newval) {
          this.scrollToBottom();
        }
      }
    },
    'selectedUserMessageRoom.userMessageAttachments': {
      immediate: true,
      handler(newval) {
        if (newval) {
          this.loadAttachments();
        }
      }
    }
  },

  methods: {
    ...mapActions(ATTACHMENT_CACHE_STORE, ['getAttachments']),

    loadAttachments() {
      const { userMessageAttachments } = (this as any).selectedUserMessageRoom as any;
      const attachments = userMessageAttachments;

      this.isMessageAttachmentLoaded = false;

      this.getAttachments({
        name: USER_MESSAGES_CACHE,
        attachments,
        includeUrl: true
      })
        .then((values) => {
          const newValues = values.filter(Boolean);
          if (newValues.length) {
            this.attachmentUrls = newValues;

            if (this.attachmentUrls?.length) {
              this.attachmentUrls.forEach((attachmentUrl) => {
                const findListItem = this.getUserMessageList.find((listItem) => listItem?.attachment && listItem?.attachment === attachmentUrl?.attachment);

                if (findListItem) {
                  const indexOfFoundListItem = this.getUserMessageList.indexOf(findListItem);

                  findListItem.src = attachmentUrl.src;
                  findListItem.url = attachmentUrl.url;
                  this.getUserMessageList[indexOfFoundListItem] = findListItem;
                }
              });
            }
          }
        })
        .catch(() => {})
        .finally(() => {
          this.isMessageAttachmentLoaded = true;
        });
    },

    loadMoreMessages() {
      (this as any).$emit('load-unread-messages');
    },
    isImageAttachment(file) {
      let { attachment: filename } = file;
      const { name } = file;

      if (!filename) { filename = name; }
      if (!filename) return false;

      const extension = filename.split('.')[filename.split('.').length - 1];

      return ImageFileTypes.includes(extension.toLowerCase());
    },

    isPdfFile(filename) {
      return filename && filename.indexOf('.pdf') > -1;
    },

    scrollToBottom() {
      setTimeout(() => {
        const element = this.$refs[`message-items-wrapper-${this.selectedUserMessageRoom.project.id}`] as any;
        if (element) {
          element.scrollTop = element?.scrollHeight;
        }
      }, 1000);
    },
  },
});
