
import { defineComponent } from 'vue';
import BusinessTypeFormComponent from '@/modules/account-details/components/business-detail/BusinessTypeFormComponent.vue';

export default defineComponent({
  name: 'limited-company-component',

  components: {
    BusinessTypeFormComponent
  },

  props: {
    businessDetails: Object,
    businessType: String
  },

  computed: {
    businessDetailEntity() {
      return (this as any).businessDetails;
    }
  }
});
