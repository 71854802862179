
import { defineComponent, PropType } from 'vue';
import { RolesEnum } from '@/core/packages/shared-library';
import { User } from '@/core/models/user';

export default defineComponent({
  name: 'sidebar-header',

  data() {
    return {
      RolesEnum,
    };
  },

  props: {
    user: Object as PropType<User>
  }
});
