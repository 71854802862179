
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'profile-about-component',

  props: {
    profile: {
      required: true,
      type: Object
    }
  },
});
