
import { defineComponent } from 'vue';

import InsuranceFormItemFileComponent from '@/modules/account-profile/components/insurances/InsuranceFormItemFileComponent.vue';

export default defineComponent({
  name: 'insurance-form-item-component',

  components: {
    InsuranceFormItemFileComponent,
  },

  props: ['insurance', 'insuranceFieldMapping'],
});
