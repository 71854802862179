import $axios from '../utils/axios-api-config';

export default {
  getInsurances() {
    return $axios.get('/insurances');
  },

  getInsuranceById(insuranceId: number) {
    return $axios.get(`/insurances/${insuranceId}`);
  }
};
