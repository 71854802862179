
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import MessageListFilter from './MessageListFilter.vue';
import PoMessageListItem from './PoMessageListItem.vue';

export default defineComponent({
  name: 'message-list-menu',
  components: {
    MessageListFilter,
    PoMessageListItem,
  },
  props: {
    userMessageRooms: Array,

    selectedUserMessageRoom: Object,
  },
  emits: ['filter-user-message-rooms', 'select-user-message-room'],
  computed: {
    ...mapGetters(['isTradesperson', 'isProjectOwner']),
  },

  methods: {
    onChangeFilterUserMessageRooms(searchStr) {
      this.$emit('filter-user-message-rooms', searchStr);
    },

    selectUserMessageRoom(userMessageRoom: any) {
      (this as any).$emit('select-user-message-room', userMessageRoom);
    }
  }
});
