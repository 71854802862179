

import { defineComponent } from 'vue';

import { mapActions } from 'vuex';
import { DATE_FORMAT } from '@/core/constants';

import { COURSE_STORE } from '@/store/modules/course';
import NumberHelper from '@/core/helpers/number.helper';

export default defineComponent({
  name: 'create-course-page',

  data() {
    return {
      DATE_FORMAT,
      createBtnDisable: true,

      form: {
        courseId: null,
        startDate: '',
        duration: 0,
        spotLimit: 0
      },

      isSubmitting: false,

      coursesOptions: [],

      rules: {
        courseId: [
          {
            required: true,
            message: 'Select a course',
            trigger: 'change'
          },
        ],
        duration: [
          {
            required: true,
            message: 'Provide a duration',
            trigger: 'change'
          },
        ],
        spotLimit: [
          {
            required: true,
            message: 'Provide a spot limit',
            trigger: 'change'
          },
        ],
      },
    };
  },

  watch: {
    form: {
      immediate: true,
      deep: true,
      handler(value) {
        this.validateForm(value);
      }
    },
  },

  async created() {
    await this.getCourses().then((response) => {
      const { data } = response;

      if (data) {
        const options = data.map((item) => {
          return {
            value: item.id,
            label: item.name
          };
        });

        this.coursesOptions = options;
      }
    });
  },

  methods: {
    ...mapActions(COURSE_STORE, [
      'createCourse',
      'updateCourse',
      'getCourses',

      'createCourseSchedule'
    ]),

    isNumber(event, value) {
      return NumberHelper.isNumber(event, value);
    },

    convertToDecimal(value) {
      return NumberHelper.convertToDecimal(value);
    },

    validateForm(data) {
      let isValid = true;
      const dataCopy = JSON.parse(JSON.stringify(data));
      const fields = Object.keys(dataCopy);

      if (fields.length) {
        fields.forEach((field) => {
          if (!dataCopy[field]) {
            isValid = false;
          }
        });
      }

      if (!isValid) {
        this.createBtnDisable = true;
      } else {
        this.createBtnDisable = false;
      }
    },

    async onSubmit() {
      this.isSubmitting = true;

      await this.createCourseSchedule(this.form)
        .then(async () => {
          this.goToCoursesPage();
        })
        .catch((e) => {
          let formError = 'Error creating course. Please try again.';
          // console.log('e', e);

          if (e?.data?.message) {
            formError = (e?.data?.message).join('\n');
          }

          (this as any).$notify.error({
            message: formError
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },

    goToCoursesPage() {
      this.$router.push('/course-schedules');
    }
  }
});
