<template>
  <div class="list-container">
    <el-row>
      <el-col :span="12">
        <el-input v-model="search" v-debounce:500="onSearch" placeholder="Search"></el-input>
      </el-col>
      <el-col :span="12" style="text-align: right;">
        <el-select v-model="currentStep" placeholder="Select step" @change="onFilterStep">
          <el-option
            v-for="step in steps"
            :key="step.value"
            :label="step.label"
            :value="step.value">
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row v-for="(userWorkstation, i) in list.items" :key="i">
      <el-col :span="4">
        <div>
          <h1 class="name">
            <a
              href="#"
              @click="viewAccount(userWorkstation.id, userWorkstation.user.id)">
              {{ userWorkstation.user?.userBusinessDetails?.businessName ||
                    userWorkstation.user?.userBusinessDetails?.companyName ||
                      userWorkstation.user?.userBusinessDetails?.teamName || '(Unnamed)' }}
            </a>
          </h1>
          <p class="contact-no">{{ userWorkstation.user.contactNo }}</p>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="status">
          <p><span class="label">Location</span>: <span>{{ userWorkstation.user?.userBusinessDetails?.businessAddress }} {{ userWorkstation.user?.userBusinessDetails?.businessPostcode }}</span></p>
        </div>
      </el-col>
      <el-col :span="8">
        <!-- <div class="status">
          <p><span class="label">Current Step</span>: <span>{{ getCurrentStep(userWorkstation.user.userVerificationStatus) }}</span></p>
        </div> -->
        <!-- <div class="status">
          <p><span class="label">Customer Status</span>: <span>{{ getCustomerStatus(userWorkstation.user.id) }}</span></p>
        </div> -->
        <div class="status">
          <p><span class="label">Name</span>: <span>
              {{ userWorkstation.user.firstName }} {{ userWorkstation.user.lastName }}
            </span></p>
        </div>
        <div class="status">
          <p><span class="label">Current Step</span>: <span>{{ getCurrentStep(userWorkstation.user.userVerificationStatus) }}</span></p>
        </div>
        <div class="status">
          <p><span class="label">Date Registered: </span>: <span>
            {{ `${$filters.formatToDatetime(userWorkstation.user?.dateCreated, 'Do MMMM - HH:mm a')}` }}
          </span></p>
        </div>
        <div class="status">
          <p><span class="label">Submitted At</span>: {{ userWorkstation?.submittedInformationAt ?
            `${$filters.formatToDatetime(userWorkstation?.submittedInformationAt, 'Do MMMM - HH:mm a')}` : '--' }}</p>
        </div>

      </el-col>
      <el-col :span="8" class="btn-view-account">
        <el-button type="primary" @click="viewAccount(userWorkstation.id, userWorkstation.user.id)">View Account</el-button>
      </el-col>
    </el-row>
    <div class="pagination">
      <el-pagination
          :current-page="pageFilters.page"
          :page-size="pageFilters.limit"
          @current-change="paginatePage"
          @prev-click="paginatePage"
          @next-click="paginatePage"
          background
          layout="prev, pager, next"
          :total="list.meta && list.meta.totalItems > 0 ? list.meta.totalItems : 0">
      </el-pagination>
    </div>
    <div v-if="!(list.items && list.items.length)">
      <h1>No Records</h1>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { UserVerificationStatusEnum, RolesEnum } from '@/core/packages/shared-library';
import { USERS_STORE } from '@/store/modules/users';
import { USER_CUSTOMERS_STORE } from '@/store/modules/user-customers';
import { VerificationStepsEnum } from '@/core/enums/verification-steps.enum';
import { toStepStatus, toVerificationStatus } from '@/core/helpers/verificationStatusNormalize';
import userPageListMixin from '@/mixins/users/user-page-list.mixin';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';

const ALL = 'all';

export default {
  name: 'Tradespeople',

  mixins: [userPageListMixin],

  data: () => {
    return {
      RolesEnum,
      account: {
        link: '/users/account'
      },
      search: '',
      listFilter: {
        roleId: RolesEnum.TRADESPERSON
      },
      currentStep: ALL,
      steps: [
        {
          label: 'All',
          value: ALL
        },
        {
          label: 'Add Details',
          value: VerificationStepsEnum.ADD_DETAILS
        },
        {
          label: 'Submit Details',
          value: VerificationStepsEnum.SUBMIT_DETAILS
        },
        {
          label: 'Verifying',
          value: VerificationStepsEnum.VERIFYING
        },
        {
          label: 'Verified',
          value: VerificationStepsEnum.VERIFIED
        }
      ],
      pageFilters: {
        page: 1,
        limit: 10,
      },
      pageListMetadata: null,

      hasFinishedRequest: false
    };
  },

  computed: {
    ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),
    ...mapGetters([
      'users',
      'usersPaginate'
    ]),
    ...mapGetters(USER_CUSTOMERS_STORE, ['userCustomers']),
    ...mapState(USERS_STORE, {
      list: (state) => state.userWorkstations
    }),
    ...mapState(USER_CUSTOMERS_STORE, {
      customers: (state) => state.userCustomers,
    }),
  },

  async created() {
    window.localStorage.removeItem('SWS');

    await this.getList();
    // this.getUserCustomers();
  },

  methods: {
    ...mapActions(USERS_STORE, ['getUsers', 'getUserWorkstations']),
    ...mapActions(USER_CUSTOMERS_STORE, ['getUserCustomers']),

    async getList(filter) {
      const { userId } = this.authenticatedUser;

      const currentFilters = {
        ...this.pageFilters,
        ...this.listFilter,
        ...filter,
        search: this.search
      };

      if (this.currentStep === ALL) {
        delete currentFilters.status;
      }

      const list = await this.getUserWorkstations({ userId, filter: currentFilters })
        .finally(() => {
          this.hasFinishedRequest = true;
        });

      // const list = await this.getUsers(currentFilters);

      this.pageFilters = currentFilters;
      this.list = list.items;
      this.pageListMetadata = list.meta;
    },

    paginatePage(currentPage) {
      const status = this.getStatus();
      const filter = {};

      if (status) {
        filter.status = status;
      }
      this.pageFilters.page = currentPage;

      this.getList(filter);
    },

    viewAccount(userWorkstationId, userId) {
      this.$router.push({
        name: 'account/tradesperson',
        params: { userWorkstationId, id: userId }
      });
    },

    getCustomerStatus(userId) {
      const customers = this.userCustomers;
      let customer;
      let status = 'NONE';

      for (let i = 0; i < customers.length; i++) {
        customer = customers[i];
        if (customer.userId === userId) {
          status = customer.status;
        }
      }

      return status;
    },

    getCurrentStep(statusId) {
      const status = toStepStatus(statusId);
      return status.replace('_', '  ');
    },

    getStatus() {
      return UserVerificationStatusEnum[
        toVerificationStatus(this.currentStep)
      ];
    },

    getStatusLabel(statusId) {
      const status = UserVerificationStatusEnum;
      return Object.keys(status).find((key) => status[key] === statusId);
    },

    onSearch(value) {
      const status = UserVerificationStatusEnum[
        toVerificationStatus(this.currentStep)
      ];
      const filter = { status };
      this.search = value;
      this.getList(filter);
    },

    onFilterStep() {
      const status = this.getStatus();
      const filter = {};

      if (status) {
        filter.status = status;
      }
      this.getList(filter);
    }
  }
};
</script>

<style lang="scss" scoped>
  .list-container {
    padding: 10px 30px 30px;
    height: 100%;
    background: #F7F9FF;
  }

  .el-row {
    margin-top: 20px;
    padding: 20px 25px 10px;
    background: #fff;
    border-radius: 6px;
  }

  .name {
    padding: 0;
    margin: 0;
    font-size: 16px;

    a {
      text-decoration: none;
    }
  }

  .contact-no {
    font-size: 14px;
    color: #898EA6;
  }

  .status {
    font-size: 14px;

    .label {
      font-weight: bold;
    }
  }

  .btn-view-account {
    .el-button {
      float: right;
    }
  }

  .pagination {
    margin-top: 10px;
  }
</style>
