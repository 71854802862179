import { RolesEnum } from '@/core/packages/shared-library';
import { AuthResponse } from '../models/authentication';
import { User } from '../models/user';
import { VerificationCode } from '../models/verification-code';
import $axios from '../utils/axios-api-config';

export default {
  /**
   * Authenticate user using the credentials provided
   * @param username
   * @param password
   * @returns
   */
  login(username: string, password: string): Promise<AuthResponse> {
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'admin'
    };

    return $axios.post('/auth/login', { username, password }, { headers });
  },

  /**
   * TODO: supply with {User} model request dto
   * Registers user either TP or PO
   * @param user
   */
  register(user: User) {
    let path = '';

    if (user.roleId === RolesEnum.PROJECT_OWNER) {
      path = 'property-owner';
    } else if (user.roleId === RolesEnum.TRADESPERSON) {
      path = 'tradesperson';
    }

    return $axios.post(`/auth/register/${path}`, user);
  },

  /**
   * Verify if email exists or not
   *
   * TODO: Transfer this to validate api request
   * @param value
   * @returns
   */
  authenticateEmail(value: string) { // TODO: replace with response dto
    return $axios.get(`/auth/verify/email/${value}`);
  },

  /**
   * Checks if phone number already exists
   * @param value
   * @returns
   */
  authenticatePhoneNumber(value: string) {
    return $axios.get(`/auth/verify/phone-number/${value}`);
  },

  /**
   * Resend verification code to user's email
   * @param email
   * @returns
   */
  resendVerificationCode(email: string) {
    return $axios.post('/auth/verifiy/resend', { email });
  },

  /**
   * Verify verification code
   * @param data
   * @returns
   */
  verifyVerificationCode(data: VerificationCode): Promise<unknown> {
    return $axios.post('/auth/verify', data);
  },

  /**
   * Send link to user's email for the change password
   * @param email
   * @returns
   */
  resetPassword(email: string) {
    return $axios.post('/forgot-password/reset', { email });
  },

  /**
   * Verify the reset password token
   * @param token
   * @returns
   */
  verifyResetPasswordToken(token: string): Promise<unknown> {
    return $axios.get(`/forgot-password/${encodeURIComponent(token)}/verify`);
  },

  /**
   * Change user password
   * @param data
   * @returns
   */
  changePassword(data) {
    return $axios.put('/forgot-password/change/new-password', data);
  },
};
