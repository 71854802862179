import $axios from '../utils/axios-api-config';

export default {
  getProjectQuotesPageList(params = {}) {
    // TODO: Currently, this endpoint is created on the project module, need to transfer this to the project quote module itself
    return $axios.get('projects/project-quotes', { params });
  },

  /**
   * Get project quotes by project id
   * SAD to say - it fetches all the projects for that id regardless of the user!
   * TODO: need to update this
   * @param projectId
   * @returns
   */
  getQuotesByProject(projectId: number) {
    const params = {
      projectId,
    };

    return $axios.get('/project-quotes/', { params });
  },

  getProjectQuotes(filters = {}) {
    return $axios.get('/project-quotes', { params: filters });
  },

  /**
   * Since we don't have currently a way to fetch project quote for that certain user, we need to get all the list and then
   * loop all the records that matches that userId
   * @param projectId
   * @param userId -> this will be removed in the future as this will be handled in the BE
   */
  getQuotedProjectByAuthUser(projectId: number, userId: number) {
    const params = {
      projectId,
    };

    return $axios.get('/project-quotes/', { params })
      .then((quotes: any) => {
        if (quotes && quotes.items.length) {
          return quotes.items.find((quote) => quote.projectQuoteCreatorId === userId);
        }

        return null;
      });
  },

  /**
   * Get the calculated service fee
   * @param payload
   * @returns
   */
  // eslint-disable-next-line camelcase
  calculateServiceFee(projectId: number, payload: { total: number, threshold_percentage: number }) {
    return $axios.post(`/projects/${projectId}/quotes/service-fee`, payload);
  },

  /**
   * Create new project Quote
   * @param payload
   * @returns
   */
  createProjectQuote(payload: unknown) {
    return $axios.post('/project-quotes', payload);
  },

  /**
   * Update existing project quote
   * @param payload
   * @returns
   */
  updateProjectQuote(payload: unknown) {
    return $axios.put('/project-quotes', payload);
  },

  getProjectQuotesByProject(projectId: number) {
    return $axios.get(`/projects/${projectId}/quotes`);
  },

  getProjectQuoteDetails(projectId: number, projectQuoteId: number) {
    return $axios.get(`/projects/${projectId}/quotes/${projectQuoteId}`);
  }
};
