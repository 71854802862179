
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'profile-skills',

  props: {
    skills: {
      required: true,
      type: Array,
      default: () => {
        return [];
      }
    },
    closableTag: {
      required: false,
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      activeSkillTab: null,
    };
  },

  computed: {
    getGroupedSkills() {
      const results = [] as any;

      if (this.skills && this.skills.length) {
        for (let i = 0; i < this.skills.length; i++) {
          const skill = this.skills[i] as any;

          const parentCategory = {
            id: skill.parentCategory.id,
            label: skill.parentCategory.tpLabel,
            children: []
          };

          const checkIfExistsCategory = results.find((c) => c.id === skill.parentCategory.id);

          if (typeof checkIfExistsCategory === 'undefined') {
            parentCategory.children.push(skill.subCategory as never);
            results.push(parentCategory);
          } else {
            checkIfExistsCategory.children.push(skill.subCategory as never);
          }
        }
      }

      return results;
    },
  }
});
