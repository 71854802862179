
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import QuoteSummaryCard from '@/core/components/project/project-card-statuses/ProjectQuoteSummaryCard.vue';
import UserThumbnail from '@/core/components/ui/UserThumbnail.vue';

import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  name: 'project-quote-overview-summary',
  emits: ['confirm-accept-quote', 'confirm-reject-quote'],
  components: {
    QuoteSummaryCard,
    UserThumbnail,
  },
  props: {
    projectQuote: Object,
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user']),
  }
});
