<template>
    <el-form-item class="upload-field" label="" prop="attachment">
      <CommonAttachmentLayout
        v-if="!embedSrc"
        :classes="'el-image'"
        :src="attachment"
        :loadingCondition="!isImageRequestDone"
        :emptyCondition="isImageRequestDone && !attachment">
      </CommonAttachmentLayout>
      <embed
        v-if="embedSrc"
        :src="embedSrc"
        type="application/pdf"
        width="100%"
      />
      <ImageDownloadLink v-if="attachment || embedSrc" :link="attachmentUrl" />
    </el-form-item>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { isPdfFile } from '@/core/helpers/check-file-type.helper';
import CommonAttachmentLayout from '@/core/components/ui/CommonAttachmentLayout.vue';
import ImageDownloadLink from '@/core/components/ui/ImageDownloadLink.vue';
import {
  USER_CERTIFICATES_CACHE,
  USER_INSURANCES_CACHE
} from '@/store/modules/attachment-cache/constants';
import { ATTACHMENT_CACHE_STORE } from '@/store/modules/attachment-cache';

export default defineComponent({
  name: 'image-attachment-component',

  props: {
    record: {
      type: Object,
      required: true,
    }
  },

  components: {
    ImageDownloadLink,
    CommonAttachmentLayout,
  },

  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      attachment: '',
      attachmentUrl: '',
      embedSrc: '',
      isImageRequestDone: true
    };
  },

  created() {
    const { attachment } = this.record;

    if (attachment.length) {
      this.loadAttachment(attachment[0]);
    }
  },

  methods: {
    ...mapActions(ATTACHMENT_CACHE_STORE, ['getAttachments']),

    loadAttachment(value) {
      const { attachment } = value;
      const cache = this.getCache(attachment);

      this.isImageRequestDone = false;
      this.getAttachments({
        name: cache,
        attachments: [value],
        includeUrl: true,
        returnFirstOnly: true
      })
        .then(({ src, url }) => {
          this.attachment = src;
          this.isImageRequestDone = true;

          if (isPdfFile(url)) {
            this.embedSrc = url;
            this.attachment = '';
          }

          this.attachmentUrl = url;
        }).catch(() => {
          this.isImageRequestDone = true;
        });
    },

    getCache(value) {
      let cache;

      if (value.indexOf('insurances') !== -1) {
        cache = USER_INSURANCES_CACHE;
      }

      if (value.indexOf('certifications') !== -1) {
        cache = USER_CERTIFICATES_CACHE;
      }

      return cache;
    }
  }
});

</script>
