
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { Project, ProjectQuote } from '@/core/packages/shared-library';
import ProjectQuoteEmpty from '@/core/components/project-quote/ProjectQuoteEmpty.vue';
import ProjectQuoteOverviewDetailSkeleton from '@/core/components/project-quote/ProjectQuoteOverviewDetailSkeleton.vue';
import ProjectQuoteOverviewSidebarSkeleton from '@/core/components/project-quote/ProjectQuoteOverviewSidebarSkeleton.vue';
import ProjectQuoteOverviewSummarySkeleton from '@/core/components/project-quote/ProjectQuoteOverviewSummarySkeleton.vue';
import ProjectQuoteContainerSlot from '@/core/components/project-quote/ProjectQuoteContainerSlot.vue';
import ProjectQuoteLeftSidebarList from '@/core/components/project-quote/ProjectQuoteLeftSidebarList.vue';
import ProjectQuoteOverviewDetail from '@/modules/project-quote-overview/components/ProjectQuoteOverviewDetail.vue';
import ProjectQuoteOverviewSummary from '@/modules/project-quote-overview/components/ProjectQuoteOverviewSummary.vue';

import { PROJECTS_ROUTE } from '@/modules/projects/routes';
import { USERS_STORE } from '@/store/modules/users';
import { PROJECT_QUOTES_STORE } from '@/store/modules/project-quotes';
// eslint-disable-next-line import/no-cycle
import { PROJECTS_STORE } from '@/store/modules/projects';

export default defineComponent({
  name: 'project-quote-overview-page',

  components: {
    ProjectQuoteContainerSlot,
    ProjectQuoteLeftSidebarList,
    ProjectQuoteOverviewDetailSkeleton,
    ProjectQuoteOverviewSummarySkeleton,
    ProjectQuoteOverviewSidebarSkeleton,
    ProjectQuoteOverviewDetail,
    ProjectQuoteOverviewSummary,
    ProjectQuoteEmpty
  },

  data: () => {
    return {
      projectQuoteListIsLoaded: false,
      projectQuoteList: [],
      selectedProjectQuote: null as null,
      projectQuoteDetailsLoaded: false as boolean,
      filterProjectQuoteString: '' as string,
    };
  },

  created() {
    (this as any).initialize();
  },

  computed: {
    ...mapGetters(PROJECTS_STORE, [
      'selectedProject'
    ]),

    ...mapGetters(USERS_STORE, ['user']),

    getFilteredProjectQuotes(): [] {
      let result = (this as any).projectQuoteList;

      if ((this as any).filterProjectQuoteString) {
        result = result.filter((projectQuote) => {
          return projectQuote.notes.toLowerCase().includes((this as any).filterProjectQuoteString.toLowerCase());
        });
      }

      return result;
    }
  },

  methods: {
    ...mapActions(USERS_STORE, ['getUserById']),

    ...mapActions(PROJECTS_STORE, ['getProjectById']),

    ...mapActions(PROJECT_QUOTES_STORE, ['getProjectQuotesByProject', 'getProjectQuoteDetails', 'getCustomerPay', 'updateProjectQuoteStatus']),

    // async initialize() {
    //   const { projectId, userId } = (this.$route as any).params;

    //   await this.getProjectById(projectId);
    //   await this.getUserById(userId);

    //   const getProjectQuoteListRequest = await this.getProjectQuotesByProject(projectId);

    //   if (getProjectQuoteListRequest?.data?.length) {
    //     (this as any).projectQuoteList = getProjectQuoteListRequest?.data;
    //     const [defaultProjectQuote] = (this as any).projectQuoteList;
    //     (this as any).selectProjectQuote(defaultProjectQuote.id);
    //     (this as any).projectQuoteListIsLoaded = true;
    //   } else {
    //     (this as any).projectQuoteDetailsLoaded = true;
    //     (this as any).projectQuoteListIsLoaded = true;
    //   }
    // },

    async initialize() {
      const { projectId, userId } = (this.$route as any).params;

      await this.getProjectById(projectId)
        .then((response) => {
          if (response) {
            this.selectedProject = response as Project;
          }
        })
        .catch((error) => {
          (this as any).$notify.error({
            message: error && error?.message ? error?.message : 'Project not found.'
          });
          (this as any).$router.push({ name: PROJECTS_ROUTE });
        });
      await this.getUserById(userId);

      const getProjectQuoteListRequest = await this.getProjectQuotesByProject(this.selectedProject?.id);

      if (getProjectQuoteListRequest?.data?.length) {
        (this as any).projectQuoteList = getProjectQuoteListRequest?.data;
        const [defaultProjectQuote] = (this as any).projectQuoteList;
        const projectQuoteGetTotalPricePromises = [] as any[];

        if ((this as any).projectQuoteList?.length) {
          (this as any).projectQuoteList.forEach((projectQuote) => {
            projectQuoteGetTotalPricePromises.push((this as any).getProjectQuoteDetailsWithTotalQuotePrice(projectQuote));
          });

          const projectQuoteGetTotalPriceResponses = await Promise.all(projectQuoteGetTotalPricePromises);

          if (projectQuoteGetTotalPriceResponses?.length) {
            projectQuoteGetTotalPriceResponses.forEach((response, index) => {
              if ((this as any).projectQuoteList[index]) {
                (this as any).projectQuoteList[index].quotePrice = response.total;
              }
            });
          }
        }
        (this as any).selectProjectQuote(defaultProjectQuote.id);
        (this as any).projectQuoteListIsLoaded = true;
      } else {
        (this as any).projectQuoteDetailsLoaded = true;
        (this as any).projectQuoteListIsLoaded = true;
      }
    },

    async getProjectQuoteDetailsWithTotalQuotePrice(projectQuote: ProjectQuote): Promise<any> {
      let result = {
        total: 0,
        serviceFee: 0,
      } as { total: number, serviceFee: number };

      const projectId = (this as any).selectedProject?.id;
      const projectQuoteDetails = await this.getProjectQuoteDetails({ projectQuoteId: projectQuote?.id, projectId });
      result = await this.getCustomerPay({ projectId: this.selectedProject?.id, quote: projectQuoteDetails });
      return result;
    },

    async selectProjectQuote(projectQuoteId: number) {
      const projectId = (this as any).selectedProject?.id;
      (this as any).projectQuoteDetailsLoaded = false;
      const getProjectQuoteDetails = await this.getProjectQuoteDetails({ projectQuoteId, projectId });

      if (getProjectQuoteDetails) {
        (this as any).selectedProjectQuote = getProjectQuoteDetails;
        /* (this as any).selectedProjectQuote.quotePrice = await (this as any).getProjectQuotePrice((this as any).selectedProjectQuote); */
      } else {
        (this as any).$notify.error({
          title: 'Project Quote Details Error.',
          message: 'Error fetching project quote details at the moment. Please try to refresh the page.',
        });
      }

      (this as any).projectQuoteDetailsLoaded = true;
    },

    filterProjectQuotes(filterString: string) {
      (this as any).filterProjectQuoteString = filterString;
    },

    async getProjectQuotePrice(projectQuote): Promise<any> {
      let result = { total: 0, serviceFee: 0 } as number | { total: number, serviceFee: number };

      const { projectId } = (this as any).$route.params;

      result = await this.getCustomerPay({ projectId, quote: projectQuote });
      return result;
    }
  }
});
