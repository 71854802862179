
import { defineComponent } from 'vue';
import { RolesEnum } from '@/core/packages/shared-library';

export default defineComponent({
  name: 'project-status-card',

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    projectQuoteCount: Number,

    status: {
      type: Object,
      required: true
    },

    averageQuoteValue: Number,

    btnQuoteLabel: String,
  },

  filters: {
    titleCase: (title: string) => {
      return title.replace(/-/g, ' ');
    },
  },

  data() {
    return {
      RolesEnum
    };
  },

  methods: {
    viewQuotes() {
      this.$emit('view-quotes');
    },

    onClickQuote() {
      this.$emit('click-quote');
    }
  }
});
