import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00a85001"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "project-tile-desc padding-top-12" }
const _hoisted_2 = {
  key: 0,
  class: "related-images"
}
const _hoisted_3 = {
  key: 1,
  class: "no-attachments"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_tag = _resolveComponent("el-tag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.currentProject.attachmentDisplayCount)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentProject.attachmentUrls.slice(0, _ctx.projectAttachmentsDisplayLimit), (image, i) => {
            return (_openBlock(), _createBlock(_component_el_avatar, {
              shape: "square",
              size: 60,
              key: i,
              src: image.src,
              icon: "el-icon-picture"
            }, null, 8, ["src"]))
          }), 128)),
          (_ctx.currentProject.totalAttachmentCount > _ctx.projectAttachmentsDisplayLimit)
            ? (_openBlock(), _createBlock(_component_el_tag, {
                key: 0,
                type: "primary",
                size: "small"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("+" + _toDisplayString(_ctx.currentProject.totalAttachmentCount - _ctx.projectAttachmentsDisplayLimit), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("img", {
            class: "project-img-tag img-cover image-placeholder",
            onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setPlaceHolderImage && _ctx.setPlaceHolderImage(...args)))
          }, null, 32)
        ]))
  ]))
}