import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { RolesEnum } from '@/core/packages/shared-library';
import LoginRoute from '@/modules/login/routes';
import UnAuthorizeRoute from '@/modules/unauthorize/routes';
import store from '@/store/index';

// import ProjectRoute from '@/modules/projects/routes';
import DashboardRoute from '@/modules/dashboard/routes';
import AccountRoute from '@/modules/account/routes';
import { TradespersonPage, TradespersonAccountPage } from '@/modules/tradesperson/routes';
import { PropertyOwners, PropertyOwnerProjects } from '@/modules/property-owners/routes';
// import ProjectOverviewRoute from '@/modules/project-overview/routes';
// import ProjectQuoteOverviewRoute from '@/modules/project-quote-overview/routes';
// import ProjectStageTaskRoute from '@/modules/project-stages-tasks/routes';
import AccountProfileRoute from '@/modules/account-profile/routes';
// import ProjectMessagesRoute from '@/modules/project-messages/routes';
import CourseRoute from '@/modules/course-schedule/routes';
import CreateCourseRoute from '@/modules/create-course-schedule/routes';
import UpdateCourseRoute from '@/modules/update-course-schedule/routes';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/core/components/layouts/MainLayout.vue'),
    name: 'main.layout',
    redirect: () => {
      const authenticatedUser = store.getters['authentication/authenticatedUser'];

      if (authenticatedUser && (authenticatedUser.roleId === RolesEnum.ADMIN)) {
        // must be a 404 page..
        return '/dashboard';
      }

      return '/login';
    },
    meta: {
      requiresAuth: true,
      authorize: [],
    },
    children: [
      // ProjectRoute,
      DashboardRoute,
      AccountRoute,
      PropertyOwners,
      PropertyOwnerProjects,
      TradespersonPage,
      TradespersonAccountPage,
      // ProjectOverviewRoute,
      // ProjectQuoteOverviewRoute,
      // ProjectStageTaskRoute,
      AccountProfileRoute,
      // ProjectMessagesRoute,

      // tmp, should be sub-mods
      CourseRoute,
      CreateCourseRoute,
      UpdateCourseRoute
    ]
  },

  LoginRoute,

  UnAuthorizeRoute,

  // otherwise redirect to main page
  { path: '/:catchAll(.*)', redirect: '/' },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['authentication/isAuthenticated'];

  // Check if page route requires authentication
  const isRequireAuth = to.matched.some((route) => route.meta.requiresAuth === true);
  // validateAuth flag checks if page need redirect only if it's authenticated
  const isValidateAuth = to.matched.some((route) => route.meta.validateAuth === true);

  // Check if page is requires an authentication
  if ((isRequireAuth) && !isAuthenticated) {
    next({ path: '/login', query: { returnUrl: to.fullPath } });
  } else if (isRequireAuth && isAuthenticated) {
    const authorize = to.meta.authorize as number[];

    // route.meta = { authorize: [<RolesEnum config>] } is required if requiresAuth is defined
    if (isRequireAuth && authorize === undefined) {
      throw new Error('Error configuration in route. Property "authorize" is required in meta route');
    }

    const authenticatedUser = store.getters['authentication/authenticatedUser'];

    if ((authorize && authorize.length) && !authorize.includes(authenticatedUser.roleId)) {
      return next('/unauthorize'); // 403 page route from the previous design of iknowa-vue
    }
  } else if (isValidateAuth && isAuthenticated) {
    return next('/');
  }

  return next();
});

export default router;
