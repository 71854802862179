
import { defineComponent, PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import InsuranceStatus from '@/modules/account-details/components/insurance/InsuranceStatusComponent.vue';
import InsuranceVerificationState from '@/modules/account-details/components/insurance/InsuranceVerificationStateComponent.vue';
import InsuranceTemplateRenderer from '@/modules/account-details/components/insurance/InsuranceTemplateRendererComponent.vue';
import CardWrapper from '@/core/components/ui/CardWrapper.vue';
import { User } from '@/core/models/user';
import UserVerifyActions from '@/core/components/account/UserVerifyActions.vue';
import { USERS_STORE } from '@/store/modules/users';
import { INSURANCES_STORE } from '../../../store/modules/insurances';

export default defineComponent({
  name: 'insurance-page',

  components: {
    InsuranceStatus,
    InsuranceVerificationState,
    InsuranceTemplateRenderer,
    CardWrapper,
    UserVerifyActions
  },

  props: {
    user: Object as PropType<User>
  },

  data() {
    return {
      activeInsurances: [],
      isCreateNewInsurance: false,
      unsaveUserInsurances: [],
      refNewUserInsuranceForm: 0,
      UserVerificationStatusEnum,
    };
  },

  computed: {
    ...mapGetters(USERS_STORE, [
      'userInsurances',
      'insuranceVerificationState'
    ]),

    ...mapGetters(INSURANCES_STORE, ['insurances']),

    hasSubmitted() {
      const state = this.insuranceVerificationState as any;
      return parseInt(state, 10) > 0;
    }
  },

  async created() {
    const user = this.user as any;

    await this.getUserInsurances(user.id);
  },

  methods: {
    ...mapActions(INSURANCES_STORE, ['getInsurances']),

    ...mapActions(USERS_STORE, [
      'getUserInsurances',
      'getAccountStatusesByUserId',
      'updateUserInsurance'
    ]),

    nextSection() {
      this.$router.push({ name: 'account/skills' });
    },

    approve(info) {
      const data = info as any;
      data.userVerificationStatus = this.UserVerificationStatusEnum.VERIFIED;
      this.onSubmit(info, 'approved');
    },

    reject(info) {
      const data = info as any;
      data.userVerificationStatus = this.UserVerificationStatusEnum.REJECTED;
      this.onSubmit(info, 'rejected');
    },

    onSubmit(payload, action) {
      const { id } = this.$route.params;

      this.updateUserInsurance({
        userId: id,
        insuranceId: payload.id,
        insurance: {
          metadata: payload.metadata,
          insuranceId: payload.id,
          userVerificationStatus: payload.userVerificationStatus
        }
      }).then(() => {
        (this as any).$notify.success({
          message: `Insurance updated successfully ${action}.`
        });
        this.getAccountStatusesByUserId(id);
      })
        .catch((e) => {
          (this as any).$notify.error({
            message: e || 'Error submitting details.'
          });
        });
    },
  }
});
