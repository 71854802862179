
import { defineComponent } from 'vue';

import ProjectCategoryFilter from '@/core/components/project/project-filter/ProjectCategoryFilter.vue';
import ProjectJobStatusFilter from '@/core/components/project/project-filter/ProjectJobStatusFilter.vue';

export default defineComponent({
  name: 'project-filter-component',

  components: {
    ProjectJobStatusFilter,
    ProjectCategoryFilter,
  },

  props: {
    defaultFilters: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      activeFilter: 'projectJobStatus',
    };
  },

  methods: {
    handleChangeFilter(filters) {
      this.$emit('filter-change', filters);
    },
  }
});
