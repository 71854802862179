import { AttachmentFileTypesEnum, AttachmentImagesTypesEnum } from '@/core/packages/shared-library';

const ImageFileTypes = [
  AttachmentImagesTypesEnum.BMP,
  AttachmentImagesTypesEnum.GIF,
  AttachmentImagesTypesEnum.JPEG,
  AttachmentImagesTypesEnum.JPG,
  AttachmentImagesTypesEnum.PNG,
  AttachmentImagesTypesEnum.TIFF,

  // needs to be transferred to iknowa shared
  'rle',
  'dib',
  'jpe',
  'exif',
  'tiff',
  'tif',
  'icon',
  'ico',
  'mwf',
  'emf'
];

const DocumentFileTypes = [
  AttachmentFileTypesEnum.PDF,
  AttachmentFileTypesEnum.DOC,
  AttachmentFileTypesEnum.DOCX,

  // needs to be transferred to iknowa shared
  'pptx',
  'text',
  'txt',
  'mov',
  'mp4',
  'mpg',
  'mpeg',
  'heic'
];

export {
  ImageFileTypes,
  DocumentFileTypes
};
