
import { defineComponent } from 'vue';
import GasSafeRegister from './templates/GasSafeRegister.vue';
import ECA from './templates/ECA.vue';
import FederationMasterBuilders from './templates/FederationMasterBuilders.vue';
import FENSA from './templates/FENSA.vue';
import NAPIT from './templates/NAPIT.vue';
import NFRC from './templates/NFRC.vue';
import NICEIC from './templates/NICEIC.vue';
import Other from './templates/Other.vue';
import TrustMark from './templates/TrustMark.vue';
import NotRequired from './templates/NotRequired.vue';

export default defineComponent({
  name: 'CertificationTemplateRenderer',

  components: {
    GasSafeRegister,
    ECA,
    FederationMasterBuilders,
    FENSA,
    NAPIT,
    NFRC,
    NICEIC,
    Other,
    TrustMark,
    NotRequired,
  },

  props: {
    certificate: Object,
    templateCerficate: Object
  }
});
