
import { ProjectStage, ProjectStageStatusEnum, ProjectStageTypeEnum } from '@/core/packages/shared-library';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import ImageDynamicLayout from '@/core/components/ui/ImageDynamicLayout.vue';
import ImageFailedLoading from '@/core/components/ui/ImageFailedLoading.vue';
import ImageLoading from '@/core/components/ui/ImageLoading.vue';
import { ImageFileTypes } from '@/core/helpers/file.helper';
import { ATTACHMENT_CACHE_STORE } from '@/store/modules/attachment-cache';
import { PROJECT_STAGES_CACHE } from '@/store/modules/attachment-cache/constants';
import { PROJECT_STAGES_TASKS_STORE } from '@/store/modules/project-quotes/submodules/project-stages-tasks';

import { PROJECT_QUOTES_STORE } from '../../../store/modules/project-quotes';

export default defineComponent({
  name: 'stage-box',
  emits: ['update-project-stage-status', 'update-project-stage-task-status', 'upload-project-stage-attachment', 'remove-project-stage-attachment'],
  props: {
    projectStage: Object,
    projectQuote: Object,
    projectStageServiceFee: Number,
    stageIndex: Number,
    verifyingStage: {
      type: Number,
      default: 0,
    }
  },

  components: {
    ImageDynamicLayout,
    ImageLoading,
    ImageFailedLoading,
  },

  data: () => {
    return {
      ProjectStageTypeEnum,
      ProjectStageStatusEnum,
      attachments: [] as any[],
      attachmentUrls: [] as { id: number, name: string, url: any, src: string }[],
      apiUrl: process.env.VUE_APP_API_URL,
      loadingAttachments: false,
      totalServiceFee: 0 as number,
      totalStageCost: 0 as number,
      imageAttachments: [] as any,
      documentAttachments: [] as any
    };
  },
  computed: {
    ...mapGetters(PROJECT_QUOTES_STORE, ['getProjectStageDurationDays']),

    totalStageCostWithServiceFee(): number {
      return (this as any).projectStage.stageCost + (this as any).projectStageServiceFee;
    },

    previewImages(): string[] {
      return this.imageAttachments.map((attachment) => attachment.src);
    }
  },

  mounted() {
    this.loadAttachments();
  },

  methods: {
    ...mapActions(ATTACHMENT_CACHE_STORE, ['getAttachments']),

    ...mapActions(PROJECT_STAGES_TASKS_STORE, ['deleteProjectStageAttachment']),

    loadAttachments(recent = false) {
      const { projectStageAttachments } = this.projectStage as any;
      let attachments = projectStageAttachments;

      if (recent) {
        attachments = this.attachments.map((item) => {
          return item.response;
        });
      }

      this.loadingAttachments = true;

      this.getAttachments({
        name: PROJECT_STAGES_CACHE,
        attachments,
        includeUrl: true
      })
        .then((values) => {
          const newValues = values.filter(Boolean);

          if (newValues.length) {
            this.attachmentUrls = values;

            this.imageAttachments = newValues.filter((item) => {
              return this.isImageAttachment(item);
            });

            this.documentAttachments = newValues.filter((item) => {
              return !this.isImageAttachment(item);
            });
          }
        })
        .catch(() => {})
        .finally(() => { this.loadingAttachments = false; });
    },
    isStageButtonDisabled(stage: ProjectStage) {
      let disabled = false;
      const { projectStageAttachments } = stage;
      const { attachments: recentAttachments } = this as any;

      switch (stage.projectStageStatus.id) {
        case ProjectStageStatusEnum.NOT_STARTED_POKED:
          if ((this as any).isTradesperson) {
            disabled = true;
          }
          break;
        case ProjectStageStatusEnum.IN_PROGRESS:
          if (!projectStageAttachments || !projectStageAttachments.length) {
            disabled = true;
          }
          if (recentAttachments.length) {
            disabled = false;
          }
          if (stage.projectStageTasks.length) {
            stage.projectStageTasks.forEach((t) => {
              if (!t.done) disabled = true;
            });
          }
          break;
        case ProjectStageStatusEnum.IN_PROGRESS_POKED:
          if (!projectStageAttachments || !projectStageAttachments.length) {
            disabled = true;
          } else if ((this as any).isTradesperson) {
            disabled = true;
          }
          break;
        case ProjectStageStatusEnum.STAGE_COMPLETE:
          disabled = true;
          break;
        case ProjectStageStatusEnum.PAYMENT_IN_PROCESS:
          disabled = true;
          break;
        default:
      }

      return disabled;
    },
    getTaskAmountWithServiceFee(amount: number) {
      const taskCostPercentage = (amount / (this as any).projectStage.stageCost) as number;
      const taskServiceFee = (this as any).projectStageServiceFee * taskCostPercentage;
      const taskAmountWithServiceFee = amount + taskServiceFee;

      // eslint-disable-next-line no-restricted-globals
      return !isNaN(taskAmountWithServiceFee) ? taskAmountWithServiceFee : 0;
    },

    isImageAttachment(file) {
      let { attachment: filename } = file;
      const { name } = file;

      if (!filename) { filename = name; }
      if (!filename) return false;

      const extension = filename.split('.')[filename.split('.').length - 1];

      return ImageFileTypes.includes(extension.toLowerCase());
    },

    isPdfFile(filename) {
      return filename && filename.indexOf('.pdf') > -1;
    }
  }
});
