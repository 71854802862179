
import { defineComponent } from 'vue';

import CertificateFormItemFileComponent from '@/modules/account-profile/components/certificates/CertificateFormItemFileComponent.vue';

export default defineComponent({
  name: 'certificate-form-item-component',

  components: {
    CertificateFormItemFileComponent,
  },

  props: ['certificate', 'certificateFieldMapping']
});
