import { User } from '../models/user';
import $axios from '../utils/axios-api-config';

export default {

  /**
   * Get user information by token
   * @returns
   */
  // getUser(): Promise<User> {
  //   return $axios.get('/users/profile');
  // },
  getUser(userId: number): Promise<User> {
    return $axios.get(`/users/${userId}`);
  },

  /**
   * Resets user password
   * @param email
   * @returns
   */
  resetPassword(email: string) {
    return $axios.post('/forgot-password/reset', { email });
  },

  /**
   * Get user by id
   * @param id
   * @returns
   */
  getUserById(id: number): Promise<User> {
    return $axios.get(`/users/${id}`);
  },

  getUserWalletAccount(userId: string, accountId: string) {
    return $axios.get(`/users/${userId}/account/${accountId}`);
  },

  /**
   * Activate the user account
   * @param userId
   * @returns
   */
  activateUserAccount(userId: number) {
    return $axios.put(`/users/${userId}/activate`);
  },

  rejectUserAccount(userId: number) {
    return $axios.put(`/users/${userId}/reject`);
  },

  /**
   * Update user information
   * @param userId
   * @param user
   * @returns
   */
  updateUser(userId: number, user: User): Promise<User> {
    return $axios.put(`/users/${userId}`, user);
  },

  /**
   *
   * @param userId
   * @param data
   * @returns
   */
  verifyWithdrawal(userId: string, data) {
    return $axios.post(`/users/${userId}/accounts/verify/code`, data);
  },

  /**
   *
   * @param userId
   * @returns
   */
  sendVerificationWithdrawal(userId: string) {
    return $axios.get(`/users/${userId}/accounts/send-verification`);
  },

  /**
   *
   * @param userId
   * @returns
   */
  getUserCustomerAccount(userId: number) {
    return $axios.get(`/users/${userId}/accounts`);
  },

  /**
   *
   * @param userId
   * @param statusId
   * @returns
   */
  getUserTotalProjectsCompleted(userId: number, statusId: string) {
    return $axios.get(`/project-quotes?projectQuoteCreatorId=${userId}&projectQuoteStatusId=${statusId}`);
  },

  getUserProjectAccountBalance(userId: number) {
    return $axios.get(`/users/${userId}/project-accounts-balance`);
  },

  /**
   * Get the transaction logs by user
   * @param userId
   * @returns
   */
  getUserTransactionLogs(userId: number) {
    return $axios.get(`/users/${userId}/transaction-logs`);
  },

  /**
   * Get acount statuses
   * @returns
   */
  getAccountStatuses() {
    return $axios.get('/users/statuses');
  },

  /**
   * Get acount statuses
   * @returns
   */
  getAccountStatusesByUserId(userId) {
    return $axios.get(`/users/${userId}/statuses`);
  },

  /**
   * Get user profile by userId
   * @param userId
   * @returns
   */
  getUserProfile(userId: number, id: number) {
    return $axios.get(`/users/${userId}/public-profile/${id}`);
  },

  /**
   * Save user profile record
   * @param userId
   * @param payload
   * @returns
   */
  saveUserProfile(userId: number, payload) {
    return $axios.put(`/users/${userId}/public-profile/${payload.id}`, payload);
  },

  /**
   * Get user business details
   * @param userId
   * @returns
   */
  getBusinessDetails(userId: number, businessId: number) {
    return $axios.get(`/users/${userId}/business-details/${businessId}`);
  },

  /**
   * Save user business details
   * @param userId
   * @param payload
   * @returns
   */
  saveBusinessDetails(userId: number, payload) {
    return $axios.put(`/users/${userId}/business-details/${payload.id}`, payload);
  },

  getUserIdentification(userId: number, identificationId) {
    return $axios.get(`/users/${userId}/identifications/${identificationId}`);
  },

  saveUserIdentification(userId: number, payload) {
    return $axios.put(`/users/${userId}/identifications`, payload);
  },

  getUserCertificates(userId) {
    return $axios.get(`users/${userId}/user-certificates`);
  },

  getCertificatesById(userId: number, certificateId: number) {
    return $axios.get(`users/${userId}/user-certificates/${certificateId}`);
  },

  deleteCertificate(userId: number, certificateId: number) {
    return $axios.delete(`users/${userId}/user-certificates/${certificateId}`);
  },

  updateCertificate(userId: number, certificateId: number, certificate) {
    return $axios.put(`users/${userId}/user-certificates/${certificateId}`, certificate);
  },

  createCertificate(certificate) {
    return $axios.post(`users/${certificate.userId}/user-certificates`, certificate);
  },

  getUserInsurances(userId: number) {
    return $axios.get(`users/${userId}/user-insurances`);
  },

  getUserInsuranceById(userId: number, insuranceId: number) {
    return $axios.get(`users/${userId}/user-insurances/${insuranceId}`);
  },

  deleteUserInsurance(userId: number, insuranceId: number) {
    return $axios.delete(`users/${userId}/user-insurances/${insuranceId}`);
  },

  updateUserInsurance(userId: number, insuranceId: number, insurance) {
    return $axios.put(`users/${userId}/user-insurances/${insuranceId}`, insurance);
  },

  createUserRating(toUserId: number, rating: number) {
    return $axios.post(`users/${toUserId}/ratings`, rating);
  },

  getUserRatingsPageList(toUserId: number, params = {}) {
    return $axios.get(`users/${toUserId}/ratings`, { params });
  },

  getUserRatingSummary(toUserId: number) {
    return $axios.get(`users/${toUserId}/ratings/summary`);
  },

  createUserInsurance(insurance) {
    return $axios.post(`users/${insurance.userId}/user-insurances`, insurance);
  },

  getUserSkills(userId) {
    return $axios.get(`/users/${userId}/skills`);
  },

  getUserSkillVerificationStatus(userId: number) {
    return $axios.get(`/users/${userId}/skills/status`);
  },

  saveUserSkills(userId: number, payload) {
    return $axios.put(`/users/${userId}/skills/status`, payload);
  },

  /**
   * Get all users
   * @returns
   */
  getUsers(filter: any) {
    let queryParam = '';

    if (filter.roleId) {
      queryParam = `?roleId=${filter.roleId}`;
    }

    if (filter.status) {
      queryParam = `${queryParam}&status=${filter.status}`;
    }

    if (filter.search) {
      queryParam = `${queryParam}&search=${filter.search}`;
    }

    queryParam += `&limit=${filter.limit}&page=${filter.page}`;

    return $axios.get(`/users${queryParam}`);
  },

  getUserWorkstations(userId: number, filter) {
    const params = new URLSearchParams(filter);

    return $axios.get(`/users/${userId}/workstations/all?${params.toString()}`);
  },

  getUserWorkstation(userId: number, userWorkstationId: number) {
    return $axios.get(`/users/${userId}/workstations/${userWorkstationId}`);
  },

  updateUserWorkstationStatus(userId, workstationId: number, formData): Promise<[]> {
    return $axios.put(`/users/${userId}/workstations/${workstationId}/status`, formData);
  },

  verifyUserAccount(userId) {
    return $axios.post(`/users/${userId}/verify`, {});
  }
};
