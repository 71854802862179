import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4295d401"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "project-filters-label" }
const _hoisted_2 = {
  key: 0,
  class: "status-count margin-left-8"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createElementBlock("aside", null, [
    _createVNode(_component_el_menu, { class: "project-job-status-filter" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredProjectJobStatus, (type, i) => {
          return (_openBlock(), _createBlock(_component_el_menu_item, {
            key: i,
            index: type.id
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, [
                _createTextVNode(_toDisplayString(type.display) + " ", 1),
                (type.projectCount > 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(type.projectCount), 1))
                  : _createCommentVNode("", true)
              ]),
              _createVNode(_component_el_checkbox, {
                class: "job-status-checkbox",
                onChange: ($event: any) => (_ctx.onSelectStatus($event, type.id))
              }, null, 8, ["onChange"])
            ]),
            _: 2
          }, 1032, ["index"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}