import { RolesEnum } from '@/core/packages/shared-library';

const meta = {
  title: 'Account Details',
  authorize: [RolesEnum.ADMIN]
};

export default [
  {
    path: '/user/tradesperson/:id/:userWorkstationId',
    name: 'account/status',
    component: () => import('@/modules/account-details/pages/AccountStatusPage.vue'),
    meta,
  },
  {
    path: 'public-profile',
    name: 'account/public-profile',
    component: () => import('@/modules/account-details/pages/ProfilePage.vue'),
    meta
  },
  {
    path: 'business-details',
    name: 'account/business-details',
    component: () => import('@/modules/account-details/pages/BusinessDetailPage.vue'),
    meta
  },
  {
    path: 'identification',
    name: 'account/identification-check',
    component: () => import('@/modules/account-details/pages/IdentificationPage.vue'),
    meta
  },
  {
    path: 'certifcation',
    name: 'account/certification',
    component: () => import('@/modules/account-details/pages/CertificationPage.vue'),
    meta
  },
  {
    path: 'insurance',
    name: 'account/insurance',
    component: () => import('@/modules/account-details/pages/InsurancePage.vue'),
    meta
  },
  {
    path: 'skills',
    name: 'account/skills',
    component: () => import('@/modules/account-details/pages/SkillsPage.vue'),
    meta
  }
];
