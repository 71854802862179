import {
  ProjectStatusesEnum, ProjectJobStatusEnum, MaterialAvailabilityEnum, ProjectTypesEnum
} from '@/core/packages/shared-library';

const initialState = ({
  details: {
    type: ProjectTypesEnum.STANDARD,
    name: null,
    postcode: null,
    categories: [],
    description: null,
    projectJobStatusId: ProjectJobStatusEnum.AWAITING_QUOTES,
    active: true,
    formValidationState: false,
  },

  statusSchedule: {
    status_schedule: 0, // TODO: Check the meaning of this, if possible make this enum to be readable
    projectStatusId: ProjectStatusesEnum.READY_FOR_HIRE,
    hasPreferredSchedule: true,
    dates: [],
    formValidationState: false,
  },

  materials: {
    materials: MaterialAvailabilityEnum.HAVE_MATERIALS,
    materialDescription: '',
    materialUrls: [{
      url: ''
    }],
    formValidationState: false,
  },

  attachments: {
    attachmentUrls: [
      { url: '' }
    ],
    images: [],
    fileList: [],
    formValidationState: false,
  }
});

export default {
  project: ({ ...initialState }),

  // TODO: update this default value for prject to only have 1 reference object.
  // Reason why temporary added in this area is because even if we create a copy of the project, still updates the original value
  defaultProjectState: ({
    details: {
      type: ProjectTypesEnum.STANDARD,
      name: null,
      postcode: null,
      categories: [],
      description: null,
      projectJobStatusId: ProjectJobStatusEnum.AWAITING_QUOTES,
      active: true,
      formValidationState: false,
    },

    statusSchedule: {
      status_schedule: 0, // TODO: Check the meaning of this, if possible make this enum to be readable
      projectStatusId: ProjectStatusesEnum.READY_FOR_HIRE,
      hasPreferredSchedule: true,
      dates: [],
      formValidationState: false,
    },

    materials: {
      materials: MaterialAvailabilityEnum.HAVE_MATERIALS,
      materialDescription: '',
      materialUrls: [{
        url: ''
      }],
      formValidationState: false,
    },

    attachments: {
      attachmentUrls: [
        { url: '' }
      ],
      images: [],
      fileList: [],
      formValidationState: false,
    }
  }),
};
