import InsuranceService from '@/core/services/insurances.service';

export default {
  getInsurances({ commit }: { commit }) {
    return InsuranceService.getInsurances()
      .then((response) => {
        commit('setInsurances', response);
      });
  }
};
