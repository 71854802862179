import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66a2634b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "project-category-filter"
}
const _hoisted_2 = { class: "parent-panel" }
const _hoisted_3 = { class: "project-filters-label" }
const _hoisted_4 = { class: "project-filters-sublabel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_option_group = _resolveComponent("el-option-group")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_el_collapse = _resolveComponent("el-collapse")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_ctx.categories)
    ? (_openBlock(), _createElementBlock("aside", _hoisted_1, [
        _createVNode(_component_el_row, {
          gutter: 10,
          class: "filter-job-categories"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  class: "select-category",
                  "collapse-tags": "",
                  placeholder: "Search Category",
                  "no-match-text": "No matching category.",
                  size: "small",
                  filterable: "",
                  multiple: "",
                  clearable: "",
                  onChange: _ctx.searchCategoryFilter,
                  modelValue: _ctx.selectedCategories,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCategories) = $event))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
                      return (_openBlock(), _createBlock(_component_el_option_group, {
                        key: category.id,
                        value: category.id,
                        label: category[_ctx.categoryLabelKey]
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.subCategories, (subCategory) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: subCategory.id,
                              value: subCategory.id,
                              label: subCategory.label
                            }, null, 8, ["value", "label"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1032, ["value", "label"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["onChange", "modelValue"]),
                _createVNode(_component_el_collapse, {
                  accordion: "",
                  class: "category-list"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category, i) => {
                      return (_openBlock(), _createBlock(_component_el_collapse_item, {
                        key: i,
                        name: category[_ctx.categoryLabelKey]
                      }, {
                        title: _withCtx(() => [
                          _createElementVNode("div", _hoisted_2, [
                            _createElementVNode("span", _hoisted_3, _toDisplayString(category[_ctx.categoryLabelKey]), 1),
                            _createVNode(_component_el_checkbox, {
                              class: "parent-checkbox",
                              indeterminate: category.isIndeterminate,
                              modelValue: category.checked,
                              "onUpdate:modelValue": ($event: any) => ((category.checked) = $event),
                              onChange: ($event: any) => (_ctx.onCheckAllCategories(category))
                            }, null, 8, ["indeterminate", "modelValue", "onUpdate:modelValue", "onChange"])
                          ])
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_el_menu, { class: "sub-category-menu" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_checkbox_group, {
                                modelValue: _ctx.selectedCategories,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedCategories) = $event)),
                                onChange: ($event: any) => (_ctx.onCheckSubCategory(category))
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.subCategories, (subcat, i) => {
                                    return (_openBlock(), _createBlock(_component_el_menu_item, {
                                      key: i,
                                      index: subcat.label
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("span", _hoisted_4, _toDisplayString(subcat.label), 1),
                                        (_openBlock(), _createBlock(_component_el_checkbox, {
                                          class: "sub-checkbox",
                                          label: subcat.id,
                                          key: subcat.id
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(''))
                                          ]),
                                          _: 2
                                        }, 1032, ["label"]))
                                      ]),
                                      _: 2
                                    }, 1032, ["index"]))
                                  }), 128))
                                ]),
                                _: 2
                              }, 1032, ["modelValue", "onChange"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["name"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}