import { RolesEnum } from '@/core/packages/shared-library';

import { PROJECTS_ROUTE } from '../../projects/routes';

/**
 * Project Stages and Tasks page
 */
export const PROJECT_STAGES_TASKS_ROUTE = 'project-stages-tasks';

export default {
  path: '/projects/:projectId/:userId/stages-tasks',
  name: PROJECT_STAGES_TASKS_ROUTE,
  props: {
    default: (route: { params: { projectId: number; userId: number }; }) => ({
      projectId: route.params.projectId,
      userId: route.params.userId
    }),
  },
  components: {
    default: () => import('@/modules/project-stages-tasks/pages/ProjectStagesTasksPage.vue'),
    headerMenu: () => import('@/core/components/project/ProjectOverviewMenu.vue')
  },
  meta: {
    title: 'Stages & Tasks',
    authorize: [RolesEnum.ADMIN],
    showBackButton: false
  },
  beforeEnter: (to, from, next) => {
    // eslint-disable-next-line no-restricted-globals
    if (!to.params.projectId || isNaN(to.params.projectId)) {
      next({ name: PROJECTS_ROUTE });
    }

    // eslint-disable-next-line no-param-reassign
    to.meta.previousRoute = from || to;
    next();
  },
};
