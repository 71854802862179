import $axios from '../utils/axios-api-config';

export default {
  /**
   * Fetch user message room by projectId, userId
   * @param userId
   * @param projectId
   * @returns UserMessageRoom[]
   */
  getUserMessageRoomByProjectId(userId: number, projectId: number) {
    return $axios.get(`/user-messages/${userId}/project/${projectId}`);
  },
  /**
   * Fetch all user message rooms
   * @param userId
   * @returns UserMessageRoom[]
   */
  getUserMessageRooms(userId: number) {
    return $axios.get(`/user-messages/${userId}`);
  },

  /**
   * Fetch all user messages by userMessageRoomId
   * @param userId
   * @param userMessageRoomId
   * @returns UserMessage[]
   */
  getUserMessages(userId: number, userMessageRoomId: number) {
    return $axios.get(`/user-messages/${userId}/user-room/${userMessageRoomId}`);
  },

  /**
   * Post message in a userMessageRoom
   * @param userId
   * @param userMessageRoomId
   * @param formData CreateUserMessageRequestDto
   * @returns UserMessage
   */
  createUserMessage(userId: number, formData: any) {
    return $axios.post(`/user-messages/${userId}`, formData);
  },

  /**
   * Create user message room if not exists
   * @param userId
   * @param formData CreateUserMessageRoomRequestDto
   * @returns UserMessageRoom
   */
  createUserMessageRoom(userId: number, formData: any) {
    return $axios.post(`/user-messages/${userId}/user-room`, formData);
  },

  /**
   * Update user messages by userMessageRoomId
   * @param userId
   * @param userMessageRoomId
   * @param formData
   * @returns UserMessages[]
   */
  updateUserMessagesByUserMessageRoom(userId: number, userMessageRoomId: number, formData: any) {
    return $axios.put(`/user-messages/${userId}/user-room/${userMessageRoomId}`, formData);
  },

  /**
   * Fetch user messages by filters(params)
   * @param userId
   * @param params
   * @returns UserMessages[]
   */
  getUserMessagesFilters(userId: number, params = {}) {
    return $axios.get(`/user-messages/${userId}/filters/`, { params });
  }

  /* putMessageHistoryByRoom(roomId: number, payload) {
    return $axios.post(`/message-history/${roomId}`, payload);
  },

  updateMessagesListByRoom(roomId: number, status = 'unread') {
    return $axios.get(`/message-history/${roomId}/${status}`);
  },

  getMessageHistoryRoomsByProject(projectId: number) {
    return $axios.get(`/message-history/${projectId}/project`);
  },

  getMessageHistoryByRoom(roomId: number) {
    return $axios.get(`/message-history/${roomId}/room`);
  },

  getMessageHistoryByUser() {
    return $axios.get('/message-history/user/list');
  },

  getMessageHistoryByToUserId(type = 'toUser', userId: number) {
    return $axios.get(`/message-history/filters/${type}/${userId}`);
  } */
};
