import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6eed9f8e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "project-stages-tasks-page page-viewport" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProjectStageTaskFilter = _resolveComponent("ProjectStageTaskFilter")!
  const _component_ProjectStagesTasksCardSkeleton = _resolveComponent("ProjectStagesTasksCardSkeleton")!
  const _component_ProjectStageTaskCard = _resolveComponent("ProjectStageTaskCard")!
  const _component_ProjectStagesTasksContainerSlot = _resolveComponent("ProjectStagesTasksContainerSlot")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_ProjectStagesTasksContainerSlot, null, _createSlots({
      default: _withCtx(() => [
        (_ctx.isLoadingProjectInfo)
          ? (_openBlock(), _createBlock(_component_ProjectStagesTasksCardSkeleton, { key: 0 }))
          : _createCommentVNode("", true),
        (!_ctx.isLoadingProjectInfo && _ctx.selectedProject && _ctx.confirmedProjectQuote)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.getFilteredProjectStages, (projectStage, index) => {
              return (_openBlock(), _createBlock(_component_ProjectStageTaskCard, {
                key: index + projectStage.id,
                projectStage: projectStage,
                stageIndex: index,
                verifyingStage: _ctx.verifyingStage,
                projectStageServiceFee: projectStage.projectStageServiceFee
              }, null, 8, ["projectStage", "stageIndex", "verifyingStage", "projectStageServiceFee"]))
            }), 128))
          : _createCommentVNode("", true)
      ]),
      _: 2
    }, [
      (!_ctx.isLoadingProjectInfo && _ctx.selectedProject && _ctx.confirmedProjectQuote)
        ? {
            name: "project-stages-tasks-filter",
            fn: _withCtx(() => [
              _createVNode(_component_ProjectStageTaskFilter, {
                availableFilters: _ctx.availableFilters,
                filters: _ctx.filterForm
              }, null, 8, ["availableFilters", "filters"])
            ]),
            key: "0"
          }
        : undefined
    ]), 1024)
  ]))
}