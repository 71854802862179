
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'rating-progress',

  props: {
    ratings: {
      required: true,
    }
  },
});
