
import { ProjectJobStatusEnum } from '@/core/packages/shared-library';
import { defineComponent, PropType } from 'vue';
import ProjectCardLayoutBImage from '@/core/components/project/project-card-layouts/ProjectCardLayoutBImage.vue';

import Expand from '@/core/components/ui/Expand.vue';

export default defineComponent({
  name: 'project-layout-B',

  emits: ['click-project'],

  components: {
    Expand,
    ProjectCardLayoutBImage
  },

  props: {
    projects: {
      type: Array as PropType<unknown>,
      required: true,
    }
  },

  data() {
    return {
      ProjectJobStatusEnum
    };
  },

  methods: {
    viewProjectInfo(projectId: number, userId: number) {
      this.$emit('click-project', projectId, userId);
    }
  }
});
