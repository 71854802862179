import { MessageStateEnum } from '@/core/packages/shared-library';

import MessageService from '@/core/services/messages.service';

import { AUTHENTICATION_STORE } from '../authentication';

export default {
  /*
  async setUnreadMessagesListByRoom(context: any, roomId: number) {
    const getMessages = await MessageService.updateMessagesListByRoom(roomId);

    return getMessages.data;
  },

  async getMessageHistoryRoomsByProject(context: any, { projectId }: { projectId: number }) {
    const getMessageHistoryRoomsByProject = await MessageService.getMessageHistoryRoomsByProject(projectId);

    return getMessageHistoryRoomsByProject.data;
  },

  async getMessageHistoryByUser() {
    const getMessageHistoryByUser = await MessageService.getMessageHistoryByUser();

    return getMessageHistoryByUser;
  },

  async getMessageHistoryByRoom(context: any, payload: { roomId: number; }) {
    const getMessages = await MessageService.getMessageHistoryByRoom(payload.roomId);

    return getMessages.data;
  }, */

  /**
   * Set total new messages for authenticated user
   * @param total
   * @returns void
   */
  setTotalNewMessages({ commit }: { commit: any }, total: any) {
    commit('setTotalNewMessages', total);
  },

  /**
   * Fetch unread user messages by filters
   * @param fromUser
   * @returns UserMessage[]
   */
  async getUnreadMessagesByUser({ dispatch, rootGetters }: { dispatch: any, rootGetters: any }, payload: { fromUser: boolean; }) {
    const type = payload.fromUser ? 'from' : 'to';
    const authUser = rootGetters[`${AUTHENTICATION_STORE}/authenticatedUser`];

    const getAllUnreadMessages = await MessageService.getUserMessagesFilters(authUser.userId, { type, state: MessageStateEnum.UNREAD }) as any;

    if (getAllUnreadMessages) {
      dispatch('setTotalNewMessages', getAllUnreadMessages?.length);
    }
  },

  /**
   * Update unread user message state by userMessageRoom
   * @param userId
   * @param projectId
   * @returns
   */
  setUnreadUserMessagesByUserMessageRoom(context: any, { userId, roomId }: { userId: number, roomId: number }) {
    return MessageService.updateUserMessagesByUserMessageRoom(userId, roomId, { state: MessageStateEnum.READ });
  },
  /**
   * Fetch user message room by projectId, userId
   * @param userId
   * @param projectId
   * @returns UserMessageRoom
   */
  getUserMessageRoomByProjectId(context, { userId, projectId }: { userId: number, projectId: number }) {
    return MessageService.getUserMessageRoomByProjectId(userId, projectId);
  },

  /**
   * Fetch all user message rooms
   * @param userId
   * @returns UserMessageRoom[]
   */
  getUserMessageRooms(context, userId: number) {
    return MessageService.getUserMessageRooms(userId);
  },

  /**
   * Fetch all user messages by userMessageRoomId
   * @param userId
   * @param userMessageRoomId
   * @returns UserMessage[]
   */
  getUserMessages(context, { userId, userMessageRoomId }: { userId: number, userMessageRoomId: number }) {
    return MessageService.getUserMessages(userId, userMessageRoomId);
  },

  /**
   * Post message in a userMessageRoom
   * @param userId
   * @param userMessageRoomId
   * @param formData CreateUserMessageRequestDto
   * @returns UserMessage
   */
  createUserMessage(context, { userId, formData }: { userId: number, formData: any }) {
    return MessageService.createUserMessage(userId, formData);
  },

  /**
   * Create user message room if not exists
   * @param userId
   * @param formData CreateUserMessageRoomRequestDto
   * @returns UserMessageRoom
   */
  createUserMessageRoom(context, { userId, formData }: { userId: number, formData: any }) {
    return MessageService.createUserMessageRoom(userId, formData);
  },

  addMemberToChatRoom(context: any, {
    member, referenceId, userMessageRooms, callback
  }: { member: any, referenceId: number, userMessageRooms: any, callback: any }) {
    if (member) {
      const findUserMessageRoom = userMessageRooms.find((p: { id: number; }) => p.id === referenceId);

      if (findUserMessageRoom) {
        callback(findUserMessageRoom, member);
      }
    }
  },

  removeMemberFromChatRoom(context: any, {
    member, referenceId, userMessageRooms, callback
  }: { member: any, referenceId: number, userMessageRooms: any, callback: any }) {
    if (member) {
      const findUserMessageRoom = userMessageRooms.find((p: { id: number; }) => p.id === referenceId);
      const findUserMessageRoomMembers = userMessageRooms[userMessageRooms.indexOf(findUserMessageRoom)].members.find((m: { id: any; }) => m.id === member.id);

      if (findUserMessageRoom) {
        callback(findUserMessageRoom, findUserMessageRoomMembers);
      }
    }
  },
};
