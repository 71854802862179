// TODO: set to specific type for all unknown type
export default {
  removeProjectAttachmentFile(state, file) {
    const index = state.project.attachments.images.findIndex((item) => item.attachment === file);

    state.project.attachments.images.splice(index, 1);
  },

  updateProjectAttachments(state, file) {
    state.project.attachments.images.push(file);
  },

  resetProjectState(state, defaultProjectState) {
    state.project = defaultProjectState;
  }
};
