import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52f365c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "margin-horizontal-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["chip", { 'has-image' : _ctx.icon}]),
    style: _normalizeStyle({ background: _ctx.backgroundColor })
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.icon
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.label), 1)
  ], 6))
}