import ProjectService from '@/core/services/projects.service';

export default {
  async getProjectCategories({ commit }: { commit}): Promise<unknown> {
    const projectCategories = await ProjectService.getProjectCategories();

    // We also update the state every call on this method to update the project categories
    if (projectCategories) {
      commit('setProjectCategories', projectCategories);
    }

    return projectCategories;
  },

  async getCategories({ commit }): Promise<unknown> {
    const categories = await ProjectService.getCategories();

    // We also update the state every call on this method to update the project categories
    if (categories) {
      commit('setCategories', categories);
    }

    return categories;
  },

  async getProjectQuoteStatusWithCountState(context, filters = {}): Promise<unknown> {
    return ProjectService.getProjectQuoteStatusWithCountStatus(filters);
  },

  async getProjectJobStatusWithCountState(context, payload): Promise<unknown> {
    const { type, projectCreatorId } = payload;

    return ProjectService.getProjectStatusWithCountStatus(type, projectCreatorId);
  },

  /**
   * Get all project list
   * @param context
   * @param filters
   * @returns
   */
  async getProjectPageList(context, filters = {}): Promise<unknown> {
    return ProjectService.getProjectPageList(filters);
  },

  /**
   * Set the project statuses
   * @param context
   * @returns
   */
  async getProjectStatuses(context) {
    return ProjectService.getProjectStatus()
      .then((response: unknown) => {
        context.commit('setProjectStatuses', response);

        return response;
      });
  },

  getProjectById(context, projectId: number) {
    return ProjectService.getProjectById(projectId)
      .then((response: unknown) => {
        context.commit('setSelectedProject', response);

        return response;
      });
  }
};
