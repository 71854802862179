import { RolesEnum } from '@/core/packages/shared-library';

export default {
  isNavCollapse: null,

  sidebarMenu: [
    {
      label: 'Tradespeople',
      icon: 'dashboard-menu-icon.svg',
      name: 'tradespeople',
      route: '/user/tradesperson',
      authorize: [RolesEnum.ADMIN]
    },
    // {
    //   label: 'Property Owners',
    //   icon: 'dashboard-menu-icon.svg',
    //   name: 'property-owners',
    //   route: '/user/property-owners',
    //   authorize: [RolesEnum.ADMIN]
    // },
    // {
    //   label: 'Projects',
    //   icon: 'dashboard-menu-icon.svg',
    //   name: 'projects',
    //   route: '/projects',
    //   authorize: [RolesEnum.ADMIN]
    // },
    {
      label: 'Cohorts',
      icon: 'dashboard-menu-icon.svg',
      name: 'course-schedules',
      route: '/course-schedules',
      authorize: [RolesEnum.ADMIN]
    },
  ],
  generalSortFields: [{ label: 'Date Created', sortBy: 'dateCreated', sortOrder: 'DESC' }] as { label: string, sortBy: string, sortOrder: string }[],
};
