<template>
  <el-container>
    <div>
      <div class="desktop-img">
        <img src="/403.jpg" />
        <h2>
          STOP THIS IS A <br />
          FORBIDDEN KINGDOM
        </h2>
        <router-link to="/" custom v-slot="{ href }">
          <a class="common-button" :href="href">Take Me Back</a>
        </router-link>
      </div>
      <div class="mobile-img">
        <div class="text-container">
          <h2>
            STOP THIS IS A <br />
            FORBIDDEN KINGDOM
          </h2>
          <router-link to="/" custom v-slot="{ href }">
            <a class="common-button" :href="href">Take Me Back</a>
          </router-link>
        </div>
      </div>
    </div>
  </el-container>
</template>

<style lang="scss" scoped>
.el-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .mobile-img {
    display: block;
    background: url("/403-mobile.jpg");
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    .text-container {
      position: absolute;
      bottom: 60px;
      width: 100%;

      h2 {
        text-align: center;
        font-size: 24px;
        color: #fff;
      }
    }
  }

  .desktop-img {
    display: none;

    h2 {
      text-align: center;
    }

    img {
      max-width: 700px;
      width: 100%;
      margin: 0 auto;
    }
  }

  a.common-button {
    background: #1a50b7;
    text-decoration: none;
    color: #fff;
    box-sizing: border-box;
    display: inline-block;
    text-align: center;
    display: block;
    max-width: 145px;
    margin: 8px auto;
    font-size: 14px;
  }
}

@include media-sm() {
  .el-container {
    padding: 16px;

    .mobile-img {
      display: none;
    }

    .desktop-img {
      display: block;

      h2 {
        font-size: 32px;
      }
    }
  }
}
</style>
