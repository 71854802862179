
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import UserThumbnail from '@/core/components/ui/UserThumbnail.vue';

import { PROJECT_QUOTES_STORE } from '../../../../store/modules/project-quotes';

export default defineComponent({
  name: 'project-job-status-confirmed-more-info-card',
  props: {
    projectQuote: Object,
    user: Object
  },

  components: {
    UserThumbnail
  },

  data() {
    return {
      tpProfileImage: ''
    };
  },
  computed: {
    ...mapGetters(PROJECT_QUOTES_STORE, [
      'getTotalProjectStageDurationDays',
      'getTotalProjectStageTasks'
    ]),
  },

  async created() {
    const { projectQuote } = this.$props;
    const { projectQuoteCreator } = projectQuote as any;
    const { userPublicProfile } = projectQuoteCreator;

    this.tpProfileImage = userPublicProfile.profileImage;
  }
});
