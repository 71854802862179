export default {
  path: 'user/tradesperson/:userId/profile',
  name: 'account/profile',
  props: {
    default: (route: { params: { userId: number; }; }) => ({ userId: route.params.userId }),
  },
  component: () => import('@/modules/account-profile/pages/AccountProfilePage.vue'),
  meta: {
    title: 'Profile'
  }
};
