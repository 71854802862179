
import { ProjectJobStatusEnum, User } from '@/core/packages/shared-library';
import { defineComponent } from 'vue';

import Expand from '@/core/components/ui/Expand.vue';
import appFilters from '@/filters';

export default defineComponent({
  name: 'po-message-list-item',
  components: {
    Expand
  },
  emits: ['select-user-message-room'],
  props: {
    userMessageRoom: Object,
    selectedUserMessageRoom: {
      type: Object,
      default() {
        return null;
      }
    },
  },
  data() {
    return {
      ProjectJobStatusEnum,
      projectAttachmentThumbnail: null as null | string,
    };
  },
  computed: {
    member() {
      return (this as any).userMessageRoom.fromUser;
    },
  },
  created() {
    (this as any).loadUserThumbnail();
  },
  methods: {
    async loadUserThumbnail() {
      const { userPublicProfile } = (this as any).userMessageRoom?.fromUser as User;

      if (userPublicProfile?.profileImage) {
        (this as any).projectAttachmentThumbnail = await appFilters.formatToImageSourceUrl(userPublicProfile?.profileImage);
      }
    },
    selectUserMessageRoom(userMessageRoom: any) {
      (this as any).$emit('select-user-message-room', userMessageRoom);
    },
  }
});
