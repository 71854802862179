
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'loader',
  props: {
    show: {
      type: Boolean
    },
  }
});
