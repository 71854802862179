import $axios from '../utils/axios-api-config';

export default {
  /**
   * Remove the filename
   * @param key
   * @returns
   */
  removeFile(key: string) {
    return $axios.delete(`/upload?key=${key}`);
  },

  /**
   * Fetch the image
   * @param key {string} filename from bucket object
   * @returns
   */
  getFile(key: string, token: string) {
    return $axios.get(`/upload?key=${key}&${encodeURIComponent(token)}`);
  }
};
