import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, Transition as _Transition, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45588f21"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "account-details page-viewport"
}
const _hoisted_2 = { class: "nav-menu page-viewport" }
const _hoisted_3 = { class: "sub-menu-container padding-top-64" }
const _hoisted_4 = { class: "menu-label text-ellipsis" }
const _hoisted_5 = { class: "form-container main-content page-viewport" }
const _hoisted_6 = { class: "padding-horizontal-32 padding-vertical-40" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_ctx.accountStatuses && _ctx.userWorkstation)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("aside", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_el_menu, {
              "default-active": _ctx.getCurrentRoute,
              router: false,
              ref: "accountSidebar",
              class: "el-menu-vertical",
              "background-color": "#ffff",
              "text-color": "#465262",
              "active-text-color": "#465262"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus, (menu, i) => {
                  return (_openBlock(), _createBlock(_component_el_menu_item, {
                    key: i,
                    index: menu.name,
                    onClick: _ctx.onClickMenu
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_4, _toDisplayString(menu.label), 1),
                      _createElementVNode("span", {
                        class: _normalizeClass(_ctx.getAccountMenuStatus(menu.name, i).type)
                      }, _toDisplayString(_ctx.getAccountMenuStatus(menu.name, i).label), 3)
                    ]),
                    _: 2
                  }, 1032, ["index", "onClick"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["default-active"])
          ])
        ]),
        _createElementVNode("section", _hoisted_5, [
          _createVNode(_component_el_scrollbar, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_6, [
                (_ctx.selectedUser)
                  ? (_openBlock(), _createBlock(_component_router_view, {
                      key: 0,
                      user: _ctx.selectedUser,
                      status: _ctx.status
                    }, {
                      default: _withCtx(({ Component }) => [
                        _createVNode(_Transition, {
                          name: "fade",
                          mode: "out-in"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 1
                    }, 8, ["user", "status"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          })
        ])
      ]))
    : _createCommentVNode("", true)
}