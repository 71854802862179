import { RolesEnum } from '@/core/packages/shared-library';

export default {
  mobileBackToggle: (state: { mobileBackToggle: any; }) => state.mobileBackToggle,
  totalNewMessages: (state: { totalNewMessages: any; }) => state.totalNewMessages,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getRoomId: () => (project: { id: any; projectCreatorId: any; }, quote: { projectQuoteCreatorId: any; }, user: { roleId: RolesEnum; id: any; }, quoteCreatorId: any) => {
    let referenceId = quote && user.roleId === RolesEnum.PROJECT_OWNER ? quote.projectQuoteCreatorId : user.id;

    if (quoteCreatorId) {
      referenceId = quoteCreatorId;
    }

    return `${project.id}-${referenceId}-${project.projectCreatorId}`;
  },
};
