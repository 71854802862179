import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27a8f60a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "public-profile-form" }
const _hoisted_2 = { class: "text-value" }
const _hoisted_3 = { class: "text-value" }
const _hoisted_4 = { class: "text-value" }
const _hoisted_5 = {
  key: 0,
  class: "gallery-images"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_CommonAttachmentLayout = _resolveComponent("CommonAttachmentLayout")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_ImageTextDisplay = _resolveComponent("ImageTextDisplay")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_el_form, {
      model: _ctx.record,
      ref: "tpPublicProfileForm",
      "label-position": "top",
      size: "small"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          label: "Profile Description",
          prop: "description"
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.record.description), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "Address",
          prop: "description"
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.record.businessAddress), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "Postcode",
          prop: "description"
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.record.businessPostcode), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "Profile Picture",
          prop: "profileImage"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CommonAttachmentLayout, {
              classes: 'profile-image',
              src: _ctx.profileImage
            }, null, 8, ["src"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "Gallery",
          prop: "gallery",
          style: {"line-height":"19px"}
        }, {
          default: _withCtx(() => [
            (_ctx.galleryImageUrls.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.galleryImageUrls, (image, i) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "gallery-item margin-right-8",
                      key: i
                    }, [
                      _createVNode(_component_el_image, {
                        src: image.src,
                        fit: "cover"
                      }, null, 8, ["src"])
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (!_ctx.isImageRequestDone)
              ? (_openBlock(), _createBlock(_component_ImageTextDisplay, {
                  key: 1,
                  label: 'Fetching attachments..'
                }, null, 8, ["label"]))
              : _createCommentVNode("", true),
            (_ctx.isImageRequestDone && !_ctx.hasGallery)
              ? (_openBlock(), _createBlock(_component_ImageTextDisplay, {
                  key: 2,
                  label: 'No photos in gallery.'
                }, null, 8, ["label"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"])
  ]))
}