
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'chip-state',

  props: {
    label: String,
    icon: String,
    backgroundColor: String,
  }
});
