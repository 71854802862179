export default {
  /**
   * Set the navigation menu only when required
   * @param state
   * @param isOpen
   */
  setNavCollapseState(state, currentState: boolean) {
    state.isNavCollapse = currentState;
  },
};
