import { RolesEnum } from '@/core/packages/shared-library';

export const CREATE_COURSE_SCHEDULE_ROUTE = 'create-course-schedule';

export default {
  path: '/course-schedule/create',
  name: CREATE_COURSE_SCHEDULE_ROUTE,
  component: () => import('@/modules/create-course-schedule/pages/CreateCourseSchedulePage.vue'),
  meta: {
    title: 'Create Cohort',
    authorize: [RolesEnum.ADMIN],
  }
};
