<template>
  <p>
    <a :href="link" target="_blank" title="download">Download</a>
  </p>
</template>
<script>
export default {
  name: 'image-download-link',
  props: {
    link: String
  },
};
</script>
<style lang="scss" scoped>
  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
</style>
