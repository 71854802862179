
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'project-stage-task-filter',
  props: {
    filters: Object,
    availableFilters: Array,
  },
  computed: {
    formEntity(): any {
      return (this as any).filters;
    }
  },
});
