
import { defineComponent } from 'vue';

export default defineComponent({

  created() {
    this.$router.push('/user/tradesperson');
  }

});
