
import { defineComponent, PropType } from 'vue';
import { mapActions } from 'vuex';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import CardWrapper from '@/core/components/ui/CardWrapper.vue';
import UserVerificationStatus from '@/core/components/account/UserVerificationStatus.vue';
import PublicProfileForm from '@/modules/account-details/components/PublicProfileFormComponent.vue';
import { User } from '@/core/models/user';
import UserVerifyActions from '@/core/components/account/UserVerifyActions.vue';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  name: 'profile-page',

  components: {
    CardWrapper,
    UserVerificationStatus,
    PublicProfileForm,
    UserVerifyActions
  },

  props: {
    user: Object as PropType<User>
  },

  data() {
    return {
      UserVerificationStatusEnum,
      publicProfile: {
        description: '',
        profileImage: null,
        userVerificationStatus: UserVerificationStatusEnum.NOT_ADDED,
      }
    };
  },

  async created() {
    const user = this.user as any;
    const profile = user.userPublicProfile as any;

    this.getUserProfile({
      userId: user.id,
      profileId: profile.id
    })
      .then((response) => {
        this.publicProfile = response;
      });
  },

  computed: {
    hasSubmitted() {
      const profile = this.publicProfile as any;
      return parseInt(profile.userVerificationStatus, 10) > 0;
    }
  },

  methods: {
    ...mapActions(USERS_STORE, [
      'getUserProfile',
      'saveUserProfile',
      'getAccountStatusesByUserId'
    ]),

    nextSection() {
      this.$router.push({ name: 'account/business-details' });
    },
    approve() {
      const info = this.publicProfile;
      info.userVerificationStatus = this.UserVerificationStatusEnum.VERIFIED;
      this.submit(info, 'approved');
    },
    reject() {
      const info = this.publicProfile;
      info.userVerificationStatus = this.UserVerificationStatusEnum.REJECTED;
      this.submit(info, 'rejected');
    },
    submit(formData, action) {
      const { id } = this.$route.params;
      this.saveUserProfile({ userId: id, profile: formData })
        .then(() => {
          (this as any).$notify.success({
            message: `Public profile successfully ${action}.`
          });
        })
        .catch((e) => {
          (this as any).$notify.error({
            message: e || 'Error submitting Public Profile details.'
          });
        })
        .finally(() => {
          this.getAccountStatusesByUserId(id);
        });
    }
  }
});
