<template>
  <div class="list-container">
    <el-row>
      <el-col :span="12">
        <el-input v-model="search" v-debounce:500="onSearch" placeholder="Search course name"></el-input>
      </el-col>
      <el-col :span="12" style="text-align: right;">
        <el-button type="primary" @click="goToCreatePage()">Create</el-button>
      </el-col>
      <!-- <el-col :span="12" style="text-align: right;">
        <el-select v-model="currentStep" placeholder="Select step" @change="onFilterStep">
          <el-option
            v-for="step in steps"
            :key="step.value"
            :label="step.label"
            :value="step.value">
          </el-option>
        </el-select>
      </el-col> -->
    </el-row>
    <el-row v-for="(courseSchedule, index) in list" :key="index">
      <el-col :span="4">
        <div>
          <h1 class="name">
            {{ formatScheduleName(courseSchedule.startDate) }}
          </h1>
          <p class="contact-no">{{ courseSchedule.course.name }}</p>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="status">
          <!-- <p><span class="label">Spot Limit</span>: <span>{{ courseSchedule.spotLimit }}</span></p> -->
        </div>
      </el-col>
      <el-col :span="8">
        <!-- <div class="status">
          <p><span class="label">Current Step</span>: <span>{{ getCurrentStep(user.userVerificationStatus) }}</span></p>
        </div> -->
        <div class="status">
          <p><span class="label">Spot Limit</span>: <span>{{ courseSchedule.spotLimit }}</span></p>
          <p><span class="label">Duration</span>: <span>{{ courseSchedule.duration }}</span></p>
        </div>
      </el-col>
      <el-col :span="8" class="btn-view-account">
        <el-button type="danger" @click="removeCourseSchedule(courseSchedule.id, courseSchedule.startDate)">Delete</el-button>
        <el-button type="primary" @click="updateCourseSchedule(courseSchedule.id)">Update</el-button>
      </el-col>
    </el-row>
    <div v-if="!(list && list.length)">
      <h3>No records found</h3>
    </div>
    <div v-else class="pagination">
      <el-pagination
          :current-page="pageFilters.page"
          :page-size="pageFilters.limit"
          @current-change="paginatePage"
          @prev-click="paginatePage"
          @next-click="paginatePage"
          background
          layout="prev, pager, next"
          :total="list.meta && list.meta.totalItems > 0 ? list.meta.totalItems : 0">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { UserVerificationStatusEnum, RolesEnum } from '@/core/packages/shared-library';
import { COURSE_STORE } from '@/store/modules/course';
import { VerificationStepsEnum } from '@/core/enums/verification-steps.enum';
import { toStepStatus, toVerificationStatus } from '@/core/helpers/verificationStatusNormalize';
import userPageListMixin from '@/mixins/users/user-page-list.mixin';
// import { COURSE_ROUTE } from '../routes';

import appFilters from '@/filters';

const ALL = 'all';

export default {
  name: 'Course',

  mixins: [userPageListMixin],

  data: () => {
    return {
      list: [],
      RolesEnum,
      account: {
        link: '/users/account'
      },
      search: '',
      listFilter: {
      },
      currentStep: ALL,
      steps: [
        {
          label: 'All',
          value: ALL
        },
        {
          label: 'Add Details',
          value: VerificationStepsEnum.ADD_DETAILS
        },
        {
          label: 'Submit Details',
          value: VerificationStepsEnum.SUBMIT_DETAILS
        },
        {
          label: 'Verifying',
          value: VerificationStepsEnum.VERIFYING
        },
        {
          label: 'Verified',
          value: VerificationStepsEnum.VERIFIED
        }
      ],
      pageFilters: {
        page: 1,
        limit: 10,
      },
      pageListMetadata: null,
    };
  },

  computed: {
    ...mapGetters(COURSE_STORE, [
      'courses',
      'coursesPaginate'
    ]),
  },

  created() {
    this.getList();
  },

  methods: {
    ...mapActions(COURSE_STORE, [
      'deleteCourseSchedule',
      'getCourseSchedules'
    ]),

    formatScheduleName(startDate) {
      return appFilters.formatToDatetimeWithMeridian(startDate);
    },

    async getList(filter) {
      const currentFilters = {
        ...this.pageFilters,
        ...this.listFilter,
        ...filter,
        search: this.search
      };

      if (this.currentStep === ALL) {
        delete currentFilters.status;
      }
      const list = await this.getCourseSchedules(currentFilters);

      this.pageFilters = currentFilters;
      this.list = list.data;
      this.pageListMetadata = list.meta;
    },

    paginatePage(currentPage) {
      const status = this.getStatus();
      const filter = {};

      if (status) {
        filter.status = status;
      }
      this.pageFilters.page = currentPage;

      this.getList(filter);
    },

    async removeCourseSchedule(id, name) {
      // eslint-disable-next-line no-alert
      const scheduleName = this.formatScheduleName(name);
      // eslint-disable-next-line no-alert
      const confirmation = window.confirm(`Are you sure you want to delete course ${scheduleName}`);
      if (confirmation) {
        await this.deleteCourseSchedule(id).then(async () => {
          await this.getList();
        });
      } else {
        // ...
      }
    },

    updateCourseSchedule(id) {
      this.$router.push(`/course-schedule/${id}`);
    },

    getCustomerStatus(userId) {
      const customers = this.userCustomers;
      let customer;
      let status = 'NONE';

      for (let i = 0; i < customers.length; i++) {
        customer = customers[i];
        if (customer.userId === userId) {
          status = customer.status;
        }
      }

      return status;
    },

    getCurrentStep(statusId) {
      const status = toStepStatus(statusId);
      return status.replace('_', '  ');
    },

    getStatus() {
      return UserVerificationStatusEnum[
        toVerificationStatus(this.currentStep)
      ];
    },

    getStatusLabel(statusId) {
      const status = UserVerificationStatusEnum;
      return Object.keys(status).find((key) => status[key] === statusId);
    },

    onSearch(value) {
      const status = UserVerificationStatusEnum[
        toVerificationStatus(this.currentStep)
      ];
      const filter = { status };
      this.search = value;
      this.getList(filter);
    },

    onFilterStep() {
      const status = this.getStatus();
      const filter = {};

      if (status) {
        filter.status = status;
      }
      this.getList(filter);
    },

    goToCreatePage() {
      this.$router.push('/course-schedule/create');
    }
  }
};
</script>

<style lang="scss" scoped>
  .list-container {
    padding: 10px 30px 30px;
    height: 100%;
    background: #F7F9FF;
  }

  .el-row {
    margin-top: 20px;
    padding: 20px 25px 10px;
    background: #fff;
    border-radius: 6px;
  }

  .name {
    padding: 0;
    margin: 0;
    font-size: 16px;

    a {
      text-decoration: none;
    }
  }

  .contact-no {
    font-size: 14px;
    color: #898EA6;
  }

  .status {
    font-size: 14px;

    .label {
      font-weight: bold;
    }
  }

  .btn-view-account {
    .el-button {
      float: right;
      margin-right: 10px;
    }
  }

  .pagination {
    margin-top: 10px;
  }
</style>
