
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'toprightside-menu',

  emits: ['click'],

  props: {
    menuItems: {
      type: Array,
      required: true,
    },
    currentTab: {
      type: String,
      default: () => {
        return (this as any).$route.name;
      }
    }
  },

  computed: {
    selectedTab(): string {
      return (this as any).currentTab;
    }
  },

  methods: {
    handleClick(tab: { paneName: string }) {
      this.$emit('click', tab.paneName);
    },
  }
});
