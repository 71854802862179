// TODO: set to specific type for all unknown type
export default {
  setProjectCategories(state, categories: unknown) {
    state.projectCategories = categories;
  },

  setCategories(state, categories: unknown) {
    state.categories = categories;
  },

  setProjectStatuses(state, projectStatuses: unknown) {
    state.projectStatuses = projectStatuses;
  },

  setSelectedProject(state, project: unknown) {
    state.selectedProject = project;
  }
};
