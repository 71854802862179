<template>
    <p class="label">{{ label }}</p>
</template>
<script>
export default {
  name: 'image-text-display',
  props: {
    label: {
      type: String,
      default: ''
    },
  }
};
</script>
<style lang="scss" scoped>
  .label {
    color: #909399;
  }
</style>
