import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopRightSideMenu = _resolveComponent("TopRightSideMenu")!

  return (_ctx.selectedProject)
    ? (_openBlock(), _createBlock(_component_TopRightSideMenu, {
        key: 0,
        menuItems: $options.getTopRightSidebarMenu,
        currentTab: $options.getCurrentRoute,
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.handleClick($event)))
      }, null, 8, ["menuItems", "currentTab"]))
    : _createCommentVNode("", true)
}