import UploadService from '@/core/services/upload.service';
import ProjectsService from '@/core/services/projects.service';

export default {
  /**
   * Remove the project atttachment file and update the attachments state
   * @param state
   * @param file
   */
  removeProjectAttachmentFile({ commit }: { commit }, file) {
    return UploadService.removeFile(file)
      .then((response) => {
        commit('removeProjectAttachmentFile', file);
        return response;
      });
  },

  /**
   * Add new attachment file to the project attachment entity
   * @param param0
   * @param file
   */
  updateProjectAttachments({ commit }: { commit }, file) {
    commit('updateProjectAttachments', file);
  },

  /**
   * Saving new project
   * @param context
   * @param project Project Entity
   * @returns
   */
  async saveProject(context, project: unknown) {
    return ProjectsService.saveProject(project)
      .then((response) => {
        context.commit('resetProjectState', context.state.defaultProjectState);

        return response;
      });
  }
};
