import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e5e0e49"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "rating-progress margin-top-24" }
const _hoisted_2 = { class: "rating-star" }
const _hoisted_3 = { class: "rating-progress" }
const _hoisted_4 = { class: "rating-percent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_progress = _resolveComponent("el-progress")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ratings, (rating, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "rating-container margin-bottom-4",
        key: index
      }, [
        _createElementVNode("div", _hoisted_2, "Star " + _toDisplayString(rating.rating), 1),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_el_progress, {
            percentage: rating.percent,
            "show-text": false,
            "stroke-width": 12,
            color: "#264FD5"
          }, null, 8, ["percentage"])
        ]),
        _createElementVNode("div", _hoisted_4, _toDisplayString(rating.percent ? `${rating.percent}%`: ''), 1)
      ]))
    }), 128))
  ]))
}