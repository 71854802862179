<template>
  <div class="image-failed">
    <span class="container">
      <i class="el-icon-picture-outline"></i>
      <br>
      <!-- <span class="label">FAILED</span> -->
    </span>
  </div>
</template>
<script>
export default {
  name: 'image-failed'
};
</script>
<style lang="scss" scoped>
  .image-failed {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .container {
      text-align: center;
    }

    i {
      font-size: 4em;
    }

    .label {
      font-weight: bold;
      font-size: 10px;
    }
  }
</style>
