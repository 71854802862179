import * as CryptoJS from 'crypto-js';
import { AuthLogin } from '@/core/models/authentication';
import AuthService from '@/core/services/authentication.service';
import { AUTH_TOKEN, CRYPTO_SECRET_KEY } from '@/core/constants';
import { User } from '../../../core/models/user';
import { VerificationCode } from '../../../core/models/verification-code';
import ImageTokenHelper from '../../../core/helpers/image-token.helper';

export default {
  /**
   * Dispatch action for login. Sets the auth token in the login
   * @param context Vuex Context
   * @param authLogin
   * @returns
   */
  login(context, authLogin: AuthLogin) {
    const { username, password } = authLogin;

    return AuthService.login(username, password)
      .then(
        (response) => {
          const token = response.IdToken;

          if (token) {
            window.localStorage.setItem(AUTH_TOKEN, token);

            ImageTokenHelper.validateImageExpirationToken();

            return response;
          }

          return null;
        }
      );
  },

  /**
   * Registers the user based on the selected role - TP or PO
   * @param context
   * @param user
   * @returns
   */
  register(context, user: User) {
    if (!user.roleId) {
      throw new Error('user.roleId is required');
    }

    const currentUser = { ...user };

    if (user) {
      if (currentUser.password && currentUser.confirmPassword) {
        currentUser.password = CryptoJS.AES.encrypt(currentUser.password, CRYPTO_SECRET_KEY).toString();
        currentUser.confirmPassword = CryptoJS.AES.encrypt(currentUser.confirmPassword, CRYPTO_SECRET_KEY).toString();
      }
    }

    return AuthService.register(currentUser);
  },

  /**
   *
   * @param context
   * @param email
   * @returns
   */
  resendVerificationCode(context, email: string) {
    return AuthService.resendVerificationCode(email);
  },

  /**
   *
   * @param context
   * @param verificationCode
   * @returns
   */
  verifyVerificationCode(context, verificationCode: VerificationCode) {
    return AuthService.verifyVerificationCode(verificationCode);
  },

  /**
   * Sends link to user's email
   * @param context
   * @param email
   * @returns
   */
  resetPassword(context, email: string) {
    return AuthService.resetPassword(email);
  },

  /**
   * Dispatch action for logout
   * @returns
   */
  logout(): void {
    // TODO: Remove local storage in here. Only the AUTH_TOKEN key
    window.localStorage.clear();
    window.location.reload();
  },
};
