import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    model: _ctx.businessDetailEntity,
    class: "form",
    ref: "businessDetailsForm",
    "label-position": "top",
    size: "small"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, {
        label: "Type",
        prop: "type"
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", null, _toDisplayString(_ctx.types[_ctx.businessDetailEntity.type]), 1)
        ]),
        _: 1
      }),
      (_ctx.businessDetails.type == _ctx.soletraderValue)
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 0,
            label: "Preferred Business Name (Optional)",
            prop: "businessName"
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(_ctx.businessDetailEntity.businessName || '---'), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.businessDetails.type == _ctx.limitedCompanyValue)
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 1,
            label: "Company Name",
            prop: "companyName"
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(_ctx.businessDetailEntity.companyName || '---'), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.businessDetails.type == _ctx.limitedCompanyValue)
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 2,
            label: "Company Number",
            prop: "companyNumber"
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(_ctx.businessDetailEntity.companyNumber || '---'), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.businessDetails.type == _ctx.soletraderValue)
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 3,
            label: "National Insurance Number",
            prop: "nationalInsuranceNumber"
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(_ctx.businessDetailEntity.nationalInsuranceNumber || '---'), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.businessDetails.type == _ctx.soletraderValue)
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 4,
            label: "Tax Reference Number",
            prop: "taxReferenceNumber"
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(_ctx.businessDetailEntity.taxReferenceNumber || '---'), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.businessDetails.type == _ctx.limitedCompanyValue)
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 5,
            label: "Incorporation Date",
            prop: "incorporationDate"
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(_ctx.businessDetailEntity.incorporationDate || '---'), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_el_form_item, {
        label: "VAT?",
        prop: "vat"
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", null, _toDisplayString(_ctx.businessDetailEntity.vat ? 'Yes' : 'No'), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model"]))
}