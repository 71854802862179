import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17940d5a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "padding-0 padding-sm-20" }
const _hoisted_2 = { class: "main-content paddi" }
const _hoisted_3 = { class: "profile-review-nav" }
const _hoisted_4 = { class: "profile-switcher" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_radio_button = _resolveComponent("el-radio-button")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_ProfileCardComponent = _resolveComponent("ProfileCardComponent")!
  const _component_ProfileReviewCardComponent = _resolveComponent("ProfileReviewCardComponent")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("nav", _hoisted_4, [
          _createVNode(_component_el_radio_group, {
            modelValue: _ctx.currentTab,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentTab) = $event)),
            style: {"margin-bottom":"30px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_radio_button, { label: "profile" }, {
                default: _withCtx(() => [
                  _createTextVNode("Profile Details")
                ]),
                _: 1
              }),
              _createVNode(_component_el_radio_button, { label: "reviews" }, {
                default: _withCtx(() => [
                  _createTextVNode("Customer Reviews")
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ])
      ]),
      (_ctx.currentTab == 'profile' && _ctx.user)
        ? (_openBlock(), _createBlock(_component_ProfileCardComponent, {
            key: 0,
            profile: _ctx.user,
            ratingSummary: _ctx.ratingSummary
          }, null, 8, ["profile", "ratingSummary"]))
        : _createCommentVNode("", true),
      (_ctx.currentTab == 'reviews' && _ctx.user)
        ? (_openBlock(), _createBlock(_component_ProfileReviewCardComponent, {
            key: 1,
            profile: _ctx.user,
            ratings: _ctx.ratings,
            ratingSummary: _ctx.ratingSummary,
            paginate: _ctx.pageFilters,
            paginateMeta: _ctx.pageListMetadata,
            paginatePage: _ctx.paginatePage,
            onOnSearch: _ctx.onSearchText
          }, null, 8, ["profile", "ratings", "ratingSummary", "paginate", "paginateMeta", "paginatePage", "onOnSearch"]))
        : _createCommentVNode("", true)
    ])
  ]))
}