import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c6a22f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "project-stage-task-filter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_el_select, {
      modelValue: _ctx.formEntity.status,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formEntity.status) = $event)),
      filterable: ""
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableFilters, (status, index) => {
          return (_openBlock(), _createBlock(_component_el_option, {
            key: index,
            value: status.value,
            label: status.label
          }, null, 8, ["value", "label"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}