export default {
  courseSchedules: [],

  courseSchedulePaginate: {
    limit: 10,
    page: 1,
  },

  courses: [],

  coursesPaginate: {
    limit: 10,
    page: 1,
  }
};
