import { DEFAULT_CURRENCY } from '@/core/constants';

export default {
  /**
   * Format to currency
   * @param value
   * @returns
   */
  formatToCurrency(value: string) {
    const parsedValue = parseFloat(value);

    return DEFAULT_CURRENCY + parsedValue.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
  }
};
