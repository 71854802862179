
import { defineComponent, PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import SidebarHeader from '@/core/components/layouts/SidebarHeader.vue';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import { User } from '../../models/user';

export default defineComponent({
  name: 'sidebar',

  components: {
    SidebarHeader,
  },

  props: {
    user: Object as PropType<User>,
  },

  computed: {
    ...mapGetters(['sidebarMenu', 'isNavCollapse']),

    currentRoute() {
      let { path } = this.$route;

      // find matching route
      if (path.indexOf('tradesperson') > -1) {
        path = '/user/tradesperson';
      }

      return path;
    },
  },

  created() {
  },

  methods: {
    ...mapActions(AUTHENTICATION_STORE, ['logout']),

    onNavigateStaticMenu(routeKey: string) {
      if (routeKey === 'account.profile') {
        this.$router.push({
          path: `/user/tradesperson/${this.user?.id}/profile`
        });
      }
    }
  }
});
