const initialStatePaymentForm = ({
  projectStageId: 0 as number,
  method: null as null | string,
  aspspId: null as null | number,
  destination: {
    id: null as null | string, // Identifier of the destination account if using ACCOUNT type
    type: 'ACCOUNT', // ACCOUNT or SCAN
  },
  paymentAmount: {
    currency: 'GBP' as string,
    value: 0 as number,
  },
  paymentContext: {
    deliveryAddress: {
      addressLine1: 'approved' as string,
      addressLine2: '' as string,
      country: 'GB' as string,
      postCode: 'approved' as string,
      postTown: 'approved' as string,
    },
    // merchantCategoryCode : '', //Merchant category code conform to ISO 18245, related to the type of services or goods provided for the transaction. Must be specified if paymentContextCode is either ECOMMERCEGOODS or ECOMMERCESERVICES
    // merchantCustomerIdentification : '',//Merchant customer identification, must be specified if paymentContextCode is either ECOMMERCEGOODS or ECOMMERCESERVICES
    paymentContextCode: 'PARTYTOPARTY' as string, // Indicates type of Payment Context, can be one of BILLPAYMENT, ECOMMERCEGOODS, ECOMMERCESERVICES, OTHER, PARTYTOPARTY
  },
  paymentReference: '' as string, // Reference to be used for the Payment. This will appear on the Account statement/the recipient's bank account. Min 6 to max 18 characters. Can contain alphanumeric, '-', '.', '&', '/' and space.
});

export default {
  paymentProviders: [] as any[],
  paymentForm: ({ ...initialStatePaymentForm })
};
