export default {
  /**
   * Format project refId initText
   * @param refId
   * @returns
   */
  initText(refId: string, initStrLength: number) {
    return refId.substring(0, initStrLength);
  },

  /**
   * Format project refId remainingText
   * @param refId
   * @returns
   */
  remainingText(refId: string, initStrLength: number) {
    return refId.substring(initStrLength);
  }
};
