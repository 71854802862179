import ProjectQuoteService from '@/core/services/project-quotes.service';

export default {
  /**
   * Get project quote page list
   * @param context
   * @param filters
   * @returns
   */
  getProjectQuotesPageList(context, filters = {}) {
    return ProjectQuoteService.getProjectQuotesPageList(filters);
  },

  getQuotesByProjectId(context, projectId: number) {
    return ProjectQuoteService.getQuotesByProject(projectId);
  },

  /**
   * Get the quoted projecy by authenticated user only
   * @param context
   * @param param
   * @returns
   */
  getQuotedProjectByAuthUser(context, { projectId, userId }: { projectId: number, userId: number }) {
    return ProjectQuoteService.getQuotedProjectByAuthUser(projectId, userId);
  },

  /**
   *
   * @param context
   * @param projectStages
   * @returns
   */
  getTotalStageCost(context, projectStages) {
    const stageAmounts = projectStages.map((stage) => {
      return stage.stageCost || 0;
    });

    const totalStageAmount = stageAmounts.reduce((acc, curr) => {
      return acc + curr;
    }, 0);

    return !Number.isNaN(totalStageAmount) ? parseFloat(parseFloat(totalStageAmount).toFixed(2)) : 0;
  },

  /**
   *
   * @param context
   * @param param
   * @returns
   */
  async calculateServiceFee(context, { projectId, projectStages }: { projectId: number, projectStages: any }) {
    const totalStageAmount = projectStages ? await context.dispatch('getTotalStageCost', projectStages) : 0;

    const serviceFeePayload = {
      total: totalStageAmount,
      threshold_percentage: 0,
    };

    if (!Number.isNaN(totalStageAmount) || totalStageAmount > 0) {
      const serviceFee = await ProjectQuoteService.calculateServiceFee(projectId, serviceFeePayload);

      return serviceFee;
    }

    return 0;
  },

  /**
   *
   * @param context
   * @param payload
   * @returns
   */
  async getStageCostWithServiceFee(context, payload) {
    const stageCostPercentage = payload.stageCost / payload.totalStageCost;
    const stageServiceFee = payload.serviceFee * stageCostPercentage;
    const stageCostWithServiceFee = parseFloat(payload.stageCost + stageServiceFee);

    return !Number.isNaN(stageCostWithServiceFee) ? stageCostWithServiceFee : 0;
  },

  /**
   *
   * @param param
   * @param quote
   * @returns
   */
  async getCustomerPay({ dispatch }, { projectId, quote }: { projectId: number, quote: any }) {
    const totalStageAmount = quote ? await dispatch('getTotalStageCost', quote.projectStages) : 0;
    const serviceFee = quote ? await dispatch('calculateServiceFee', { projectId, projectStages: quote.projectStages }) : 0;
    const totalStageAmoutWithServiceFee = parseFloat(parseFloat(totalStageAmount).toFixed(2)) + parseFloat(parseFloat(serviceFee).toFixed(2));
    const result = {
      total: !Number.isNaN(totalStageAmoutWithServiceFee) ? totalStageAmoutWithServiceFee : 0,
      serviceFee: !Number.isNaN(serviceFee) ? serviceFee : 0,
    };

    return result;
  },

  /**
   * Create new project quote
   * @param context
   * @param quote
   * @returns
   */
  createProjectQuote(context, quote) {
    return ProjectQuoteService.createProjectQuote(quote);
  },

  /**
   * Create new project quote
   * @param context
   * @param quote
   * @returns
   */
  updateProjectQuote(context, quote) {
    return ProjectQuoteService.updateProjectQuote(quote);
  },

  /**
   * Get project quote page list by projectId
   * @param context
   * @param projectId
   * @returns
   */
  getProjectQuotesByProject(context, projectId: number) {
    return ProjectQuoteService.getProjectQuotesByProject(projectId);
  },

  getProjectQuoteDetails(context, { projectId, projectQuoteId }: { projectId:number, projectQuoteId: number }) {
    return ProjectQuoteService.getProjectQuoteDetails(projectId, projectQuoteId);
  }
};
