
import { mapGetters } from 'vuex';
import { UserVerificationStatusEnum, ProjectJobStatusEnum } from '@/core/packages/shared-library';

import TopRightSideMenu from '@/core/components/layouts/TopRightSideMenu.vue';
// eslint-disable-next-line import/no-cycle
import { PROJECT_QUOTE_OVERVIEW_ROUTE } from '@/modules/project-quote-overview/routes';
// eslint-disable-next-line import/no-cycle
import { PROJECTS_STORE } from '@/store/modules/projects';

import { USERS_STORE } from '@/store/modules/users';
// eslint-disable-next-line import/no-cycle
import { PROJECT_STAGES_TASKS_ROUTE } from '@/modules/project-stages-tasks/routes';

export default {
  name: 'project-overview-menu',

  components: {
    TopRightSideMenu,
  },

  data: () => {
    return {
      UserVerificationStatusEnum,
    };
  },

  computed: {

    ...mapGetters(['selectedUser']),

    ...mapGetters(USERS_STORE, [
      'selectedUser',
    ]),

    ...mapGetters(PROJECTS_STORE, [
      'topRightSidebarMenu',
      'selectedProject'
    ]),

    getCurrentRoute(): string {
      return (this as any).$route.name;
    },

    getTopRightSidebarMenu(): { label: string, route: string, routeParams: string[] }[] {
      const { topRightSidebarMenu, selectedProject } = (this as any);
      const { projectJobStatus } = selectedProject;
      const isTradesperson = false;
      const isPropertyOwner = true;
      const projectJobStatusId = projectJobStatus?.id;
      const PROJECT_QUOTE_BUILDER_ROUTE = 'project-quote-create';

      const filterTopRightSidebarMenu = JSON.parse(JSON.stringify(topRightSidebarMenu));

      const ifProjectJobStatusIdIsNotYetInProgress = [
        ProjectJobStatusEnum.AWAITING_QUOTES,
        ProjectJobStatusEnum.QUOTES_RECEIVED,
        ProjectJobStatusEnum.QUOTE_ACCEPTED
      ];

      if (ifProjectJobStatusIdIsNotYetInProgress.includes(projectJobStatusId)) {
        const findProjectJobStagesRoute = filterTopRightSidebarMenu.find((menu) => menu.route === PROJECT_STAGES_TASKS_ROUTE);
        filterTopRightSidebarMenu.splice(filterTopRightSidebarMenu.indexOf(findProjectJobStagesRoute), 1);
      }

      const ifProjectJobStatusIdIsInProgress = [ProjectJobStatusEnum.QUOTE_CONFIRMED, ProjectJobStatusEnum.IN_PROGRESS, ProjectJobStatusEnum.COMPLETED];

      if (ifProjectJobStatusIdIsInProgress.includes(projectJobStatusId) || isTradesperson) {
        const findProjectQuoteOverviewRoute = filterTopRightSidebarMenu.find((menu) => menu.route === PROJECT_QUOTE_OVERVIEW_ROUTE);
        filterTopRightSidebarMenu.splice(filterTopRightSidebarMenu.indexOf(findProjectQuoteOverviewRoute), 1);
      }

      if (isPropertyOwner
        || [ProjectJobStatusEnum.QUOTE_CONFIRMED, ProjectJobStatusEnum.IN_PROGRESS, ProjectJobStatusEnum.COMPLETED].includes(projectJobStatusId)
      ) {
        const findProjectQuoteBuilderRoute = filterTopRightSidebarMenu.find((menu) => menu.route === PROJECT_QUOTE_BUILDER_ROUTE);
        filterTopRightSidebarMenu.splice(filterTopRightSidebarMenu.indexOf(findProjectQuoteBuilderRoute), 1);
      }
      return filterTopRightSidebarMenu;
    },
  },

  methods: {
    handleClick(route: string) {
      const { selectedProject, selectedUser } = (this as any);

      const { id: userId } = selectedUser;
      const { id: projectId, projectJobStatus } = selectedProject;
      const { id: projectJobStatusId } = projectJobStatus;

      if (route !== (this as any).$route.name) {
        (this as any).$router.push({
          name: route,
          params: { projectId, projectJobStatusId, userId }
        });
      }
    },
  }
};
