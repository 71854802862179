import { RolesEnum } from '@/core/packages/shared-library';

const PropertyOwners = {
  path: '/user/property-owners',
  name: 'user/property-owners',
  component: () => import('@/modules/property-owners/pages/PropertyOwnerPersonPage.vue'),
  meta: {
    title: 'Property Owners',
    authorize: [RolesEnum.ADMIN]
  }
};

const PropertyOwnerProjects = {
  path: '/user/property-owner/:id',
  name: 'user/property-owner',
  component: () => import('@/modules/projects/pages/ProjectPage.vue'),
  meta: {
    title: 'Property Owner Projects',
    authorize: [RolesEnum.ADMIN]
  }
};

export {
  PropertyOwners,
  PropertyOwnerProjects
};
