
import { defineComponent } from 'vue';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';

export default defineComponent({
  name: 'certificate-verification-state-component',

  props: {
    verificationState: String,
  },

  data() {
    return {
      UserVerificationStatusEnum,
    };
  }
});
