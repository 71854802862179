import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7523ccb0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "skills-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfileSkills = _resolveComponent("ProfileSkills")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_el_form, {
      model: _ctx.currentRecord,
      "label-position": "top",
      size: "small"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ProfileSkills, {
          skills: _ctx.getSkills,
          closableTag: true
        }, null, 8, ["skills"])
      ]),
      _: 1
    }, 8, ["model"])
  ]))
}