

import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { PROJECTS_CACHE } from '@/store/modules/attachment-cache/constants';
import { ATTACHMENT_CACHE_STORE } from '@/store/modules/attachment-cache';

export default defineComponent({
  name: 'project-layout-B-image',

  props: {
    project: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
      loadedAttachmentSources: [],
      projectAttachmentsDisplayLimit: 3,
    };
  },

  computed: {
    currentProject() {
      const { project, loadedAttachmentSources: sources } = this as any;
      const { projectAttachments } = this.project as any;

      return {
        ...project,
        attachmentDisplayCount: sources.length,
        totalAttachmentCount: projectAttachments.length,
        attachmentUrls: sources
      };
    }
  },

  mounted() {
    this.loadAttachments();
  },

  methods: {
    ...mapActions(ATTACHMENT_CACHE_STORE, ['getAttachments']),

    loadAttachments() {
      const { projectAttachments } = this.project as any;

      const list = projectAttachments
        .slice(0, this.projectAttachmentsDisplayLimit)
        .filter(Boolean)
        .map((item) => {
          return {
            attachment: item
          };
        });

      this.getAttachments({
        name: PROJECTS_CACHE,
        attachments: list
      })
        .then((values) => {
          const newValues = values.filter(Boolean);

          if (newValues.length) {
            this.loadedAttachmentSources = values;
          }
        }).catch(() => {});
    },

    setPlaceHolderImage(e) {
      // eslint-disable-next-line global-require
      e.target.src = require('@/assets/images/po-project-empty-placeholder.jpg');
    }
  }
});

