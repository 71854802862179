import { AuthenticatedUserStorage } from '../core/models/authentication';
import { SidebarMenu } from '../core/models/sidebar-menu';

export default {
  /**
   * Retrieve sidebar menus and check the authorization
   *
   * @param state
   * @param getters
   * @param rootState
   * @param rootGetters
   * @returns
   */
  sidebarMenu: (state, getters, rootState, rootGetters): SidebarMenu[] => {
    const authenticatedUser = rootGetters['authentication/authenticatedUser'] as AuthenticatedUserStorage;

    if (authenticatedUser == null) {
      throw new Error('User is not authenticated');
    }

    const menus = (<SidebarMenu[]>state.sidebarMenu).filter((menu) => {
      const isAuthorize = menu.authorize.includes(authenticatedUser.roleId);

      // check if public profile is authorize, if not, don't sow
      if (menu.name === '/wallet') {
        // check for public profile verification status
      }

      return isAuthorize;
    });

    return menus;
  },

  isNavCollapse: (state) => {
    return state.isNavCollapse;
  },

  generalSortFields: (state) => state.generalSortFields,
};
