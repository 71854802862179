
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import {
  ProjectQuote, ProjectStage, ProjectStageStatusEnum
} from '@/core/packages/shared-library';

import ProjectStagesTasksCardSkeleton from '@/core/components/project-stages-tasks/ProjectStagesTasksCardSkeleton.vue';
import ProjectStagesTasksContainerSlot from '@/core/components/project-stages-tasks/ProjectStagesTasksContainerSlot.vue';
import ProjectStageTaskCard from '@/modules/project-stages-tasks/components/ProjectStageTaskCard.vue';
import ProjectStageTaskFilter from '@/modules/project-stages-tasks/components/ProjectStageTaskFilter.vue';

import { USERS_STORE } from '@/store/modules/users';
import { PAYMENTS_STORE } from '@/store/modules/payments';
import { PROJECT_QUOTES_STORE } from '@/store/modules/project-quotes';
// eslint-disable-next-line import/no-cycle
import { PROJECTS_STORE } from '@/store/modules/projects';

export default defineComponent({
  name: 'project-stages-tasks-page',
  components: {
    ProjectStagesTasksCardSkeleton,
    ProjectStagesTasksContainerSlot,
    ProjectStageTaskCard,
    ProjectStageTaskFilter,
  },
  data: () => {
    return {
      selectedProjectStage: null as null | ProjectStage,
      isLoadingProjectInfo: null as boolean | null,
      verifyingStage: false as boolean,
      filterForm: {
        status: null as null | ProjectStageStatusEnum,
        sort: 0 as number,
        filter: 0 as number,
      },
      availableFilters: [
        { label: 'All', value: null },
        { label: 'Not Started', value: [ProjectStageStatusEnum.NOT_STARTED, ProjectStageStatusEnum.NOT_STARTED_POKED] },
        { label: 'In Progress', value: [ProjectStageStatusEnum.IN_PROGRESS, ProjectStageStatusEnum.IN_PROGRESS_POKED] },
        { label: 'Stage Complete', value: [ProjectStageStatusEnum.STAGE_COMPLETE] },
        { label: 'Cancelled', value: [ProjectStageStatusEnum.CANCELLED] }
      ],
    };
  },

  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await (vm as any).initialize();
    });
  },

  computed: {
    ...mapGetters(PROJECTS_STORE, [
      'selectedProject'
    ]),

    ...mapGetters(PAYMENTS_STORE, ['paymentProviders']),

    projectQuote(): null | ProjectQuote {
      if ((this as any).selectedProject?.projectQuotes?.length) {
        return (this as any).selectedProject?.projectQuotes[0];
      }
      return null;
    },

    confirmedProjectQuote(): null | ProjectQuote {
      const quotes = (this as any).selectedProject?.projectQuotes as any;
      const confirmedQuote = quotes.filter((quote) => quote.agreeTermsConsumerDisclosure === true);
      if (confirmedQuote.length) {
        return confirmedQuote[0];
      }

      return null;
    },

    getFilteredProjectStages(): ProjectStage[] {
      if ((this as any).confirmedProjectQuote) {
        let stages = (this as any).confirmedProjectQuote.projectStages;

        if (this.filterForm.status !== null && Array.isArray(stages) && stages.length) {
          stages = stages.filter((stage) => (this as any).filterForm.status.includes(stage.projectStageStatus?.id));
        }

        return stages || [];
      }
      return [];
    },
  },

  methods: {
    ...mapActions(USERS_STORE, ['getUserById']),

    ...mapActions(PROJECTS_STORE, ['getProjectById']),

    ...mapActions(USERS_STORE, [
      'getUserCustomerAccount',
      'getUserRatingsPageList',
      'getUserRatingSummary'
    ]),

    ...mapActions(PROJECT_QUOTES_STORE, ['getTotalStageCost', 'calculateServiceFee']),

    async initialize() {
      const { projectId, userId } = (this.$route as any).params;

      await this.getProjectById(projectId);
      await this.getUserById(userId);

      this.isLoadingProjectInfo = true;

      if (this.selectedProject) {
        if ((this as any).confirmedProjectQuote?.projectStages) {
          const getProjectStageServiceFeePromises = [] as number[];
          (this as any).confirmedProjectQuote.projectStages.map((projectStage, i) => {
            getProjectStageServiceFeePromises.push((this as any).getProjectStageServiceFee(i) as number);
            return projectStage;
          });

          const getProjectStageServiceFeeResponses = await Promise.all(getProjectStageServiceFeePromises);

          if (getProjectStageServiceFeeResponses) {
            (this as any).confirmedProjectQuote.projectStages = (this as any).confirmedProjectQuote.projectStages.map((projectStage, i) => {
              const projectStageWithServiceFee = { ...projectStage, projectStageServiceFee: getProjectStageServiceFeeResponses[i] };
              return projectStageWithServiceFee;
            });
          }
        }
      }

      this.isLoadingProjectInfo = false;
    },

    async initiateProjectStageDeposit(projectStage: ProjectStage) {
      await (this as any).getPaymentProviders();
      (this as any).selectedProjectStage = projectStage;
      (this as any).openProjectStageDepositModal = true;
    },

    async initiateApproveReleaseProjectStage(projectStage: ProjectStage) {
      (this as any).selectedProjectStage = projectStage;
      (this as any).openProjectStageApproveReleaseDepositModal = true;
    },

    async getProjectStageServiceFee(stageIndex: number): Promise<number> {
      const totalStageCost = await (this as any).getTotalStageCost((this as any).confirmedProjectQuote.projectStages);
      const totalServiceFee = await (this as any).calculateServiceFee({ projectStages: (this as any).confirmedProjectQuote.projectStages });

      const stageCostPercentage = (this as any).confirmedProjectQuote.projectStages[stageIndex].stageCost / totalStageCost;
      const stageServiceFee = totalServiceFee * stageCostPercentage;

      return parseFloat(stageServiceFee.toFixed(2));
    }
  },
});
