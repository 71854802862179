
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { ATTACHMENT_CACHE_STORE } from '@/store/modules/attachment-cache';
import {
  USER_PUBLIC_PROFILE_CACHE,
  USER_PUBLIC_GALLERY_CACHE
} from '@/store/modules/attachment-cache/constants';

import ImageTextDisplay from '@/core/components/ui/ImageTextDisplay.vue';

import CommonAttachmentLayout from '@/core/components/ui/CommonAttachmentLayout.vue';

export default defineComponent({
  name: 'public-profile-form-component',

  props: {
    record: {
      type: Object,
      required: true,
    }
  },

  components: {
    ImageTextDisplay,
    CommonAttachmentLayout
  },

  data: () => {
    return {
      profileImage: null as any,
      galleryImageUrls: [],
      isImageRequestDone: false,
      hasGallery: true,
    };
  },

  watch: {
    'record.galleries': {
      deep: true,
      async handler(list) {
        if (list) {
          await this.loadGalleryImageUrls(list);
        }
      }
    },
    'record.profileImage': {
      deep: true,
      handler(profileImage) {
        if (profileImage) {
          this.loadProfileImageUrl(profileImage);
        }
      }
    }
  },

  methods: {
    ...mapActions(ATTACHMENT_CACHE_STORE, ['getAttachments']),

    loadProfileImageUrl(value) {
      this.getAttachments({
        name: USER_PUBLIC_PROFILE_CACHE,
        attachments: [{ attachment: value }],
        returnFirstOnly: true,
      })
        .then(({ src }) => {
          this.profileImage = src;
          this.isImageRequestDone = true;
        }).catch(() => {
          this.isImageRequestDone = true;
        });
    },

    loadGalleryImageUrls(list) {
      this.getAttachments({
        name: USER_PUBLIC_GALLERY_CACHE,
        attachments: list
      })
        .then((values) => {
          this.galleryImageUrls = values;
          this.hasGallery = true;
          this.isImageRequestDone = true;
        }).catch(() => {
          this.isImageRequestDone = true;
          this.hasGallery = false;
        });
    }
  }
});
