
import { defineComponent } from 'vue';
import ProfileSkills from '@/core/components/account/ProfileSkills.vue';

export default defineComponent({
  name: 'skills-form-component',

  components: {
    ProfileSkills,
  },

  props: {
    record: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    rawCategories: {
      type: Array,
      required: true,
    }
  },

  data() {
    return {
      selectSkill: [],
    };
  },

  computed: {
    currentRecord() {
      return (this as any).record;
    },

    getSkills() {
      const data = [] as string[];

      this.record.categories.forEach((category) => {
        const catObj = {} as any;
        const parentCategory = this.rawCategories.find((c: any) => c.id === category[0]) as any;

        if (parentCategory && parentCategory.subCategories) {
          catObj.parentCategory = JSON.parse(JSON.stringify(parentCategory));
          delete catObj.parentCategory.children;

          catObj.subCategory = parentCategory.subCategories.find((sub) => sub.id === category[1]);
          data.push(catObj);
        }
      });

      return data;
    }
  },

  methods: {
    getSubCategory(ids) {
      let subCategory;

      const parentCategory = this.categories.find((c: any) => c.value === ids[0]) as any;

      if (parentCategory && parentCategory.children) {
        subCategory = parentCategory.children.find((sub) => sub.value === ids[1]);
        return subCategory.label;
      }

      return subCategory;
    }
  }
});
