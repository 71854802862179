export default {
  /**
   * Format name to their initials
   * @param name
   * @returns
   */
  formatToNameInitials(name: string) {
    const split = name.split(' ');
    const initialsStr = `${split[0].charAt(0)}${split[1].charAt(0)}`;
    return initialsStr.toUpperCase();
  }
};
