<template>
  <div class="list-container">
    <el-row>
      <el-col :span="12">
        <el-input v-model="search" v-debounce:500="onSearch" placeholder="Search"></el-input>
      </el-col>
    </el-row>
    <el-row v-for="(user) in list.items" :key="user.id">
      <el-col :span="8">
        <div>
          <h1 class="name">
            <a
              href="#"
                @click="viewProjects(user.id)">{{ user.firstName }} {{ user.lastName }}
            </a>
          </h1>
          <p class="contact-no">{{ user.contactNo }}</p>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="status">
          <p><span class="label">Location</span>: <span>{{ user.postcode }}</span></p>
        </div>
      </el-col>
      <el-col :span="8" class="btn-view-project">
        <el-button type="primary" @click="viewProjects(user.id)">View Projects</el-button>
      </el-col>
    </el-row>
    <div class="pagination">
      <el-pagination
          :current-page="pageFilters.page"
          :page-size="pageFilters.limit"
          @current-change="paginatePage"
          @prev-click="paginatePage"
          @next-click="paginatePage"
          background
          layout="prev, pager, next"
          :total="list.meta && list.meta.totalItems > 0 ? list.meta.totalItems : 0">
      </el-pagination>
    </div>
    <div v-if="!(list.items && list.items.length)">
      <h1>No Records</h1>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { RolesEnum } from '@/core/packages/shared-library';
import { USERS_STORE } from '@/store/modules/users';
import userPageListMixin from '@/mixins/users/user-page-list.mixin';

export default {
  name: 'Tradesperson',

  mixins: [userPageListMixin],

  data: () => {
    return {
      RolesEnum,
      search: '',
      listFilter: {
        roleId: RolesEnum.PROJECT_OWNER
      },
      pageFilters: {
        page: 1,
        limit: 10,
      },
      pageListMetadata: null,
    };
  },

  computed: {
    ...mapGetters([
      'users',
      'usersPaginate'
    ]),
    ...mapState(USERS_STORE, {
      list: (state) => state.users
    })
  },

  created() {
    this.getList();
  },

  methods: {
    ...mapActions(USERS_STORE, ['getUsers']),

    async getList(filter) {
      const currentFilters = {
        ...this.pageFilters,
        ...this.listFilter,
        ...filter,
        search: this.search
      };
      const list = await this.getUsers(currentFilters);

      this.pageFilters = currentFilters;
      this.list = list.items;
      this.pageListMetadata = list.meta;
    },

    paginatePage(currentPage) {
      const status = this.getStatus();
      const filter = {};

      if (status) {
        filter.status = status;
      }
      this.pageFilters.page = currentPage;

      this.getList(filter);
    },

    viewProjects(id) {
      this.$router.push({ path: `/user/property-owner/${id}` });
    },

    onSearch(value) {
      const filter = { search: value };

      this.getList(filter);
    }
  }
};
</script>

<style lang="scss" scoped>
  .list-container {
    padding: 10px 30px 30px;
    height: 100%;
    background: #F7F9FF;
  }

  .el-row {
    margin-top: 20px;
    padding: 20px 25px 10px;
    background: #fff;
    border-radius: 6px;
  }

  .name {
    padding: 0;
    margin: 0;
    font-size: 16px;

    a {
      text-decoration: none;
    }
  }

  .btn-view-project {
    .el-button {
      float: right;
    }
  }

  .pagination {
    margin-top: 10px;
  }
</style>
