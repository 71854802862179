import CertificateService from '@/core/services/certificates.service';

export default {
  getCertificates({ commit, getters }: { commit, getters }) {
    if (getters.certificates && getters.certificates.length) {
      return getters.certificates;
    }

    return CertificateService.getCertificates()
      .then((response) => {
        commit('setCertificates', response);

        return response;
      });
  }
};
