import $axios from '../utils/axios-api-config';

export default {
  getCourses(params) {
    return $axios.get('/courses', { params });
  },

  getCourse(id: number): Promise<any> {
    return $axios.get(`/courses/${id}`);
  },

  createCourse(formData: any): Promise<any> {
    return $axios.post('/courses', formData);
  },

  updateCourse(id: number, formData): Promise<any> {
    return $axios.put(`/courses/${id}`, formData);
  },

  deleteCourse(id) {
    return $axios.delete(`/courses/${id}`);
  },

  getCourseSchedules(params) {
    return $axios.get('/course-schedules', { params });
  },

  getCourseSchedule(id: number): Promise<any> {
    return $axios.get(`/course-schedules/${id}`);
  },

  createCourseSchedule(formData: any): Promise<any> {
    return $axios.post('/course-schedules', formData);
  },

  updateCourseSchedule(id: number, formData): Promise<any> {
    return $axios.put(`/course-schedules/${id}`, formData);
  },

  deleteCourseSchedule(id) {
    return $axios.delete(`/course-schedules/${id}`);
  }
};
