
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { ProjectJobStatusEnum } from '@/core/packages/shared-library';
import { PROJECTS_CACHE, PROJECT_QUOTES_CACHE } from '@/store/modules/attachment-cache/constants';
import { ATTACHMENT_CACHE_STORE } from '@/store/modules/attachment-cache';
import StringHelper from '@/core/helpers/string.helper';
import DownloadAttachment from '@/core/components/common/attachments/DownloadAttachment.vue';
import { ImageFileTypes } from '@/core/helpers/file.helper';
import { PROJECTS_STORE } from '../../../store/modules/projects';

export default defineComponent({
  name: 'project-details',

  components: {
    DownloadAttachment
  },

  props: {
    project: Object,

    projectQuote: Object,

    confirmedQuote: {
      type: Object,
      default() {
        return null;
      },
    },

    /* Project Attachment Grid */
    projectAttachmentGridLg: {
      type: Number,
      default: 8,
    },
    projectAttachmentGridMd: {
      type: Number,
      default: 8,
    },
    projectAttachmentGridSm: {
      type: Number,
      default: 12,
    },
    projectAttachmentGridXs: {
      type: Number,
      default: 24,
    },
  },

  data() {
    return {
      selectedMaterialUrl: null,

      selectedAttachmentUrl: null,

      apiUrl: process.env.VUE_APP_API_URL,

      projectAttachments: [],

      projectQuoteAttachments: [],

      isAttachmentRequestComplete: true,

      projectImageAttachments: [] as any
    };
  },

  created() {
    this.loadAttachments();
  },

  computed: {
    ...mapGetters(PROJECTS_STORE, ['materialAvailabilityLabel']),

    previewImages(): string[] {
      return this.projectAttachments.map((attachment: any) => attachment.src);
    },

    projectConfimed(): boolean {
      return [
        ProjectJobStatusEnum.QUOTE_CONFIRMED,
        ProjectJobStatusEnum.IN_PROGRESS,
        ProjectJobStatusEnum.COMPLETED
      ].indexOf((this as any).project.projectJobStatusId) !== -1;
    },

    projectMaterial(): any {
      return (this as any).materialAvailabilityLabel.find((material: any) => {
        return material.value === (this as any).project.materials;
      });
    },

    materialUrls() {
      const props = this.$props as any;
      const { project } = props;
      const { materialUrls } = project;
      const isValidData = StringHelper.IsJsonString(materialUrls);
      const list: Array<string> = [];

      if (isValidData) {
        const currentList = JSON.parse(materialUrls);
        for (let i = 0; i < currentList.length; i++) {
          const item = currentList[i];

          if (item.url) {
            list.push(item);
          }
        }
      }

      return list;
    },

    projectAttachmentUrls() {
      const props = this.$props as any;
      const { project } = props;
      const { attachmentUrls } = project;
      const isValidData = StringHelper.IsJsonString(attachmentUrls);
      const list: Array<string> = [];

      if (isValidData) {
        const currentList = JSON.parse(attachmentUrls);
        for (let i = 0; i < currentList.length; i++) {
          const item = currentList[i];

          if (item.url) {
            list.push(item);
          }
        }
      }

      return list;
    },
  },

  methods: {
    ...mapActions(ATTACHMENT_CACHE_STORE, ['getAttachments', 'resetCacheEntity']),

    async loadAttachments() {
      await this.loadProjectAttachments();
      await this.loadQuoteAttachments();
    },

    async loadProjectAttachments(data = null) {
      const props = this.$props as any;
      const { project } = props;
      let { projectAttachments } = project;

      if (data) {
        projectAttachments = (data as any).projectAttachments;
      }
      this.isAttachmentRequestComplete = false;
      this.resetCacheEntity(PROJECTS_CACHE);
      await this.getAttachments({
        name: PROJECTS_CACHE,
        attachments: projectAttachments
      })
        .then((values) => {
          const newValues = values.filter(Boolean);

          if (newValues.length) {
            this.projectAttachments = newValues.map((item) => {
              const attachment = this.getProjectAttachmentId(item.attachment, data);
              return {
                ...item,
                id: attachment[0]?.id
              };
            });
            this.projectImageAttachments = this.projectAttachments.filter((item) => {
              return this.isImageAttachment((item as any).originalName);
            });
          }
          this.isAttachmentRequestComplete = true;
        })
        .catch(() => {})
        .finally(() => { this.isAttachmentRequestComplete = true; });
    },

    async loadQuoteAttachments() {
      const { projectQuote } = this as any;

      if (projectQuote) {
        const { projectQuoteAttachments } = projectQuote;

        if (projectQuoteAttachments?.length) {
          this.isAttachmentRequestComplete = false;
          await this.getAttachments({
            name: PROJECT_QUOTES_CACHE,
            attachments: projectQuoteAttachments,
            includeUrl: true
          })
            .then((values) => {
              const newValues = values.filter(Boolean);

              if (newValues.length) {
                this.projectQuoteAttachments = newValues;
              }
              this.isAttachmentRequestComplete = true;
            })
            .catch(() => {})
            .finally(() => { this.isAttachmentRequestComplete = true; });
        }
      }
    },

    getProjectAttachmentId(attachment, data) {
      const { project } = this as any;
      let { projectAttachments } = project;

      if (data) {
        projectAttachments = data.projectAttachments;
      }

      return projectAttachments.filter((item) => {
        return item.attachment === attachment;
      });
    },

    isImageAttachment(filename) {
      const extension = filename.split('.')[filename.split('.').length - 1];

      return ImageFileTypes.includes(extension.toLowerCase());
    }
  }
});
