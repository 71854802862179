
import { defineComponent } from 'vue';
import ContractorsAllRisk from './templates/ContractorsAllRisk.vue';
import EmployerLiability from './templates/EmployerLiability.vue';
import InsuranceBackedGuarantee from './templates/InsuranceBackedGuarantee.vue';
import Other from './templates/Other.vue';
import PlantEquipment from './templates/PlantEquipment.vue';
import ProductLiability from './templates/ProductLiability.vue';
import ProfessionalIndemnity from './templates/ProfessionalIndemnity.vue';
import PublicLiability from './templates/PublicLiability.vue';
import StructuralWarranty from './templates/StructuralWarranty.vue';

export default defineComponent({
  name: 'InsuranceTemplateRenderer',

  components: {
    ContractorsAllRisk,
    EmployerLiability,
    InsuranceBackedGuarantee,
    Other,
    PlantEquipment,
    ProductLiability,
    ProfessionalIndemnity,
    PublicLiability,
    StructuralWarranty
  },

  props: {
    insurance: {
      required: true,
      type: Object
    },
    form: {
      required: true,
      type: Object
    }
  }
});
