import $axios from '../utils/axios-api-config';

export default {
  /**
   * Get project quote statuses with count state
   * @param params
   * @returns
   */
  getProjectQuoteStatusWithCountStatus(params) {
    return $axios.get('/projects/statuses/quote-status', { params });
  },

  /**
   * Get project statuses with count state
   * @param params
   * @returns
   */
  getProjectStatusWithCountStatus(type: string, projectCreatorId) {
    const params = { projectCreatorId };

    return $axios.get(`/project-filters/${type}`, { params });
  },

  /**
   * Get the project categories
   * @returns
   */
  getProjectCategories() {
    return $axios.get('/categories/');
  },

  /**
   * Get the project list
   * @param params
   * @returns
   */
  getProjectPageList(params) {
    return $axios.get('/projects', { params });
  },

  /**
   * Get the projecy ById
   * @param projectId
   * @returns
   */
  getProjectById(projectId: number) {
    return $axios.get(`/projects/${projectId}/admin`);
  },

  /**
   * Get project statuses
   * @returns
   */
  getProjectStatus() {
    return $axios.get('/project-status');
  },

  /**
   * Create new project record
   * @param record
   * @returns
   */
  saveProject(record: unknown) {
    return $axios.post('/projects', record)
      .catch((error) => {
        const errorText = (error.response.data && error.response.data.message) || error.response.statusText;
        return Promise.reject(errorText);
      });
  },

  getCategories() {
    return $axios.get('/categories');
  },
};
