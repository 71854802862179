import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      model: _ctx.form,
      rules: _ctx.rules,
      ref: "courseForm"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          label: "Course",
          prop: "courseId"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              modelValue: _ctx.form.courseId,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.courseId) = $event)),
              placeholder: "Select Course"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.coursesOptions, (item) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: item.value,
                    label: item.label,
                    value: item.value
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "Start Date and time" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_date_picker, {
              modelValue: _ctx.form.startDate,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.startDate) = $event)),
              type: "datetime",
              placeholder: "",
              "value-format": 'YYYY-MM-DD HH:mm:ss'
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "Duration",
          prop: "duration"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input_number, {
              modelValue: _ctx.form.duration,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.duration) = $event)),
              min: 1,
              max: 1000
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "Cohort Size",
          prop: "spotLimit"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input_number, {
              modelValue: _ctx.form.spotLimit,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.spotLimit) = $event)),
              min: 1,
              max: 1000
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _ctx.onSubmit,
              disabled: _ctx.createBtnDisable,
              loading: _ctx.isSubmitting
            }, {
              default: _withCtx(() => [
                _createTextVNode("Create")
              ]),
              _: 1
            }, 8, ["onClick", "disabled", "loading"]),
            _createVNode(_component_el_button, {
              type: "info",
              onClick: _ctx.goToCoursesPage
            }, {
              default: _withCtx(() => [
                _createTextVNode("Cancel")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules"])
  ]))
}