
import { Location } from '@element-plus/icons';
import { defineComponent } from 'vue';

import ProjectQuoteLeftSidebarFilters from '@/core/components/project-quote/ProjectQuoteLeftSidebarFilters.vue';
import UserThumbnail from '@/core/components/ui/UserThumbnail.vue';

export default defineComponent({
  name: 'project-quote-left-sidebar-list',
  emits: ['select-project-quote', 'filter-project-quotes'],
  components: {
    ProjectQuoteLeftSidebarFilters,
    UserThumbnail,
    Location
  },
  props: {
    selectedProjectQuote: {
      type: Object,
      required: true,
      default: () => {
        return null;
      }
    },
    projectQuotes: {
      type: Object,
      required: true,
    },
  },
  methods: {
    filterProjectQuotes(event) {
      (this as any).$emit('filter-project-quotes', event);
    },
  }
});
