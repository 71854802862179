<template>
  <div class="image-container">
    <el-image
      :class="classes"
      :src="src"
      v-if="src"
      :fit="'cover'"
    ></el-image>
    <ImageTextDisplay v-if="loadingCondition" :label="loadingLabel"></ImageTextDisplay>
    <ImageTextDisplay v-if="emptyCondition" :label="emptyLabel"></ImageTextDisplay>
  </div>
</template>
<script>

import ImageTextDisplay from '@/core/components/ui/ImageTextDisplay.vue';

const DEFAULT_LOADING_LABEL = 'Fetching attachment(s)..';
const DEFAULT_EMPTY_LABEL = 'No attachment(s) found.';

export default {
  name: 'common-attachment-layout',

  components: {
    ImageTextDisplay
  },

  props: {
    classes: {
      type: String,
      default: ''
    },
    loadingLabel: {
      type: String,
      default: DEFAULT_LOADING_LABEL
    },
    emptyLabel: {
      type: String,
      default: DEFAULT_EMPTY_LABEL
    },
    src: {
      type: String,
    },
    loadingCondition: {
      type: Boolean
    },
    emptyCondition: {
      type: Boolean
    }
  }
};
</script>
<style lang="scss" scoped>
  .profile-image {
    width: 150px;
    height: 150px;
    border-radius:100px;
  }

</style>
