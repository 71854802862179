
import { defineComponent, PropType } from 'vue';
import { mapActions } from 'vuex';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import UserVerificationStatus from '@/core/components/account/UserVerificationStatus.vue';
import CardWrapper from '@/core/components/ui/CardWrapper.vue';
import { User } from '@/core/models/user';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  name: 'identification-page',

  components: {
    CardWrapper,
    UserVerificationStatus
  },

  props: {
    user: Object as PropType<User>
  },

  data() {
    return {
      UserVerificationStatusEnum,
      identification: {} as any,
      isRequestDone: false
    };
  },

  created() {
    const user = this.user as any;
    const { userIdentification } = user;
    const payload = {
      userId: user.id,
      identificationId: userIdentification?.id
    };

    this.getUserIdentification(payload)
      .then((response) => {
        this.identification = response;
        this.isRequestDone = true;
      }).catch(() => {
        this.isRequestDone = true;
      });
  },

  methods: {
    ...mapActions(USERS_STORE, [
      'getUserIdentification',
      'getAccountStatusesByUserId'
    ]),

    nextSection() {
      this.$router.push({ name: 'account/certification' });
    }
  }
});
