<template>
  <section class="message-list-filter padding-vertical-16 padding-horizontal-28">
    <el-input prefix-icon="el-icon-search" placeholder="Enter text to search..." v-model="searchMessageList" v-debounce:150="onSearch"></el-input>
  </section>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'message-list-filter',
  emits: ['filter'],
  data: () => {
    return {
      searchMessageList: '',
    };
  },
  methods: {
    onSearch() {
      this.$emit('filter', this.searchMessageList);
    }
  }
});
</script>
<style lang="scss" scoped>
.message-list-filter{
  position: relative;
  max-height:65px;
  height: 47px;
}
</style>
