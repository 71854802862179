import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44220595"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile-card" }
const _hoisted_2 = { class: "main-content" }
const _hoisted_3 = { class: "white-bg-container padding-24 padding-sm-30" }
const _hoisted_4 = { class: "skills padding-horizontal-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfileSummaryComponent = _resolveComponent("ProfileSummaryComponent")!
  const _component_ProfileBusinessDetailComponent = _resolveComponent("ProfileBusinessDetailComponent")!
  const _component_ProfileAboutComponent = _resolveComponent("ProfileAboutComponent")!
  const _component_ProfileCertificatesComponent = _resolveComponent("ProfileCertificatesComponent")!
  const _component_ProfileInsurancesComponent = _resolveComponent("ProfileInsurancesComponent")!
  const _component_ProfileGalleryComponent = _resolveComponent("ProfileGalleryComponent")!
  const _component_ProfileSkills = _resolveComponent("ProfileSkills")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_ProfileSummaryComponent, {
          class: "margin-bottom-64",
          profile: _ctx.profile,
          ratingSummary: _ctx.ratingSummary,
          onOnNavigate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-navigate')))
        }, null, 8, ["profile", "ratingSummary"]),
        _createVNode(_component_ProfileBusinessDetailComponent, {
          class: "margin-bottom-32",
          profile: _ctx.profile
        }, null, 8, ["profile"]),
        _createVNode(_component_ProfileAboutComponent, {
          class: "margin-bottom-32",
          profile: _ctx.profile
        }, null, 8, ["profile"]),
        _createVNode(_component_ProfileCertificatesComponent, {
          class: "margin-bottom-32",
          certificates: _ctx.profile.userCertificates
        }, null, 8, ["certificates"]),
        _createVNode(_component_ProfileInsurancesComponent, {
          class: "margin-bottom-32",
          insurances: _ctx.profile.userInsurances
        }, null, 8, ["insurances"]),
        _createVNode(_component_ProfileGalleryComponent, {
          class: "margin-bottom-32",
          profile: _ctx.profile,
          images: _ctx.profileGallery
        }, null, 8, ["profile", "images"])
      ])
    ]),
    _createElementVNode("aside", _hoisted_4, [
      _createVNode(_component_ProfileSkills, {
        skills: _ctx.getSkills,
        closableTag: false
      }, null, 8, ["skills"])
    ])
  ]))
}