import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "business-detail-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LimitedCompanyComponent = _resolveComponent("LimitedCompanyComponent")!
  const _component_SoleTraderComponent = _resolveComponent("SoleTraderComponent")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _withDirectives(_createVNode(_component_LimitedCompanyComponent, {
      ref: "limitedCompany",
      businessDetails: _ctx.businessInfo
    }, null, 8, ["businessDetails"]), [
      [_vShow, _ctx.businessInfo.type === 'limited_company']
    ]),
    _withDirectives(_createVNode(_component_SoleTraderComponent, {
      ref: "soletrader",
      businessDetails: _ctx.businessInfo
    }, null, 8, ["businessDetails"]), [
      [_vShow, ['soletrader', 'team'].includes(_ctx.businessInfo.type)]
    ])
  ]))
}