
import { defineComponent } from 'vue';
import { ProjectQuoteStatusEnum } from '@/core/packages/shared-library';

import UserThumbnail from '@/core/components/ui/UserThumbnail.vue';

export default defineComponent({
  name: 'po-project-job-status-accepted-card',
  props: {
    projectQuote: Object,
  },
  components: {
    UserThumbnail,
  },
  data: () => {
    return {
      ProjectQuoteStatusEnum,
    };
  },

  computed: {
    formEntity() {
      return (this as any).projectQuote;
    },
  },

  methods: {}
});
