
import { defineComponent } from 'vue';
import ImageAttachmentComponent from '@/modules/account-details/components/ImageAttachmentComponent.vue';

export default defineComponent({
  name: 'PublicLiability',

  props: ['insurance'],

  components: {
    ImageAttachmentComponent
  },

  computed: {
    currentInsurance() {
      return (this as any).insurance;
    }
  }
});
