import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60a3e900"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_skeleton_item = _resolveComponent("el-skeleton-item")!
  const _component_el_skeleton = _resolveComponent("el-skeleton")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_el_skeleton, { animated: "" }, {
      template: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_el_skeleton_item, {
            variant: "h1",
            style: {"max-width":"200px","width":"100%"}
          }),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_el_skeleton_item, {
              variant: "button",
              style: {"max-width":"140px","width":"100%"}
            }),
            _createVNode(_component_el_skeleton_item, {
              variant: "button",
              style: {"max-width":"140px","width":"100%"}
            })
          ])
        ]),
        _createVNode(_component_el_skeleton_item, {
          variant: "image",
          style: {"height":"280px"}
        }),
        _createVNode(_component_el_skeleton_item, {
          variant: "h3",
          class: "title"
        }),
        _createVNode(_component_el_skeleton_item, {
          variant: "rect",
          class: "descritpion"
        }),
        _createVNode(_component_el_skeleton_item, {
          variant: "rect",
          class: "descritpion"
        }),
        _createVNode(_component_el_skeleton_item, {
          variant: "rect",
          class: "descritpion",
          style: {"max-width":"500px","width":"100%"}
        })
      ]),
      _: 1
    })
  ]))
}