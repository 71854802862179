/**
 * Login route page
 */
export default {
  path: '/login',
  name: 'login',
  component: () => import('@/modules/login/pages/LoginPage.vue'),
  meta: {
    validateAuth: true,
  }
};
