import { RolesEnum } from '@/core/packages/shared-library';

/**
 * Projects route page
 */
export const PROJECTS_ROUTE = 'projects';

export default {
  path: '/projects',
  name: PROJECTS_ROUTE,
  component: () => import('@/modules/projects/pages/ProjectPage.vue'),
  meta: {
    title: 'Projects',
    authorize: [RolesEnum.ADMIN],
  }
};
