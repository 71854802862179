import UserService from '@/core/services/users.service';
import { User } from '@/core/models/user';

export default {
  /**
   * Get the user profile by token itself
   * @returns {User} model
   */
  getUser(context, userId: number): Promise<User | null> {
    return UserService.getUser(userId)
      .then((user) => {
        if (!user) return null;

        return user;
      });
  },

  updateUser(context, { userId, user }: { userId: number, user }) {
    return UserService.updateUser(userId, user);
  },

  resetPassword(context, email: string) {
    return UserService.resetPassword(email);
  },

  /**
   * Set local storage for user
   * @param context
   * @param user
   */
  saveUserState({ commit }: { commit }, user: User): User | null {
    if (user) {
      commit('setUser', user);

      return user;
    }

    return null;
  },

  /**
   * Initialize user and set the
   * @param param
   * @returns
   */
  // initializeUserState({ commit } : { commit }) {
  //   return UserService.getUser()
  //     .then((user) => {
  //       if (!user) return null;

  //       commit('setUser', user);

  //       return user;
  //     });
  // },
  initializeUserState({ commit, rootGetters } : { commit, rootGetters }) {
    const authenticatedUser = rootGetters['authentication/authenticatedUser'];
    return UserService.getUser(authenticatedUser.userId)
      .then((user) => {
        if (!user) return null;

        commit('setUser', user);

        return user;
      });
  },

  getUserProjectAccountBalance(context, userId: number) {
    return UserService.getUserProjectAccountBalance(userId);
  },

  getUserTransactionLogs(context, userId: number) {
    return UserService.getUserTransactionLogs(userId);
  },

  /**
   * Get account statuses
   * @param context
   * @returns
   */
  getAccountStatuses(context) {
    return UserService.getAccountStatuses()
      .then((statuses) => {
        context.commit('setAccountStatuses', statuses);

        return statuses;
      });
  },

  /**
   * Get account statuses by user
   * @param context
   * @returns
   */
  getAccountStatusesByUserId(context, userId: number) {
    return UserService.getAccountStatusesByUserId(userId)
      .then((statuses) => {
        context.commit('setAccountStatuses', statuses);

        return statuses;
      });
  },

  /**
   * Activate User account
   * @param context
   * @param userId
   * @returns
   */
  activateUserAccount(context, userId: number) {
    return UserService.activateUserAccount(userId);
  },

  rejectUserAccount(context, userId: number) {
    return UserService.rejectUserAccount(userId);
  },

  /**
   * Save user profile
   * @param context
   * @param payload
   * @returns
   */
  saveUserProfile(context, payload) {
    const { userId, profile } = payload;

    delete profile.dateCreated;
    delete profile.dateUpdated;

    return UserService.saveUserProfile(userId, profile);
  },

  /**
   * Get user profile
   * @param context
   * @param userId
   * @returns
   */
  getUserProfile(context, { userId, profileId }) {
    return UserService.getUserProfile(userId, profileId);
  },

  /**
   * Get user business details
   * @param context
   * @param userId
   * @returns
   */
  getBusinessDetails(context, { userId, businessId }) {
    return UserService.getBusinessDetails(userId, businessId);
  },

  /**
   *
   * @param context
   * @param payload
   * @returns
   */
  saveBusinessDetails(context, payload) {
    const { userId, businessDetail } = payload;

    delete businessDetail.dateCreated;
    delete businessDetail.dateUpdated;

    return UserService.saveBusinessDetails(userId, businessDetail);
  },

  saveUserIdentification(context, payload) {
    const { userId, identification } = payload;

    return UserService.saveUserIdentification(userId, identification);
  },

  getUserIdentification(context, payload) {
    const { userId, identificationId } = payload;
    return UserService.getUserIdentification(userId, identificationId);
  },

  getUserCertificates({ commit }: { commit}, userId: number) {
    return UserService.getUserCertificates(userId)
      .then((certificates) => {
        commit('setCertificates', certificates);

        return certificates;
      });
  },

  addNewCertificate({ commit }: { commit }, certificate) {
    return UserService.createCertificate(certificate)
      .then((response) => {
        commit('setNewUserCertificate', response);
        return response;
      });
  },

  updateUserCertificate(context, payload) {
    const { userId, certificateId, certificate } = payload;

    return UserService.updateCertificate(userId, certificateId, certificate);
  },

  removeUserCertificates(context, payload) {
    const { userId, certificateId } = payload;

    return UserService.deleteCertificate(userId, certificateId)
      .then((response) => {
        const certificate = response.data;

        context.commit('removeUserCertificates', certificateId);

        return certificate;
      });
  },

  getUserInsurances({ commit }: { commit}, userId: number) {
    return UserService.getUserInsurances(userId)
      .then((insurances) => {
        commit('setInsurances', insurances);

        return insurances;
      });
  },

  addNewInsurance({ commit }: {commit}, insurance = {}) {
    return UserService.createUserInsurance(insurance)
      .then((response) => {
        commit('setNewUserInsurance', response);
      });
  },

  updateUserInsurance(context, payload) {
    const { userId, insuranceId, insurance } = payload;
    return UserService.updateUserInsurance(userId, insuranceId, insurance);
  },

  removeUserInsurance({ commit }: { commit }, payload) {
    const { userId, insuranceId } = payload;
    return UserService.deleteUserInsurance(userId, insuranceId)
      .then((response) => {
        commit('removeUserInsurance', response);

        return response;
      });
  },

  saveUserSkills(context, payload) {
    const { userId, userSkill } = payload;

    return UserService.saveUserSkills(userId, userSkill);
  },

  getUserSkills(context, userId: number) {
    return UserService.getUserSkills(userId);
  },

  getUserSkillVerificationStatus(context, userId: number) {
    return UserService.getUserSkillVerificationStatus(userId);
  },

  createUserRating(context, payload) {
    const { toUserId, rating } = payload;

    return UserService.createUserRating(toUserId, rating);
  },

  getUserRatingsPageList(context, payload) {
    const { userId, filter } = payload;

    return UserService.getUserRatingsPageList(userId, filter);
  },

  getUserRatingSummary(context, userId: number) {
    return UserService.getUserRatingSummary(userId);
  },

  async getUserCustomerAccount(context, userId: number) {
    const userAccount = await UserService.getUserCustomerAccount(userId);

    return userAccount[0];
  },

  async getUsers({ commit }, filter) {
    return UserService.getUsers(filter).then((response) => {
      commit('setUsers', response);
      return Promise.resolve(response);
    });
  },

  async getUserWorkstations({ commit }, params) {
    const { userId, filter } = params;

    return UserService.getUserWorkstations(userId, filter).then((response) => {
      commit('setUserWorkstations', response);
      return Promise.resolve(response);
    });
  },

  async getUserWorkstation({ dispatch }, params) {
    const { userId, userWorkstationId } = params;

    return UserService.getUserWorkstation(userId, userWorkstationId).then((response) => {
      dispatch('setSelectedUserWorkstation', response);
      return Promise.resolve(response);
    });
  },

  async getUserById({ commit }, id) {
    return UserService.getUserById(id).then((response) => {
      commit('setSelectedUser', response);
      return Promise.resolve(response);
    });
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  verifyUserAccount({ commit }, userId) {
    return UserService.verifyUserAccount(userId).then((response) => {
      return Promise.resolve(response);
    });
  },

  setSelectedUserWorkstation({ commit }, value) {
    commit('setSelectedUserWorkstation', value);
  },

  updateUserWorkstationStatus({ commit }, params) {
    const { userId, userWorkstationId, formData } = params;

    return UserService.updateUserWorkstationStatus(userId, userWorkstationId, formData)
      .then((response) => {
        commit('setSelectedUserWorkstation', response);
      });
  },
};
