export default {
  GasSafeRegister: {
    postcode: {
      label: 'Postcode',
      type: 'input'
    },
    registrationNumber: {
      label: 'Registration Number',
      type: 'input'
    },
    proof: {
      label: 'Proof Of Certificate',
      type: 'file'
    },
  },

  NICEIC: {
    businessName: {
      label: 'Business Name',
      type: 'input'
    },
    postcode: {
      label: 'Postcode',
      type: 'input'
    },
    url: {
      label: 'URL',
      type: 'input'
    },
    proof: {
      label: 'Proof of Certificate',
      type: 'file'
    },
  },

  TrustMark: {
    businessName: {
      label: 'Business Name',
      type: 'input'
    },
    postcode: {
      label: 'Postcode',
      type: 'input'
    },
    tradeCategory: {
      label: 'Trade Category (Type)',
      type: 'input'
    },
    url: {
      label: 'URL',
      type: 'input'
    },
    proof: {
      label: 'Proof of Certificate',
      type: 'file'
    },
  },

  FederationMasterBuilders: {
    businessName: {
      label: 'Business Name',
      type: 'input'
    },
    postcode: {
      label: 'Postcode',
      type: 'input'
    },
    tradeCategory: {
      label: 'Trade Category (Type)',
      type: 'input'
    },
    url: {
      label: 'URL',
      type: 'input'
    },
    proof: {
      label: 'Proof of Certificate',
      type: 'file'
    }
  },

  ECA: {
    businessName: {
      label: 'Business Name',
      type: 'input'
    },
    postcode: {
      label: 'Postcode',
      type: 'input'
    },
    areasOfWorkCovered: {
      label: 'Areas of work covered',
      type: 'input'
    },
    url: {
      label: 'URL',
      type: 'input',
    },
    proof: {
      label: 'Proof of Certificate',
      type: 'file'
    },
  },

  NAPIT: {
    businessName: {
      label: 'Business Name',
      type: 'input'
    },
    postcode: {
      label: 'Postcode',
      type: 'input'
    },
    tradeCategory: {
      label: 'Trade Category (Type)',
      type: 'input'
    },
    url: {
      label: 'URL',
      type: 'input'
    },
    proof: {
      label: 'Proof of Certificate',
      type: 'file'
    }
  },

  FENSA: {
    businessName: {
      label: 'Business Name',
      type: 'input'
    },
    postcode: {
      label: 'Postcode',
      type: 'input'
    },
    registrationNumber: {
      label: 'Registration Number',
      type: 'input'
    },
    proof: {
      label: 'Proof of Certificate',
      type: 'file'
    }
  },

  NFRC: {
    businessName: {
      label: 'Business Name',
      type: 'input'
    },
    postcode: {
      label: 'Postcode',
      type: 'input'
    },
    url: {
      label: 'URL',
      type: 'input'
    },
    proof: {
      label: 'Proof of Certificate',
      type: 'file'
    },
  },

  Other: {
    accreditationBoardName: {
      label: 'Accreditation Board Name',
      type: 'input'
    },
    tradeCategory: {
      label: 'Trade Category (Please specify)',
      type: 'input'
    },
    registrationNumber: {
      label: 'Registration Number',
      type: 'input'
    },
    additionalInformation: {
      label: 'Additional Information',
      type: 'input'
    },
    proof: {
      label: 'Proof of Certificate',
      type: 'file'
    },
  },

  NotRequired: {
    commentbox: {
      label: 'Comment Box',
      type: 'input'
    }
  }
};
