import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26c9fde0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "project-quote-overview-page page-viewport" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProjectQuoteOverviewSidebarSkeleton = _resolveComponent("ProjectQuoteOverviewSidebarSkeleton")!
  const _component_ProjectQuoteLeftSidebarList = _resolveComponent("ProjectQuoteLeftSidebarList")!
  const _component_ProjectQuoteOverviewDetailSkeleton = _resolveComponent("ProjectQuoteOverviewDetailSkeleton")!
  const _component_ProjectQuoteEmpty = _resolveComponent("ProjectQuoteEmpty")!
  const _component_ProjectQuoteOverviewDetail = _resolveComponent("ProjectQuoteOverviewDetail")!
  const _component_ProjectQuoteOverviewSummarySkeleton = _resolveComponent("ProjectQuoteOverviewSummarySkeleton")!
  const _component_ProjectQuoteOverviewSummary = _resolveComponent("ProjectQuoteOverviewSummary")!
  const _component_ProjectQuoteContainerSlot = _resolveComponent("ProjectQuoteContainerSlot")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_ProjectQuoteContainerSlot, null, {
      "project-quote-sidebar-list": _withCtx(() => [
        (!_ctx.projectQuoteListIsLoaded)
          ? (_openBlock(), _createBlock(_component_ProjectQuoteOverviewSidebarSkeleton, { key: 0 }))
          : (_ctx.selectedProjectQuote && _ctx.projectQuoteListIsLoaded && _ctx.getFilteredProjectQuotes.length)
            ? (_openBlock(), _createBlock(_component_ProjectQuoteLeftSidebarList, {
                key: 1,
                projectQuotes: _ctx.getFilteredProjectQuotes,
                selectedProjectQuote: _ctx.selectedProjectQuote,
                onSelectProjectQuote: _ctx.selectProjectQuote,
                onFilterProjectQuotes: _ctx.filterProjectQuotes
              }, null, 8, ["projectQuotes", "selectedProjectQuote", "onSelectProjectQuote", "onFilterProjectQuotes"]))
            : _createCommentVNode("", true)
      ]),
      "project-quote-summary": _withCtx(() => [
        (!_ctx.projectQuoteDetailsLoaded)
          ? (_openBlock(), _createBlock(_component_ProjectQuoteOverviewSummarySkeleton, { key: 0 }))
          : (_ctx.selectedProjectQuote && _ctx.selectedProjectQuote !== null && _ctx.projectQuoteDetailsLoaded)
            ? (_openBlock(), _createBlock(_component_ProjectQuoteOverviewSummary, {
                key: 1,
                projectQuote: _ctx.selectedProjectQuote
              }, null, 8, ["projectQuote"]))
            : _createCommentVNode("", true)
      ]),
      default: _withCtx(() => [
        (!_ctx.projectQuoteDetailsLoaded)
          ? (_openBlock(), _createBlock(_component_ProjectQuoteOverviewDetailSkeleton, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.projectQuoteDetailsLoaded && _ctx.getFilteredProjectQuotes.length === 0)
          ? (_openBlock(), _createBlock(_component_ProjectQuoteEmpty, { key: 1 }))
          : (_ctx.selectedProject && _ctx.selectedProjectQuote && _ctx.selectedProjectQuote !== null && _ctx.projectQuoteDetailsLoaded)
            ? (_openBlock(), _createBlock(_component_ProjectQuoteOverviewDetail, {
                key: 2,
                projectQuote: _ctx.selectedProjectQuote,
                projectStartDate: _ctx.selectedProject?.startDate,
                projectEndDate: _ctx.selectedProject?.endDate
              }, null, 8, ["projectQuote", "projectStartDate", "projectEndDate"]))
            : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}