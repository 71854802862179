
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { User, Project } from '@/core/packages/shared-library';

import MessageDashboardContainerSlot from '@/core/components/messages/MessageDashboardContainerSlot.vue';
import MessageListMenu from '@/core/components/messages/MessageListMenu.vue';
import MessageList from '@/core/components/messages/MessagesList.vue';
import MessageSidebarSkeleton from '@/core/components/messages/placeholder/MessageSidebarSkeleton.vue';
import ProjectDetail from '@/core/components/project/ProjectDetail.vue';
import { MESSAGES_STORE } from '@/store/modules/messages';

import { USERS_STORE } from '@/store/modules/users';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
// eslint-disable-next-line import/no-cycle
import { PROJECTS_STORE } from '@/store/modules/projects';
// import { PROJECT_POST_ROUTE } from '../../project-post/routes';

export default defineComponent({
  name: 'project-messages-page',
  components: {
    MessageDashboardContainerSlot,
    MessageSidebarSkeleton,
    MessageListMenu,
    MessageList,
    ProjectDetail,
  },
  data: () => {
    interface UserMessageRoom {
      id: number,
      project: null | Project,
      fromUser: null | User,
      toUser: null | User,
    }
    return {
      messageDashboardIsLoaded: false as boolean,
      messageDashboardSidebarIsLoaded: false as boolean,
      userMessageRooms: [] as any[],
      selectedUserMessageRoom: null as null | UserMessageRoom,
      selectedProject: null as null | Project,
      chatRooms: [],
      allDataLoaded: false,
      subscribedRoomChannels: [] as any[],
      defaultUserMessageRoom: {
        fromUser: null,
        toUser: null,
      } as UserMessageRoom,
      filteredUserMessageRooms: [] as any,
      originalFilteredUserMessageRooms: [] as any,
      isSearchTriggered: false
    };
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapGetters(USERS_STORE, ['user']),

    ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser'])
  },
  methods: {
    ...mapActions(MESSAGES_STORE, ['getUserMessageRooms', 'createUserMessage',
      'createUserMessageRoom', 'getUserMessageRoomByProjectId', 'setTotalNewMessages',
      'setUnreadUserMessagesByUserMessageRoom', 'addMemberToChatRoom', 'removeMemberFromChatRoom'
    ]),

    ...mapActions(PROJECTS_STORE, ['getProjectById', 'setSelectedProjectJobStatus']),
    ...mapActions(USERS_STORE, ['getUserById']),

    async initialize() {
      const { projectId, userId } = (this as any).$route.params;

      (this as any).selectedProject = await (this as any).getProjectById(projectId);
      const user = await (this as any).getUserById(userId);

      // fetch sidebar data list
      if (user && (this as any).selectedProject) {
        // const { selectedProject } = this;
        const getUserMessageRoomsRequest = await (this as any).getUserMessageRoomByProjectId({
          userId,
          projectId
        });

        // check if userMessageRooms has values [].length
        if (getUserMessageRoomsRequest?.length) {
          (this as any).userMessageRooms = getUserMessageRoomsRequest.map((userMessageRoom: any) => {
            const extend = {
              ...userMessageRoom,
              messagesIsLoaded: true,
              members: [],
              totalNewMessages: 0,
            };

            return extend;
          });
          this.filterUserMessageRooms();
          const [firstUserMessageRoom] = this.filteredUserMessageRooms;

          (this as any).selectedUserMessageRoom = firstUserMessageRoom;

          (this as any).messageDashboardSidebarIsLoaded = true;
        }
        if (!this.filteredUserMessageRooms.length) {
          (this as any).selectedUserMessageRoom = null;
        }
      } else {
        // redirect to unauthorize
        (this as any).$router.push('/unauthorize');
      }
    },

    async selectUserMessageRoom(userMessageRoom: any) {
      if (userMessageRoom) {
        this.selectedUserMessageRoom = userMessageRoom;
      }
    },

    filterUserMessageRooms() {
      const { userMessageRooms } = this;
      const projectQuoteCreatorIds = this.getAllProjectQuoteCreatorIds();
      const filteredRooms = [] as any;

      if (userMessageRooms && userMessageRooms.length) {
        userMessageRooms.forEach((userMessageRoom) => {
          const { fromUser, userMessages } = userMessageRoom as any;

          if (!userMessages.length) {
            if (projectQuoteCreatorIds.includes(fromUser.id)) {
              filteredRooms.push(userMessageRoom);
            }
          } else {
            filteredRooms.push(userMessageRoom);
          }
        });
      }

      this.filteredUserMessageRooms = filteredRooms;
      this.originalFilteredUserMessageRooms = filteredRooms;
    },

    getAllProjectQuoteCreatorIds() {
      const { selectedProject } = this;
      const { projectQuotes } = selectedProject as any;

      let ids = [] as any;

      if (projectQuotes && projectQuotes.length) {
        ids = projectQuotes.map((projectQuote) => {
          return projectQuote.projectQuoteCreator.id;
        });
      }

      return ids;
    },

    getAllTpMessageRoomUserId() {
      const { userMessageRooms } = this as any;
      let ids = [];

      if (userMessageRooms && userMessageRooms.length) {
        ids = userMessageRooms.map((userMessageRoom) => {
          return userMessageRoom.fromUser?.id;
        });
      }

      return ids;
    },

    onChangeFilterUserMessageRooms(keyword) {
      this.filteredUserMessageRooms = this.originalFilteredUserMessageRooms;

      const { filteredUserMessageRooms: list } = this as any;
      const searchedKeyword = keyword.toLowerCase();
      const newList = [] as any;

      if ((list && list.length) && searchedKeyword) {
        list.forEach((item) => {
          const { fromUser, project } = item;
          const { firstName, lastName } = fromUser;
          const { refId, name } = project;

          if (firstName.toLowerCase().indexOf(searchedKeyword) > -1
            || lastName.toLowerCase().indexOf(searchedKeyword) > -1
            || refId.toLowerCase().indexOf(searchedKeyword) > -1
            || name.toLowerCase().indexOf(searchedKeyword) > -1) {
            newList.push(item);
          }
        });
      }

      if (newList.length || searchedKeyword) {
        this.filteredUserMessageRooms = newList;
      }

      if (this.filteredUserMessageRooms.length) {
        const [firstUserMessageRoom] = this.filteredUserMessageRooms;
        this.selectedUserMessageRoom = firstUserMessageRoom;
      }

      this.isSearchTriggered = !(this.filteredUserMessageRooms.length > 0);
    }
  },
});
