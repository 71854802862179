

import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import {
  ProjectJobStatusEnum, ProjectQuote
} from '@/core/packages/shared-library';

import ProjectDetailSkeleton from '@/core/components/project/placeholder/ProjectDetailSkeleton.vue';
import PoProjectJobStatusAcceptedCard from '@/core/components/project/project-card-statuses/PoProjectJobStatusAcceptedCard.vue';
import PoRecentProjectQuotesCard from '@/core/components/project/project-card-statuses/PoRecentProjectQuotesCard.vue';
import ProjectJobPlanCard from '@/core/components/project/project-card-statuses/ProjectJobPlanCard.vue';
import ProjectJobStatusConfirmedMoreInfoCard from '@/core/components/project/project-card-statuses/ProjectJobStatusConfirmedMoreInfoCard.vue';
import ProjectStatusCard from '@/core/components/project/project-card-statuses/ProjectStatusCard.vue';
import ProjectDetail from '@/core/components/project/ProjectDetail.vue';
import { USERS_STORE } from '@/store/modules/users';
import { PROJECT_QUOTES_STORE } from '@/store/modules/project-quotes';
// eslint-disable-next-line import/no-cycle
import { PROJECTS_STORE } from '@/store/modules/projects';
import { PROJECT_QUOTE_OVERVIEW_ROUTE } from '@/modules/project-quote-overview/routes';
import ProjectDetailSummarySkeleton from '../../projects/components/ProjectDetailSummarySkeleton.vue';

export default defineComponent({
  name: 'project-overview-page',

  components: {
    ProjectDetail,
    ProjectStatusCard,
    ProjectDetailSkeleton,
    ProjectDetailSummarySkeleton,
    PoRecentProjectQuotesCard,
    PoProjectJobStatusAcceptedCard,
    ProjectJobStatusConfirmedMoreInfoCard,
    ProjectJobPlanCard,
  },

  data() {
    return {
      ProjectJobStatusEnum,
      projectQuotes: [],
      isLoadingProjectInfo: null as boolean | null
    };
  },

  computed: {
    ...mapGetters(PROJECTS_STORE, [
      'getProjectStatusLabel',
      'selectedProject'
    ]),

    ...mapGetters(PROJECT_QUOTES_STORE, ['getAverageQuoteValue', 'getQuoteStatusLabel']),

    getProjectQuoteCount() {
      const { selectedProject } = this as any;
      const { projectQuoteCount } = selectedProject;

      return projectQuoteCount;
    },

    getProjectStatus() : any {
      return this.getProjectStatusLabel((this as any).selectedProject);
    },

    getProjectJobStatus() : any {
      const { selectedProject } = this as any;
      const { projectJobStatus } = selectedProject;

      return projectJobStatus;
    },

    projectQuote() {
      if ((this as any).selectedProject?.projectQuotes?.length) {
        return (this as any).selectedProject?.projectQuotes[0];
      }
      return null;
    },

    confirmedProjectQuote(): null | ProjectQuote {
      const quotes = (this as any).selectedProject?.projectQuotes as any;
      const confirmedQuote = quotes.filter((quote) => quote.agreeTermsConsumerDisclosure === true);
      if (confirmedQuote.length) {
        return confirmedQuote[0];
      }

      return null;
    },

    averageQuoteValue(): any {
      const { selectedProject } = this;
      const { projectQuotes } = selectedProject;
      let value = 0;

      if (projectQuotes && projectQuotes.length) {
        value = this.getAverageQuoteValue(projectQuotes);
      }

      return value;
    },

    canShowPoRecentProjectQuotes(): boolean {
      let result = false;
      if ((this as any).selectedProject?.projectQuoteCount
        && (this as any).selectedProject?.projectJobStatus?.id === ProjectJobStatusEnum.QUOTES_RECEIVED) {
        result = true;
      }

      return result;
    }
  },

  async created() {
    const { projectId, userId } = (this.$route as any).params;

    await this.getProjectById(projectId);
    await this.getUserById(userId);

    if (this.selectedProject) {
      if (this.selectedProject?.projectQuotes?.length) {
        (this.selectedProject.projectQuotes[0] as any).quotePrice = await (this as any).getProjectQuotePrice((this as any).selectedProject.projectQuotes[0]);
      }
    }

    this.isLoadingProjectInfo = false;
  },

  methods: {
    ...mapActions(['checkIfUserHasMatchedProjectSkills']),

    ...mapActions(USERS_STORE, ['getUserById']),

    ...mapActions(PROJECTS_STORE, [
      'getProjectById',
      'updateProject',
      'setSelectedProjectJobStatus'
    ]),

    ...mapActions(PROJECT_QUOTES_STORE, ['updateProjectQuoteStatus', 'getCustomerPay']),

    navigateToUrl(url) {
      window.open(url);
    },
    navigateToMessages() {
      this.$router.push(`/projects/${this.$route.params.projectId}/messages`);
    },

    navigateToProjectQuoteOverview() {
      const { projectId } = this.$route.params;

      this.$router.push({ name: PROJECT_QUOTE_OVERVIEW_ROUTE, params: { projectId } });
    },

    async getProjectQuotePrice(projectQuote): Promise<any> {
      let result = { total: 0, serviceFee: 0 } as number | { total: number, serviceFee: number };

      const { projectId } = (this as any).$route.params;
      result = await this.getCustomerPay({ projectId, quote: projectQuote });
      return result;
    },
  }
});
