import DateFilter from './date.filter';
import ExpandCollapseText from './expand-collapse-text.filter';
import CurrencyFilter from './currency.filter';
import formatToImageSourceUrl from './image-source.filter';
import formatToNameInitials from './name-initials.filter';

export default {
  ...DateFilter,
  ...CurrencyFilter,
  ...formatToImageSourceUrl,
  ...ExpandCollapseText,
  ...formatToNameInitials
};
