import { AUTH_TOKEN, AUTH_USER } from '@/core/constants';

export default {
  /**
   * Checks if user is authenticated.
   * NOTE: AUTH_TOKEN will eventually set to null if expired. Handled in core/utils/axios-api-config.ts
   * @returns
   */
  isAuthenticated(): boolean {
    return !!window.localStorage.getItem(AUTH_TOKEN);
  },

  authenticatedUser() {
    const authUser = window.localStorage.getItem(AUTH_USER);

    if (authUser) {
      return JSON.parse(authUser);
    }

    return null;
  },
};
