// Reference for the local storage auth token
export const AUTH_TOKEN = 'admin_token';

// Reference for the local storage authenticated user info
export const AUTH_USER = 'admin_user';

// Crypto secret key
export const CRYPTO_SECRET_KEY = process.env.VUE_APP_CRYPTO_SECRET_KEY ?? '';

// Defautl current symbol
export const DEFAULT_CURRENCY = '£';

// app standard date format
export const DATE_FORMAT = 'MM-DD-YYYY';

export const PAGE_LIMIT = 20;

export const IMAGE_EXPIRATION_KEY = 'image_expiration_token';

export const IMAGE_EXPIRATION_TOKEN = 'image_expiration_storage';

export const IMAGE_EXPIRATION_DURATION = 70; // in minutes

// Area distance max in miles
export const AREA_DISTANCE_MAX_RADIUS = 50;

// Area distance min in miles
export const AREA_DISTANCE_MIN_RADIUS = 1;

// Area distance default in miles
export const AREA_DISTANCE_DEFAULT_RADIUS = 5;
