import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6dc5e9b3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "margin-top-20" }
const _hoisted_2 = { class: "person-name margin-bottom-0" }
const _hoisted_3 = { class: "person-location margin-top-0" }
const _hoisted_4 = { class: "comments" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_rate = _resolveComponent("el-rate")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_el_row, { gutter: 30 }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          md: 10,
          xs: 24
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.currentRatingRecord.fromUser.firstName) + " " + _toDisplayString(_ctx.currentRatingRecord.fromUser.lastName), 1),
            _createElementVNode("p", _hoisted_3, " Reviewed in " + _toDisplayString(_ctx.currentRatingRecord.fromUser.address) + " on " + _toDisplayString(_ctx.dateReview), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, {
          md: 14,
          xs: 24
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_rate, {
              max: 6,
              "show-text": true,
              "show-score": true,
              modelValue: _ctx.currentRatingRecord.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentRatingRecord.value) = $event)),
              colors: ['#264FD5', '#264FD5', '#264FD5'],
              disabled: ""
            }, null, 8, ["modelValue"]),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.currentRatingRecord.comments), 1),
            (_ctx.attachments?.length)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.attachments, (attachment, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "attachment margin-12",
                    key: index
                  }, [
                    _createVNode(_component_el_image, {
                      style: {"width":"97px","height":"67px"},
                      src: attachment,
                      fit: "cover",
                      "preview-src-list": _ctx.attachments
                    }, null, 8, ["src", "preview-src-list"])
                  ]))
                }), 128))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}