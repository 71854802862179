import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f94cd93"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "read-more-wrap" }
const _hoisted_3 = { class: "read-more-target" }
const _hoisted_4 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", null, [
    _createElementVNode("input", {
      type: "checkbox",
      class: "read-more-state",
      id: _ctx.id
    }, null, 8, _hoisted_1),
    _createElementVNode("span", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.$filters.initText(_ctx.text, _ctx.initStrLength)), 1),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$filters.remainingText(_ctx.text, _ctx.initStrLength)), 1)
    ]),
    _createTextVNode("   "),
    _createElementVNode("label", {
      for: _ctx.id,
      class: "read-more-trigger"
    }, null, 8, _hoisted_4)
  ]))
}