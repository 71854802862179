
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

import { PROJECTS_STORE } from '../../../../store/modules/projects';

export default defineComponent({
  name: 'project-jobstatus-filter',
  emits: ['change'],
  props: {
    projectJobStatusEnum: {
      type: Array,
      required: false,
    },
    filterType: String,
  },

  data() {
    return {
      projectJobStatusCountState: [],
      selectedJobStatusIds: [],
    };
  },

  computed: {
    filteredProjectJobStatus(): unknown[] {
      if (!this.projectJobStatusEnum?.length) {
        return this.projectJobStatusCountState;
      }

      if ((this.projectJobStatusCountState && this.projectJobStatusCountState.length)) {
        return this.projectJobStatusCountState.filter((projectQuoteItem: { id: number }) => {
          return this.projectJobStatusEnum?.includes(projectQuoteItem.id);
        });
      }

      return this.projectJobStatusCountState;
    }
  },

  methods: {
    ...mapActions(PROJECTS_STORE, ['getProjectJobStatusWithCountState']),

    onSelectStatus(checked: boolean, status: never) {
      const foundIndex = this.selectedJobStatusIds.indexOf(status);

      if (checked) {
        if (foundIndex === -1) {
          this.selectedJobStatusIds.push(status);
        }
      } else if (foundIndex > -1) {
        this.selectedJobStatusIds.splice(foundIndex, 1);
      }

      this.$emit('change', {
        projectJobStatusIds: this.selectedJobStatusIds
      });
    }
  },

  async created() {
    const { params } = this.$route;
    const { id: userId } = params;

    const payload = {
      type: (this as any).filterType,
    } as any;

    if (userId) {
      payload.projectCreatorId = userId;
    }
    const projectJobStatusCountState = await this.getProjectJobStatusWithCountState(payload);

    if (projectJobStatusCountState) {
      this.projectJobStatusCountState = projectJobStatusCountState;
    }
  }
});
