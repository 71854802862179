import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b9356d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_Loader = _resolveComponent("Loader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.initializing && _ctx.isNavCollapse != null)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.user)
            ? (_openBlock(), _createBlock(_component_Sidebar, {
                key: 0,
                user: _ctx.user
              }, null, 8, ["user"]))
            : _createCommentVNode("", true),
          _createElementVNode("section", {
            class: _normalizeClass(["main-content", { 'main-content-open': !_ctx.isNavCollapse }])
          }, [
            _createVNode(_component_Header, {
              showBackButton: _ctx.showBackButton,
              title: _ctx.currentRoute
            }, null, 8, ["showBackButton", "title"]),
            _createElementVNode("main", _hoisted_1, [
              _createVNode(_component_el_scrollbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_router_view, null, {
                    default: _withCtx(({ Component }) => [
                      _createVNode(_Transition, {
                        name: "fade",
                        mode: "out-in"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ], 2)
        ], 64))
      : _createCommentVNode("", true),
    _createVNode(_component_Loader, { show: _ctx.initializing }, null, 8, ["show"])
  ], 64))
}