
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { USER_INSURANCES_CACHE } from '@/store/modules/attachment-cache/constants';
import { ATTACHMENT_CACHE_STORE } from '@/store/modules/attachment-cache';

declare interface BaseComponentData {
  file: string | null;
}

export default defineComponent({
  name: 'insurance-form-item-file-component',

  props: ['propName', 'metadata', 'insurance'],

  data(): BaseComponentData {
    return {
      file: null,
    };
  },

  filters: {
    fileType: (file) => file.split('.')[file.split('.').length - 1],
    fileName: (file) => file.replace(/^.*[\\/]/, '').split('.')[0]
  },

  created() {
    const { attachment } = this.insurance;

    if (attachment.length) {
      this.loadAttachment(attachment[0]);
    }
  },

  methods: {
    ...mapActions(ATTACHMENT_CACHE_STORE, ['getAttachments']),

    async loadAttachment(value) {
      this.getAttachments({
        name: USER_INSURANCES_CACHE,
        attachments: [value],
        returnFirstOnly: true
      })
        .then(({ src }) => {
          this.file = src;
        }).catch(() => {});
    }
  }
});
