import { RolesEnum } from '@/core/packages/shared-library';

import { PROJECTS_ROUTE } from '../../projects/routes';

/**
 * Project Quotes Overview page
 */
export const PROJECT_QUOTE_OVERVIEW_ROUTE = 'project-quote-overview';

export default {
  path: '/projects/:projectId/:userId/quote-overview',
  name: PROJECT_QUOTE_OVERVIEW_ROUTE,
  props: {
    default: (route: { params: { projectId: number; userId: number }; }) => ({
      projectId: route.params.projectId,
      userId: route.params.userId
    }),
    headerMenu: (route: { params: { projectJobStatusId: number; }; }) => ({
      projectJobStatusId: route.params.projectJobStatusId
    })
  },
  components: {
    default: () => import('@/modules/project-quote-overview/pages/ProjectQuoteOverviewPage.vue'),
    headerMenu: () => import('@/core/components/project/ProjectOverviewMenu.vue')
  },
  meta: {
    title: 'Quote Overview',
    authorize: [RolesEnum.ADMIN],
    showBackButton: false
  },
  beforeEnter: (to, from, next) => {
    // eslint-disable-next-line no-restricted-globals
    if (!to.params.projectId || isNaN(to.params.projectId)) {
      next({ name: PROJECTS_ROUTE });
    }

    // eslint-disable-next-line no-param-reassign
    to.meta.previousRoute = from || to;
    next();
  }
};
