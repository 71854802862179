
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Loader from '@/core/components/ui/Loader.vue';
import Sidebar from '@/core/components/layouts/Sidebar.vue';
import Header from '@/core/components/layouts/Header.vue';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import mobile from '@/core/utils/mobile';
import { USERS_STORE } from '@/store/modules/users';
import { PROJECTS_STORE } from '../../../store/modules/projects';

export default defineComponent({
  name: 'main-layout',

  components: {
    Loader,
    Sidebar,
    Header,
  },

  data() {
    return {
      initializing: false,
    };
  },

  created() {
    this.initialize();

    // set the default state for the sidenav collapse depending on the device
    if (mobile.isMobile) {
      this.setNavCollapseState(true);
    } else {
      this.setNavCollapseState(false); // change to false
    }
  },

  computed: {
    ...mapGetters(['isNavCollapse']),

    ...mapGetters(USERS_STORE, ['user']),

    ...mapGetters(AUTHENTICATION_STORE, ['isAuthenticated']),

    currentRoute(): string | undefined {
      return this?.$route?.meta?.title as string;
    },

    showBackButton(): boolean | undefined {
      return this.$route?.meta?.showBackButton === true;
    }
  },

  methods: {
    ...mapActions(USERS_STORE, ['initializeUserState']),

    ...mapActions(['setNavCollapseState']),

    ...mapActions(PROJECTS_STORE, ['getProjectCategories']),

    /**
     * Initialize all necessary data for this app before loadign the content
     */
    async initialize() {
      this.initializing = true;

      if (this.isAuthenticated && !this.user) {
        await this.initializeUserState();
      }

      // await this.getProjectCategories();

      // To emphasize the loader
      setTimeout(() => {
        this.initializing = false;
      }, 500);
    }
  },
});
