import { RolesEnum } from '@/core/packages/shared-library';
import AccountDetailRoute from '@/modules/account-details/routes';

export default {
  path: '/account/',
  name: 'account',
  component: () => import('@/modules/account/pages/AccountPage.vue'),
  children: [
    ...AccountDetailRoute
  ],
  meta: {
    title: 'Account Details',
    authorize: [RolesEnum.ADMIN],
  }
};
