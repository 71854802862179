import { RolesEnum } from '@/core/packages/shared-library';

/**
 * Projects route page
 */
export const COURSE_SCHEDULE_ROUTE = 'course-schedule';

export default {
  path: '/course-schedules',
  name: COURSE_SCHEDULE_ROUTE,
  component: () => import('@/modules/course-schedule/pages/CourseSchedulePage.vue'),
  meta: {
    title: 'Cohorts',
    authorize: [RolesEnum.ADMIN],
  }
};
