
import { defineComponent } from 'vue';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import LimitedCompanyComponent from '@/modules/account-details/components/business-detail/LimitedCompanyComponent.vue';
import SoleTraderComponent from '@/modules/account-details/components/business-detail/SoleTraderComponent.vue';

export default defineComponent({
  name: 'business-detail-form-component',

  components: {
    SoleTraderComponent,
    LimitedCompanyComponent,
  },

  props: {
    businessDetails: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      businessInfo: {},
      selectedType: '',
      UserVerificationStatusEnum
    };
  },

  watch: {
    businessDetails(business) {
      this.selectedType = business.type;
      this.businessInfo = { ...business };
    }
  },

  created() {
    this.selectedType = this.businessDetails.type;
    this.businessInfo = { ...this.businessDetails };
  }
});
