import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b2771aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "left-section" }
const _hoisted_3 = { class: "header-title" }
const _hoisted_4 = { class: "right-section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.showBackButton)
        ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            class: "arrow-anchor margin-right-4",
            type: "text",
            onClick: _ctx.backToPreviousPage
          }, {
            default: _withCtx(() => [
              _createVNode(_component_inline_svg, {
                color: "#264FD5",
                src: require(`@/assets/icons/arrow-left.svg`)
              }, null, 8, ["src"])
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.pageTitle), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_router_view, { name: "headerMenu" })
    ])
  ]))
}