
import { defineComponent } from 'vue';

import { PROJECT_MESSAGES_ROUTE } from '../../../modules/project-messages/routes';

export default defineComponent({
  name: 'message-dashboard-container-slot',
  props: {
    messagesToggleOpenCloseDetailClass: String,
  },
  data: () => {
    return {
      PROJECT_MESSAGES_ROUTE
    };
  },
  computed: {
    currentRoute() {
      return (this as any).$route;
    }
  }
});
