import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChipState = _resolveComponent("ChipState")!

  return (_ctx.verificationState === _ctx.UserVerificationStatusEnum.NOT_ADDED)
    ? (_openBlock(), _createBlock(_component_ChipState, {
        key: 0,
        label: "Not Added",
        icon: require('@/assets/icons/not-added.svg'),
        backgroundColor: "#4887FF"
      }, null, 8, ["icon"]))
    : (_ctx.verificationState === _ctx.UserVerificationStatusEnum.PENDING_VERIFICATION)
      ? (_openBlock(), _createBlock(_component_ChipState, {
          key: 1,
          label: "Pending Verification",
          icon: require('@/assets/icons/in-progress-white.svg'),
          backgroundColor: "#E5B800"
        }, null, 8, ["icon"]))
      : (_ctx.verificationState === _ctx.UserVerificationStatusEnum.REJECTED)
        ? (_openBlock(), _createBlock(_component_ChipState, {
            key: 2,
            label: "Rejected",
            icon: require('@/assets/icons/rejected.svg'),
            backgroundColor: "#D90404"
          }, null, 8, ["icon"]))
        : (_ctx.verificationState === _ctx.UserVerificationStatusEnum.VERIFIED)
          ? (_openBlock(), _createBlock(_component_ChipState, {
              key: 3,
              label: "Complete",
              icon: require('@/assets/icons/complete-1.svg'),
              backgroundColor: "#40AE42"
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true)
}