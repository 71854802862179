
import { defineComponent } from 'vue';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import ChipState from '@/core/components/ui/ChipState.vue';

export default defineComponent({
  name: 'certificatie-status-component',

  components: {
    ChipState,
  },

  props: {
    verificationState: {
      required: true,
      type: String
    },
  },

  data() {
    return {
      UserVerificationStatusEnum,
    };
  }
});
