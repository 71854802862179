
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import {
  RolesEnum, ProjectQuoteStatusEnum, ProjectStageStatusEnum, ProjectStageTypeEnum
} from '@/core/packages/shared-library';
import { PROJECT_QUOTES_STORE } from '../../../../store/modules/project-quotes';

export default defineComponent({
  name: 'project-quote-summary-card',

  props: {
    user: Object,

    quoteRecord: Object,

    canCancel: Boolean,

    canEditOrSubmitQuote: Boolean,

    totalStageAmount: Number,
  },

  emits: ['confirm-quote-submit', 'confirm-quote-cancel'],

  data() {
    return {
      ProjectStageStatusEnum,
      ProjectQuoteStatusEnum,
      ProjectStageTypeEnum,
      RolesEnum,
      totalStageCost: 0,
      serviceFee: 0,
      customerPays: { total: 0, serviceFee: 0 },
      thresholdPercentage: 10,
      submitted: false
    };
  },

  watch: {
    totalStageAmount: {
      immediate: true,
      handler(after) {
        if (after) {
          if (this.quoteRecord != null) {
            this.getTotalStageCostFn();
            this.getCustomerPayFn();
          }

          return true;
        }

        return false;
      }
    },
  },

  async created() {
    await this.getTotalStageCostFn();
    await this.getCustomerPayFn();
  },

  computed: {
    ...mapGetters(PROJECT_QUOTES_STORE, ['getProjectQuoteSummary']),

    getQuoteStages() {
      const stages = (this as any).quoteRecord.projectStages;
      let result = [];

      // Stage(Labour) first
      result = stages.filter((stage) => stage.stageType === ProjectStageTypeEnum.LABOUR);

      // Stage(Materials) last
      result = result.concat(stages.filter((stage) => stage.stageType === ProjectStageTypeEnum.MATERIALS));

      return result;
    },
  },

  methods: {
    ...mapActions(PROJECT_QUOTES_STORE, ['getTotalStageCost', 'getCustomerPay']),

    getStageCostWithServiceFee(payload) {
      const { stageCost } = payload.stage;
      const stageCostPercentage = stageCost / this.totalStageCost;
      const stageServiceFee = this.customerPays.serviceFee * stageCostPercentage;
      const stageCostWithServiceFee = parseFloat(payload.stage.stageCost + stageServiceFee);

      return !Number.isNaN(stageCostWithServiceFee) ? stageCostWithServiceFee : 0;
    },

    async getTotalStageCostFn(): Promise<any> {
      this.totalStageCost = await this.getTotalStageCost((this as any).quoteRecord.projectStages);
    },

    async getCustomerPayFn() {
      const { params } = this.$route;

      this.customerPays = await this.getCustomerPay({
        projectId: params.projectId,
        quote: (this as any).quoteRecord
      });
    }
  }
});
