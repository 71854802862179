
import { ProjectQuote, ProjectStageTypeEnum } from '@/core/packages/shared-library';
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

import { ImageFileTypes } from '@/core/helpers/file.helper';
import { ATTACHMENT_CACHE_STORE } from '@/store/modules/attachment-cache';
import { PROJECT_QUOTES_CACHE } from '@/store/modules/attachment-cache/constants';

export default defineComponent({
  name: 'project-quote-detail',

  emits: ['close-selected-project-quote'],

  props: {
    projectQuote: Object,
    projectStartDate: {
      type: String,
      default: null,
    },
    projectEndDate: {
      type: String,
      default: null,
    },
  },

  data: () => {
    return {
      ProjectStageTypeEnum,
      selectedSection: 'overview' as string,
      selectedMaterialUrl: '',
      selectedAttachmentUrl: '',
      IsAttachmentsFetched: false,
      attachmentUrls: [] as any
    };
  },

  created() {
    this.loadAttachments();
  },

  computed: {
    formEntity(): ProjectQuote {
      return (this as any).projectQuote;
    },
    getScheduleDates(): any[] | null {
      if ((this as any).projectStartDate == null || (this as any).projectEndDate == null) {
        return null;
      }
      const projectDates = [(this as any).projectStartDate, (this as any).projectEndDate];
      const dates = [
        {
          highlight: {
            color: 'gray',
            contentStyle: {
              'background-color': '#4887FF',
              color: '#fff',
              'border-radius': '4px',
            },
            fillMode: 'light',
          },
          contentStyle: {
            color: 'white',
          },
          dates: [
            {
              start: new Date(projectDates[0]),
              end: new Date(projectDates[1])
            }
          ],
          order: 0,
        }
      ];

      return dates;
    },
  },

  methods: {
    ...mapActions(ATTACHMENT_CACHE_STORE, ['getAttachments']),

    closeQuote() {
      (this as any).$emit('close-selected-project-quote');
    },

    isImageAttachment(filename) {
      const extension = filename.split('.')[filename.split('.').length - 1];

      return ImageFileTypes.includes(extension.toLowerCase());
    },

    loadAttachments() {
      const { projectQuoteAttachments } = this.formEntity as any;
      const list = projectQuoteAttachments
        .filter(Boolean)
        .map((item) => {
          return {
            attachment: item.attachment,
            originalName: item.originalName
          };
        });

      this.getAttachments({
        name: PROJECT_QUOTES_CACHE,
        attachments: list,
        includeUrl: true
      })
        .then((values) => {
          const newValues = values.filter(Boolean);

          if (newValues.length) {
            this.attachmentUrls = values;
          }
        })
        .catch(() => {})
        .finally(() => { this.IsAttachmentsFetched = true; });
    }
  }
});
