<template>
  <div v-if="isUserAccountPending">
    <el-row v-if="!isActionsSent" style="text-align: right;" class="hidden-xs-only">
      <el-button
        size="mini"
        class="action-btn" type="primary" @click="approve()">Approve</el-button>
      <el-button
        size="mini"
        class="action-btn" type="primary" @click="reject()">Reject</el-button>
    </el-row>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  name: 'UserVerifyActions',

  props: {
    info: {
      required: true,
      type: Object
    },
    user: Object
  },

  data() {
    return {
      UserVerificationStatusEnum: {
        ...UserVerificationStatusEnum,
      }
    };
  },

  computed: {
    ...mapGetters(USERS_STORE, ['selectedUserWorkstation']),

    isActionsSent() {
      return (this.isVerified || this.isRejected) || !this.info.userVerificationStatus;
    },
    isVerified() {
      return this.info.userVerificationStatus === this.UserVerificationStatusEnum.VERIFIED;
    },
    isRejected() {
      return this.info.userVerificationStatus === this.UserVerificationStatusEnum.REJECTED;
    },
    isUserAccountPending() {
      return this.selectedUserWorkstation.verificationStatus === UserVerificationStatusEnum.PENDING_VERIFICATION;
    },
  },

  methods: {
    approve() {
      this.$emit('approve', this.info);
    },
    reject() {
      this.$emit('reject', this.info);
    }
  }
});
</script>

<style type="scss" scoped>

.action-btn {
  margin-top: 20px;
  width: 47%;
  background-color: #264FD5;
  height: 40px;
}

</style>
