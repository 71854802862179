
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

export default defineComponent({
  name: 'layout-header',

  components: {
    // Burger,
  },

  props: {
    title: String,

    showBackButton: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    ...mapGetters(['isNavCollapse']),

    pageTitle(): string | undefined {
      return this.title;
    }
  },

  methods: {
    ...mapActions(['setNavCollapseState']),

    backToPreviousPage() {
      this.$router.go(-1);
    }
  }
});
