import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-659c6957"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tradesperson-profile-summary" }
const _hoisted_2 = { class: "header-photo" }
const _hoisted_3 = { class: "profileImageContainer" }
const _hoisted_4 = { class: "thumbnail" }
const _hoisted_5 = { class: "padding-left-16" }
const _hoisted_6 = { class: "profile-name margin-bottom-4 text-capitalize" }
const _hoisted_7 = { class: "margin-bottom-4 postcode text-uppercase" }
const _hoisted_8 = { class: "postcode-info" }
const _hoisted_9 = { class: "padding-12" }
const _hoisted_10 = { class: "text-center padding-vertical-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserThumbnail = _resolveComponent("UserThumbnail")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_RatingStars = _resolveComponent("RatingStars")!
  const _component_RatingProgress = _resolveComponent("RatingProgress")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_rate = _resolveComponent("el-rate")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_UserThumbnail, {
            width: "80px",
            height: "80px",
            class: "profile-photo-image",
            source: _ctx.profile?.userPublicProfile?.profileImage || null
          }, null, 8, ["source"])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.profile.firstName) + " " + _toDisplayString(_ctx.profile.lastName), 1),
        _createElementVNode("p", _hoisted_7, [
          _createVNode(_component_inline_svg, {
            class: "icon",
            src: require('@/assets/icons/pin-location-blue.svg')
          }, null, 8, ["src"]),
          _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.profile.postcode), 1)
        ]),
        _createElementVNode("section", {
          class: _normalizeClass(["rating", {'cursor-pointer': _ctx.popoverRating}])
        }, [
          (_ctx.popoverRating)
            ? (_openBlock(), _createBlock(_component_el_popover, {
                key: 0,
                placement: "bottom",
                width: "300",
                trigger: "click"
              }, {
                reference: _withCtx(() => [
                  _createVNode(_component_el_button, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_rate, {
                        class: "rating-star",
                        modelValue: _ctx.avgRating,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.avgRating) = $event)),
                        colors: _ctx.colors,
                        disabled: "",
                        "show-score": "",
                        "text-color": "#4887FF",
                        "score-template": `Based on ${_ctx.totalRatings} ratings`
                      }, null, 8, ["modelValue", "colors", "score-template"])
                    ]),
                    _: 1
                  })
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_9, [
                    (_ctx.ratingSummary)
                      ? (_openBlock(), _createBlock(_component_RatingStars, {
                          key: 0,
                          total: _ctx.ratingSummary.totalRatings,
                          average: _ctx.ratingSummary.avgRating
                        }, null, 8, ["total", "average"]))
                      : _createCommentVNode("", true),
                    (_ctx.ratingSummary)
                      ? (_openBlock(), _createBlock(_component_RatingProgress, {
                          key: 1,
                          ratings: _ctx.ratingSummary.ratings
                        }, null, 8, ["ratings"]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_el_button, {
                        class: "btn-customer-reviews",
                        type: "primary",
                        size: "small",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-navigate')))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("See All Customer Reviews")
                        ]),
                        _: 1
                      })
                    ])
                  ])
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_el_rate, {
                key: 1,
                modelValue: _ctx.avgRating,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.avgRating) = $event)),
                colors: _ctx.colors,
                disabled: "",
                "show-score": "",
                "text-color": "#4887FF",
                "score-template": `Based on ${_ctx.totalRatings} ratings`
              }, null, 8, ["modelValue", "colors", "score-template"]))
        ], 2)
      ])
    ])
  ]))
}