import axios, { AxiosError } from 'axios';
import { API_URL } from '../../config';
import { AUTH_TOKEN } from '../constants';

const axiosRequest = axios.create({
  baseURL: API_URL,
  timeout: 60 * 1000,
});

// TODO: Need to refactor on how to dynamically list all api that doesn't request Authorization
axiosRequest.interceptors.request.use(
  (config) => {
    const configureConfig = { ...config };
    const token = window.localStorage.getItem(AUTH_TOKEN);
    const sws = window.localStorage.getItem('SWS');

    if (sws) {
      configureConfig.headers.sws = sws;
    }

    // TODO: Set the authorization token only if the base url is iknowa api endpoint
    if (configureConfig) {
      configureConfig.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  },
);

// TODO: plan how to handle the response data from the server
axiosRequest.interceptors.response.use(
  (response: any) => {
    const res = response.data;

    return res;
  },
  (error: AxiosError) => {
    // toast a possible message in here.
    if (error.response?.status === 403) {
      // clear all local storage
      window.localStorage.clear();
      // TODO: acces the router here for navigation
      window.location.reload();

      return null;
    }

    return Promise.reject(error.response);
  },
);

export default axiosRequest;
