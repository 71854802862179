import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2929849c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item" }
const _hoisted_2 = { class: "certificate-form-file thumbnail" }
const _hoisted_3 = {
  key: 1,
  class: "text-placeholder"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_avatar = _resolveComponent("el-avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.file)
        ? (_openBlock(), _createBlock(_component_el_avatar, {
            key: 0,
            size: 60,
            src: _ctx.file,
            shape: "square",
            icon: "el-icon-picture"
          }, null, 8, ["src"]))
        : (_openBlock(), _createElementBlock("span", _hoisted_3, "Empty"))
    ])
  ]))
}