import * as CryptoJS from 'crypto-js';
import ImageTokenHelper from '@/core/helpers/image-token.helper';
import { API_URL } from '../config';
import $axios from '../core/utils/axios-api-config';

export default {
  /**
   * Format to image source url
   * @param value
   * @returns
   */
  async formatToImageSourceUrl(value: string) {
    const expiration = ImageTokenHelper.getImageExpirationTokenStorage();
    const encryptedToken = CryptoJS.AES.encrypt(expiration ?? '', process.env.VUE_APP_CRYPTO_SECRET_KEY).toString();
    const url = `${API_URL}/upload?key=${value}&token=${encodeURIComponent(encryptedToken)}`;
    const sourceUrl = await $axios.get(url);

    return sourceUrl;
  },

  formatToFileType: (file) => file.split('.')[file.split('.').length - 1],

  async getEncodedAttachment(value: string) {
    const expiration = ImageTokenHelper.getImageExpirationTokenStorage();
    const encryptedToken = CryptoJS.AES.encrypt(expiration ?? '', process.env.VUE_APP_CRYPTO_SECRET_KEY).toString();
    const url = `${API_URL}/upload?encoded=1&key=${value}&token=${encodeURIComponent(encryptedToken)}`;
    const encoded = await $axios.get(url);

    return encoded;
  }
};
