
import { defineComponent, PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import CardWrapper from '@/core/components/ui/CardWrapper.vue';
import CertificateVerificationState from '@/modules/account-details/components/certificate/CertificateVerificationStateComponent.vue';
import CertificationTemplateRenderer from '@/modules/account-details/components/certificate/CertificateTemplateRenderer.vue';
import CertificationStatus from '@/modules/account-details/components/certificate/CertificateStatusComponent.vue';
import { User } from '@/core/models/user';
import UserVerifyActions from '@/core/components/account/UserVerifyActions.vue';
import { USERS_STORE } from '@/store/modules/users';
import { CERTIFICATES_STORE } from '../../../store/modules/certificates';

export default defineComponent({
  name: 'certification-page',

  components: {
    CardWrapper,
    CertificateVerificationState,
    CertificationTemplateRenderer,
    CertificationStatus,
    UserVerifyActions
  },

  props: {
    user: Object as PropType<User>
  },

  data() {
    return {
      activeCertificate: null,
      isCreateNewCertificate: false,
      unsaveUserCerficates: [],
      refNewUserCertificateForm: 0,
      UserVerificationStatusEnum,
      routeParamId: null
    };
  },

  created() {
    const user = this.user as any;

    this.getUserCertificates(user.id);
  },

  computed: {
    ...mapGetters(USERS_STORE, [
      'userCertificates',
      'certificateVerificationState'
    ]),
    ...mapGetters(CERTIFICATES_STORE, ['certificates']),

    hasSubmitted() {
      const state = this.certificateVerificationState as any;
      return parseInt(state, 10) > 0;
    }
  },

  methods: {
    ...mapActions(CERTIFICATES_STORE, ['getCertificates']),

    ...mapActions(USERS_STORE, [
      'getUserCertificates',
      'getAccountStatusesByUserId',
      'updateUserCertificate'
    ]),

    nextSection() {
      this.$router.push({ name: 'account/insurance' });
    },

    approve(info) {
      const data = info as any;
      data.userVerificationStatus = this.UserVerificationStatusEnum.VERIFIED;
      this.onSubmit(info, 'approved');
    },

    reject(info) {
      const data = info as any;
      data.userVerificationStatus = this.UserVerificationStatusEnum.REJECTED;
      this.onSubmit(info, 'rejected');
    },

    onSubmit(payload, action) {
      const { id } = this.$route.params;

      this.updateUserCertificate({
        userId: id,
        certificateId: payload.id,
        certificate: {
          metadata: payload.metadata,
          certificateId: payload.id,
          userVerificationStatus: payload.userVerificationStatus
        }
      }).then(() => {
        (this as any).$notify.success({
          message: `Certificate updated successfully ${action}.`
        });
        this.getAccountStatusesByUserId(id);
      })
        .catch((e) => {
          (this as any).$notify.error({
            message: e || 'Error submitting details.'
          });
        });
    },
  }
});
