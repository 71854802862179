
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  name: 'account-page',

  computed: {
    ...mapGetters(USERS_STORE, [
      'accountStatuses',
      'selectedUser',
      'isUserVerificationActive'
    ]),

    getCurrentRoute() {
      return this.$route.name;
    },
  },

  data() {
    return {
      user: {},
      status: {},
      currentNavMenu: '',
      selectedMenu: '',
      menus: [
        { label: 'Account Status', name: 'account/status' },
        { label: 'Profile', name: 'account/public-profile' },
        { label: 'Business Details', name: 'account/business-details' },
        { label: 'Identification', name: 'account/identification-check' },
        { label: 'Certification', name: 'account/certification' },
        { label: 'Insurance', name: 'account/insurance' },
        { label: 'Skills', name: 'account/skills' },
      ],

      userWorkstation: null
    };
  },

  async created() {
    const { id, userWorkstationId } = this.$route.params;
    let { name } = this.$route as any;

    this.userWorkstation = await this.getUserWorkstation({
      userId: id,
      userWorkstationId
    });

    window.localStorage.setItem('SWS', `${userWorkstationId}`);

    this.status = await this.getAccountStatusesByUserId(id);
    this.user = await this.getUserById(id);

    if (name === 'account/tradesperson') {
      name = 'account/status';
    }

    this.$router.push({ name });
  },

  methods: {
    ...mapActions(USERS_STORE, ['getAccountStatusesByUserId', 'getUserById', 'getUserWorkstation']),

    onClickMenu(e) {
      this.$router.push({ name: e.index });
    },

    getAccountMenuStatus(formType, index) {
      const result = {
        type: 'primary',
        label: 'Not Started'
      };

      const { userWorkstation } = this as any;
      const formName = {
        TRADESPERSON: 0,
        PUBLIC_PROFILE: 1,
        BUSINESS_DETAILS: 2,
        IDENTIFICATION_CHECK: 3,
        CERTIFICATION: 4,
        INSURANCE: 5,
        SKILLS: 6,
      };

      // eslint-disable-next-line no-param-reassign
      formType = formType.replace('account/', '').replace('-', '_');
      let form = null;

      if (formType !== 'tradesperson' && this.accountStatuses && this.accountStatuses.forms[formType]) {
        form = this.accountStatuses.forms[formType] || null;
      }

      switch (index) {
        case formName.TRADESPERSON:
          if (userWorkstation.verificationStatus === UserVerificationStatusEnum.VERIFIED) {
            result.label = 'Complete';
            result.type = 'success';
          } else if (userWorkstation.verificationStatus === UserVerificationStatusEnum.PENDING_VERIFICATION) {
            result.label = 'Ready to activate';
            result.type = 'primary';
          } else {
            result.label = 'Inactive';
            result.type = 'danger';
          }
          break;
        default:
          if (form && form === UserVerificationStatusEnum.PENDING_VERIFICATION) {
            result.type = 'warning';
            result.label = 'Pending Verification';
          } else if (form && form === UserVerificationStatusEnum.VERIFIED) {
            result.type = 'success';
            result.label = 'Complete';
          } else if (form && form === UserVerificationStatusEnum.REJECTED) {
            result.type = 'danger';
            result.label = 'Rejected';
          }
          break;
      }

      return result;
    }
  }
});
