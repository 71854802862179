import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_skeleton_item = _resolveComponent("el-skeleton-item")!
  const _component_el_skeleton = _resolveComponent("el-skeleton")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_el_skeleton, { animated: "" }, {
      template: _withCtx(() => [
        _createVNode(_component_el_skeleton_item, {
          variant: "rect",
          class: "project-card-empty"
        }),
        _createVNode(_component_el_skeleton_item, {
          variant: "rect",
          class: "project-card-empty"
        }),
        _createVNode(_component_el_skeleton_item, {
          variant: "rect",
          class: "project-card-empty"
        }),
        _createVNode(_component_el_skeleton_item, {
          variant: "rect",
          class: "project-card-empty"
        })
      ]),
      _: 1
    })
  ]))
}