import { ProjectJobStatusEnum } from '@/core/packages/shared-library';

export default {
  selectedProject: (state) => {
    return state.selectedProject;
  },

  topRightSidebarMenu: (state) => {
    return state.topRightSidebarMenu;
  },

  categories: (state) => {
    return state.categories;
  },

  projectCategories: (state) => {
    return state.projectCategories;
  },

  projectStatuses: (state) => {
    return state.projectStatuses;
  },

  materialAvailabilityLabel: (state) => {
    return state.materialAvailabilityLabel;
  },

  getProjectStatusLabel: () => {
    return (project: { projectJobStatus }) => {
      const projectJobStatus = project?.projectJobStatus.id.toString();

      const status = {
        name: project?.projectJobStatus.display || 'Not Started',
        type: 'warning',
      };

      switch (projectJobStatus) {
        case ProjectJobStatusEnum.DRAFT_PROJECT:
          status.type = 'warning';
          break;

        case ProjectJobStatusEnum.AWAITING_QUOTES:
          status.type = 'primary';
          break;

        case ProjectJobStatusEnum.QUOTES_RECEIVED:
          status.type = 'warning';
          break;

        case ProjectJobStatusEnum.QUOTE_ACCEPTED:
          status.type = 'success';
          break;

        case ProjectJobStatusEnum.QUOTE_CONFIRMED:
          status.name = 'Quote Accepted';
          status.type = 'success';
          break;

        case ProjectJobStatusEnum.IN_PROGRESS:
          status.type = 'primary';
          break;

        case ProjectJobStatusEnum.COMPLETED:
          status.type = 'success';
          break;

        case ProjectJobStatusEnum.CANCELLED:
          status.type = 'danger';
          break;

        default:
      }

      return status;
    };
  }
};
