import { RolesEnum } from '@/core/packages/shared-library';

export const UPDATE_COURSE_SCHEDULE_ROUTE = 'update-course-schedule';

export default {
  path: '/course-schedule/:courseScheduleId',
  name: UPDATE_COURSE_SCHEDULE_ROUTE,
  component: () => import('@/modules/update-course-schedule/pages/UpdateCourseSchedulePage.vue'),
  meta: {
    title: 'Update Cohort',
    authorize: [RolesEnum.ADMIN],
  }
};
