import { ProjectQuoteStatusEnum } from '@/core/packages/shared-library';

export default {
  defaultProjectQuoteState: (state) => {
    return state.defaultProjectQuoteState;
  },

  defaultStageState: (state) => {
    return state.defaultStageState;
  },

  defaultTaskState: (state) => {
    return state.defaultTaskState;
  },

  getAverageQuoteValue: () => {
    return (quotes: unknown[]) => {
      let value = 0;

      if (quotes.length) {
        quotes.forEach((quote: any) => {
          if (quote.projectStages.length) {
            quote.projectStages.forEach((projectStage) => {
              value += parseFloat(projectStage.stageCost);
            });
          }
        });
      }

      return value;
    };
  },

  getQuoteStatusLabel: () => {
    return (quoteEntity) => {
      const quote = quoteEntity != null && quoteEntity.projectQuoteStatus ? quoteEntity : null;

      const status = {
        name: quote ? quote.projectQuoteStatus.display : '',
        type: 'warning',
      };

      if (quote != null && quote.projectQuoteStatusId) {
        switch (quote.projectQuoteStatusId) {
          case ProjectQuoteStatusEnum.DRAFT_QUOTE:
            status.type = 'warning';
            break;

          case ProjectQuoteStatusEnum.QUOTE_SUBMITTED:
            status.type = 'primary';
            break;

          case ProjectQuoteStatusEnum.QUOTE_ACCEPTED:
            status.type = 'success';
            break;

          case ProjectQuoteStatusEnum.IN_PROGRESS:
            status.type = 'primary';
            break;

          case ProjectQuoteStatusEnum.COMPLETED:
            status.type = 'success';
            break;

          case ProjectQuoteStatusEnum.QUOTE_REJECTED:
          case ProjectQuoteStatusEnum.PROJECT_REJECTED:
          case ProjectQuoteStatusEnum.CANCELLED:
            status.type = 'danger';
            break;

          default:
        }
      }

      return status;
    };
  },

  getProjectQuoteSummary: () => {
    return (param) => {
      const { columns, data } = param;
      const sums: string[] = [];

      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Your total earnings';
          return;
        }

        const values = data.map((item) => Number(item[column.property]));

        if (!values.every((value) => Number.isNaN(value))) {
          sums[index] = `£${values.reduce((prev, curr) => {
            const value = Number(curr);

            if (!Number.isNaN(value)) {
              return prev + curr;
            }

            return prev;
          }, 0).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')}`;
        } else {
          sums[index] = 'N/A';
        }
      });

      return sums;
    };
  },

  getProjectStageDurationDays: () => (tasks: any[]) => {
    let total = 0 as number;

    if (tasks.length) {
      tasks.forEach((task) => {
        total += task?.duration && task.duration > 0 ? parseFloat(task.duration) : 0;
      });
    }

    return total.toFixed(1);
  },

  getTotalProjectStageTasks: () => {
    return (quote) => {
      let total = 0 as number;

      if (quote.projectStages.length) {
        quote.projectStages.forEach((stage: any) => {
          total += parseFloat(stage.projectStageTasks.length);
        });
      }

      return total;
    };
  },

  getTotalProjectStageDurationDays: (store, getters) => (quote) => {
    let total = 0 as number;

    if (quote.projectStages.length) {
      quote.projectStages.forEach((stage) => {
        if (stage?.projectStageTasks?.length) {
          total += parseFloat(getters.getProjectStageDurationDays(stage.projectStageTasks));
        }
      });
    }

    return total;
  }
};
