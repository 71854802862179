import { RolesEnum } from '@/core/packages/shared-library';
import AccountDetailRoute from '@/modules/account-details/routes';

const accessRole = [RolesEnum.ADMIN];

const TradespersonPage = {
  path: '/user/tradesperson',
  name: 'user/tradesperson',
  component: () => import('@/modules/tradesperson/pages/TradespersonPage.vue'),
  meta: {
    title: 'Tradesperson',
    authorize: accessRole
  }
};

const TradespersonAccountPage = {
  path: '/user/tradesperson/:id/:userWorkstationId',
  name: 'account/tradesperson',
  component: () => import('@/modules/account/pages/AccountPage.vue'),
  meta: {
    title: 'Account Details',
    authorize: accessRole
  },
  children: [
    ...AccountDetailRoute
  ]
};

export {
  TradespersonPage,
  TradespersonAccountPage
};
