// Iknowa themes and app styles
import ElementPlus from 'element-plus';
import '@/assets/scss/themes/element-variables.scss';

import { createApp } from 'vue';
import vueDebounce from 'vue-debounce';
import InlineSvg from 'vue-inline-svg';
import AsyncComputed from 'vue-async-computed';
import appFilters from '@/filters';
import App from './App.vue';
import router from './router';
import store from './store';

const app = createApp(App);

// plugins
app.use(ElementPlus, {} as any);
app.use(vueDebounce);
app.use(store);
app.use(router);
app.use(AsyncComputed);

// global filters
app.config.globalProperties.$filters = {
  ...appFilters,
};

// custom components
app.component('inline-svg', InlineSvg);

// mounts the vue into app
app.mount('#app');
