
import { defineComponent, PropType } from 'vue';
import { mapActions } from 'vuex';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import CardWrapper from '@/core/components/ui/CardWrapper.vue';
import UserVerificationStatus from '@/core/components/account/UserVerificationStatus.vue';
import UserVerifyActions from '@/core/components/account/UserVerifyActions.vue';
import BusinessDetailsFormComponent from '@/modules/account-details/components/BusinessDetailFormComponent.vue';
import { User } from '@/core/models/user';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  name: 'business-detail-page',

  components: {
    CardWrapper,
    UserVerificationStatus,
    UserVerifyActions,
    BusinessDetailsFormComponent,
  },

  props: {
    user: Object as PropType<User>
  },

  data() {
    return {
      UserVerificationStatusEnum,
      businessDetails: {
        type: '',
        companyName: '',
        nationalInsuranceNumber: '',
        taxReferenceNumber: '',
        incorporationDate: '',
      }
    };
  },

  created() {
    const user = this.$props.user as any;
    const businesDetails = user.userBusinessDetails;

    this.getBusinessDetails({
      userId: user.id,
      businessId: businesDetails.id
    })
      .then((response) => {
        this.businessDetails = response;
      })
      .finally(() => {
        if (!this.businessDetails.type) {
          this.businessDetails.type = 'limited_company';
        }
      });
  },

  computed: {
    hasSubmitted() {
      const profile = this.businessDetails as any;
      return parseInt(profile.userVerificationStatus, 10) > 0;
    }
  },

  methods: {
    ...mapActions(USERS_STORE, [
      'getBusinessDetails',
      'saveBusinessDetails',
      'getAccountStatusesByUserId'
    ]),
    nextSection() {
      this.$router.push({ name: 'account/identification-check' });
    },
    approve() {
      const info = this.businessDetails as any;
      info.userVerificationStatus = this.UserVerificationStatusEnum.VERIFIED;
      this.submit(info, 'approved');
    },
    reject() {
      const info = this.businessDetails as any;
      info.userVerificationStatus = this.UserVerificationStatusEnum.REJECTED;
      this.submit(info, 'rejected');
    },
    submit(formData, action) {
      const { id } = this.$route.params;
      this.saveBusinessDetails({ userId: id, businessDetail: formData })
        .then(() => {
          (this as any).$notify.success({
            message: `Business details successfully ${action}.`
          });
        })
        .catch((e) => {
          (this as any).$notify.error({
            message: e || 'Error submitting Public Profile details.'
          });
        })
        .finally(() => {
          this.getAccountStatusesByUserId(id);
        });
    }
  }
});
