import UserCustomerService from '@/core/services/user-customers.service';

export default {
  async getCustomerByUserId({ commit }, params) {
    const { userId, userWorkstationId } = params;
    return UserCustomerService.getCustomer(userId, userWorkstationId)
      .then((response) => response)
      .then((customer) => {
        commit('setUserCustomer', customer);
        return customer;
      });
  },

  async getCustomerAccountByUserId({ commit }, params) {
    const { userId, userWorkstationId } = params;
    return UserCustomerService.getCustomerAccount(userId, userWorkstationId)
      .then((response) => response)
      .then((account) => {
        commit('setUserCustomerAccount', account);
        return account;
      });
  },

  async checkUserCustomerStatus({ commit }, payload) {
    return UserCustomerService.updateCustomerStatus(payload)
      .then((response) => response.data)
      .then((customer) => {
        commit('setUserCustomer', customer);
        return customer;
      });
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async createCustomerAccount({ commit }, payload) {
    return UserCustomerService.createCustomerAccount(payload)
      .then((response) => response)
      .then((account) => {
        // commit('setUserCustomer', customer);
        return account;
      });
  },

  async getUserCustomers({ commit }) {
    return UserCustomerService.getUserCustomers().then((response) => {
      commit('setUserCustomers', response);
      return Promise.resolve(response);
    });
  },

  async createCustomer({ commit }, payload) {
    const { userId } = payload;

    // delete payload.userId;

    return UserCustomerService.createCustomer(userId, payload).then((response) => {
      const updatedUser = response.data;
      // updatedUser.token = getters.user.tokensponse.data

      // commit('setState', { field: 'user', value : updatedUser })
      commit('setUserCustomer', [updatedUser]);
      // // dispatch('updateLocalStorageUser')
      return Promise.resolve(response);
    });
  }
};
