
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { User, UserVerificationStatusEnum } from '@/core/packages/shared-library';

import { PAGE_LIMIT } from '@/core/constants';
import ProfileCardComponent from '@/modules/account-profile/components/ProfileCardComponent.vue';
import ProfileReviewCardComponent from '@/modules/account-profile/components/ProfileReviewCardComponent.vue';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  name: 'account-profile-page',

  components: {
    ProfileCardComponent,
    ProfileReviewCardComponent,
  },

  data: () => {
    interface ProjectListMetaData {
      limit: number,
      page: number,
      totalCount: number,
      totalPages: number,
    }
    return {
      submittingForm: false,

      UserVerificationStatusEnum,

      loadedData: false,

      selectedTab: 'profile',

      showRatingDialog: false,

      currentTab: 'profile',

      ratings: [],

      user: null as null | User,

      ratingSummary: {
        avgRating: 0,
        totalRatings: 0,
        ratings: []
      },

      pageFilters: {
        page: 1,
        limit: PAGE_LIMIT
      },

      // holds a copy of the page from response data
      pageListMetadata: {
        page: 0,
        limit: PAGE_LIMIT,
        totalCount: 0,
        totalPages: 0,
      } as ProjectListMetaData,
    };
  },

  async created() {
    (this as any).user = await this.getUser((this as any).$route?.params.userId);
    const ratingSummary = await this.getUserRatingSummary((this as any).$route?.params.userId);

    this.ratingSummary = ratingSummary;

    this.fetchUserRatingList();
  },

  methods: {
    ...mapActions(USERS_STORE, [
      'getUser',
      'getUserRatingsPageList',
      'getUserRatingSummary'
    ]),

    async fetchUserRatingList(filter = {}) {
      const currentFilters = { ...this.pageFilters, ...filter };

      const ratingsPageList = await this.getUserRatingsPageList({
        userId: (this as any).user.id,
        filter: currentFilters
      });

      this.ratings = ratingsPageList.data;
      const { totalCount, totalPages } = ratingsPageList;
      this.pageListMetadata = { ...this.pageListMetadata, totalCount, totalPages };
    },

    onSearchText(value: string) {
      // reset the page filter to 1 when doing new query
      this.pageFilters.page = 1;

      this.fetchUserRatingList({
        search: value
      });
    },

    paginatePage(currentpage: number) {
      this.pageFilters.page = currentpage;

      this.fetchUserRatingList();
    }
  }
});
