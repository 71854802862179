
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'business-type-form-component',

  props: {
    businessDetails: Object
  },

  data() {
    return {
      soletraderValue: 'soletrader',
      limitedCompanyValue: 'limited_company',
      types: {
        limited_company: 'Limited Company',
        soletrader: 'Soletrader'
      }
    };
  },

  computed: {
    businessDetailEntity(): unknown {
      return this.businessDetails;
    }
  }
});
