import CourseService from '@/core/services/course.service';

export default {
  getCourses({ commit }, filter) {
    return CourseService.getCourses(filter).then((response) => {
      commit('setCourses', response);
      return Promise.resolve(response);
    });
  },

  getCourse(context, id) {
    return CourseService.getCourse(id);
  },

  updateCourse(context, params) {
    const { id, formData } = params;

    return CourseService.updateCourse(id, formData);
  },

  createCourse(context, formData) {
    return CourseService.createCourse(formData);
  },

  deleteCourse(context, id) {
    return CourseService.deleteCourse(id);
  },

  getCourseSchedules({ commit }, filter) {
    return CourseService.getCourseSchedules(filter).then((response) => {
      commit('setCourseSchedules', response);
      return Promise.resolve(response);
    });
  },

  getCourseSchedule(context, id) {
    return CourseService.getCourseSchedule(id);
  },

  updateCourseSchedule(context, params) {
    const { id, formData } = params;

    return CourseService.updateCourseSchedule(id, formData);
  },

  createCourseSchedule(context, formData) {
    return CourseService.createCourseSchedule(formData);
  },

  deleteCourseSchedule(context, id) {
    return CourseService.deleteCourseSchedule(id);
  }
};
