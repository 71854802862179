
import moment from 'moment';
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

import { ATTACHMENT_CACHE_STORE } from '@/store/modules/attachment-cache';
import { USER_RATING_REVIEWS_CACHE } from '@/store/modules/attachment-cache/constants';

export default defineComponent({
  name: 'rating-review-card',

  props: {
    rating: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      attachments: [],
    };
  },

  computed: {
    currentRatingRecord() {
      return (this as any).rating;
    },

    dateReview() {
      return moment((this as any).rating.dateCreated).format('DD MMMM YYYY');
    }
  },

  created() {
    this.loadAttachments();
  },

  methods: {
    ...mapActions(ATTACHMENT_CACHE_STORE, ['getAttachments']),

    loadAttachments() {
      const { ratingAttachments } = (this as any).currentRatingRecord as any;

      this.getAttachments({
        name: USER_RATING_REVIEWS_CACHE,
        attachments: ratingAttachments
      })
        .then((values) => {
          const newValues = values.filter(Boolean);

          if (newValues.length) {
            this.attachments = values.map((value) => value.src);
          }
        }).catch(() => {});
    }
  }
});
