
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NotRequired',

  props: ['certificate'],

  computed: {
    currentCertificate() {
      return (this as any).certificate;
    }
  }
});
