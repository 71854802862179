
import { defineComponent } from '@vue/runtime-core';
import { ProjectStageStatusEnum, ProjectStageTypeEnum } from '@/core/packages/shared-library';

export default defineComponent({
  name: 'project-job-plan-card',
  props: {
    projectQuote: Object,
  },
  data: () => {
    return {
      ProjectStageStatusEnum,
      ProjectStageTypeEnum,
    };
  },
});
