import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c02e83e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "padding-horizontal-24 padding-top-12" }
const _hoisted_2 = { class: "header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_skeleton_item = _resolveComponent("el-skeleton-item")!
  const _component_el_skeleton = _resolveComponent("el-skeleton")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_el_skeleton, { animated: "" }, {
      template: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_skeleton_item, {
            variant: "h1",
            style: {"max-width":"200px","width":"100%","height":"40px"}
          }),
          _createVNode(_component_el_skeleton_item, {
            variant: "h1",
            style: {"max-width":"40px","width":"100%","height":"40px"}
          })
        ]),
        _createVNode(_component_el_skeleton_item, {
          variant: "rect",
          class: "overview-block"
        }),
        _createVNode(_component_el_skeleton_item, {
          variant: "rect",
          class: "margin-top-4 overview-block"
        }),
        _createVNode(_component_el_skeleton_item, {
          variant: "rect",
          class: "margin-top-4 overview-block"
        }),
        _createVNode(_component_el_skeleton_item, {
          variant: "rect",
          class: "margin-top-4 overview-block"
        }),
        _createVNode(_component_el_skeleton_item, {
          variant: "rect",
          class: "margin-top-4 overview-block"
        })
      ]),
      _: 1
    })
  ]))
}