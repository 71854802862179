export default {
  setUserCustomer(state, payload) {
    state.userCustomer = payload;
  },

  setUserCustomerAccount(state, payload) {
    state.userCustomerAccount = payload;
  },

  setUserCustomers(state, payload) {
    state.userCustomers = payload;
  }
};
