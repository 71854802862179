export default {
  user: null,

  accountStatuses: null,

  userCertificates: [],

  userInsurances: [],

  users: [],

  userWorkstations: [],

  usersPaginate: {
    limit: 10,
    page: 1,
  },

  selectedUser: null,

  selectedUserWorkstation: null
};
