import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f54e947"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_ImageAttachmentComponent = _resolveComponent("ImageAttachmentComponent")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      model: _ctx.currentCertificate.metadata,
      class: "form",
      "label-position": "top",
      size: "small"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          label: "Business Name",
          prop: "businessName"
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(_ctx.currentCertificate.metadata.businessName), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "Postcode",
          prop: "postcode"
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(_ctx.currentCertificate.metadata.postcode), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "URL",
          prop: "url"
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(_ctx.currentCertificate.metadata.url), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "Proof Of Certificate" }, {
          default: _withCtx(() => [
            _createVNode(_component_ImageAttachmentComponent, { record: _ctx.currentCertificate }, null, 8, ["record"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"])
  ]))
}