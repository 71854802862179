import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MessageListFilter = _resolveComponent("MessageListFilter")!
  const _component_PoMessageListItem = _resolveComponent("PoMessageListItem")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_MessageListFilter, { onFilter: _ctx.onChangeFilterUserMessageRooms }, null, 8, ["onFilter"]),
    (_ctx.userMessageRooms.length)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.userMessageRooms, (userMessageRoom, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, null, [
            (userMessageRoom && userMessageRoom.project && userMessageRoom.project.id)
              ? (_openBlock(), _createBlock(_component_PoMessageListItem, {
                  key: userMessageRoom.id + index,
                  userMessageRoom: userMessageRoom,
                  selectedUserMessageRoom: _ctx.selectedUserMessageRoom,
                  onSelectUserMessageRoom: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectUserMessageRoom($event)))
                }, null, 8, ["userMessageRoom", "selectedUserMessageRoom"]))
              : _createCommentVNode("", true)
          ], 64))
        }), 256))
      : _createCommentVNode("", true)
  ]))
}