
import { User } from '@/core/packages/shared-library';
import { defineComponent } from 'vue';

import UserThumbnail from '@/core/components/ui/UserThumbnail.vue';

import { PROJECT_OVERVIEW_ROUTE } from '../../../modules/project-overview/routes';

export default defineComponent({
  name: 'message-list-detail-box-component',
  components: {
    UserThumbnail
  },
  props: {
    userMessageRoom: Object,
    showViewProjectButton: Boolean,
  },
  computed: {
    getSelectedUserId() {
      return this.$route.params.userId;
    },

    member(): null | User {
      let member = (this as any).userMessageRoom.fromUser && (this as any).userMessageRoom.fromUser.id !== this.getSelectedUserId
        ? (this as any).userMessageRoom.fromUser : (this as any).userMessageRoom.toUser;

      if (!member) {
        member = (this as any).userMessageRoom?.project?.projectCreator;
      }

      return member;
    }
  },
  methods: {
    viewProject() {
      const { projectId, userId } = this.$route.params;
      this.$router.push({ name: PROJECT_OVERVIEW_ROUTE, params: { projectId, userId } });
    }
  }
});
