import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-214755dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "message-dashboard-container page-viewport" }
const _hoisted_2 = { class: "message-dashboard-sidebar-list" }
const _hoisted_3 = { class: "message-dashboard-list is-project-owner-project-messages" }
const _hoisted_4 = { class: "message-dashboard-project-mini-details margin-top-48" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_scrollbar, null, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "message-dashboard-sidebar-list", {}, undefined, true)
        ]),
        _: 3
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createElementVNode("aside", _hoisted_4, [
      _createVNode(_component_el_scrollbar, null, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "message-dashboard-project-mini-details", {}, undefined, true)
        ]),
        _: 3
      })
    ])
  ]))
}