
import { defineComponent } from '@vue/runtime-core';

import { AUTH_TOKEN } from '@/core/constants';

const ATTACHMENT_URL = 'download-attachments';

export default defineComponent({
  name: 'download-attachment',
  props: {
    link: {
      type: String,
      required: true
    },
  },

  computed: {
    getDownloadLink() {
      return `${this.link}/${ATTACHMENT_URL}`;
    }
  },

  methods: {
    download() {
      const token = window.localStorage.getItem(AUTH_TOKEN);

      window.open(`${this.getDownloadLink}?download=${token}`);
    }
  }
});
