import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createBlock(_component_el_tabs, {
    class: "toprightside--menu--tabs",
    modelValue: _ctx.selectedTab,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTab) = $event)),
    onTabClick: _ctx.handleClick
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (menuItem, i) => {
        return (_openBlock(), _createBlock(_component_el_tab_pane, {
          key: i,
          label: menuItem.label,
          name: menuItem.route
        }, null, 8, ["label", "name"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue", "onTabClick"]))
}